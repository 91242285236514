import React from 'react';

interface PurchaseEffortCardProps {
  title: string;
  recommendedSupporters: number;
  imageUrl: string;
  onCardClick?: () => void;
}

const PurchaseEffortCard: React.FC<PurchaseEffortCardProps> = ({
  title,
  recommendedSupporters,
  imageUrl,
  onCardClick,
}) => {
  return (
    <div
      onClick={onCardClick}
      style={{
        border: '1px solid #ddd',
        borderRadius: '8px',
        overflow: 'hidden',
        fontFamily: 'Inter, sans-serif',
        maxWidth: '450px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        userSelect: 'none',
      }}
    >
      {/* Header */}
      <div
        style={{
          backgroundColor: '#4C4C4C',
          padding: '0.65rem 1.2rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ color: '#fff', fontWeight: 'bold', fontSize: '1.15rem' }}>
          {title}
        </div>
      </div>

      {/* Large Image with extra top padding */}
      <div style={{ paddingTop: '1rem' }}>
        <img
          src={imageUrl}
          alt={title}
          style={{
            width: '100%',
            height: 'auto',
            display: 'block',
          }}
        />
      </div>

      {/* Body */}
      <div style={{ backgroundColor: '#fff', padding: '1.5rem' }}>
        {/* Stats Section */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: '1.5rem',
          }}
        >
          {/* Recommended Supporters */}
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontSize: '1rem', display: 'block' }}>
              Recommended Supporters
            </span>
            <span style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 1 }}>
              {recommendedSupporters}
            </span>
          </div>
        </div>

        {/* "EFFORT NOT PURCHASED" text */}
        <div style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '0.75rem' }}>
          EFFORT NOT PURCHASED
        </div>
      </div>
    </div>
  );
};

export default PurchaseEffortCard;
