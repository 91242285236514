import React from 'react';
import { Grid } from '@mui/material';
import SupportersView from './SupportersView';

const ViewSupporters: React.FC = () => {
  return (
    <Grid container 
      sx={{ 
        width: '100%', 
        maxWidth: 'calc(100%)', 
        margin: '0 auto', // Centers the component horizontally
        padding: { xs: '24px', sm: '24px', md: '32px', lg: '40px' } // Padding matches Email Settings
      }}
    >
      <SupportersView />
    </Grid>
  );
};

export default ViewSupporters;