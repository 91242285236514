import { useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { Box, Typography, Grid } from '@mui/material';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { getGeoLocation } from '../../api/utils';

// Import custom icons for individual markers
import greenMarker from '../../assets/images/map-icons/light-green-dot.png';
import purpleMarker from '../../assets/images/map-icons/purple-dot.png';

// Import custom icons for cluster sizes
import blueCircle from '../../assets/images/map-icons/m1.png';
import yellowCircle from '../../assets/images/map-icons/m2.png';
import redCircle from '../../assets/images/map-icons/m3.png';
import purpleCircle from '../../assets/images/map-icons/m4.png';

interface Supporters {
  salutation?: string | null;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string | null;
  address?: string;
  city?: string | null;
  state?: string | null;
  zip_code?: string | null;
  id: number;
  engagementScore?: number;
  considerationStage?: string;
  latitude?: number;
  longitude?: number;
}

interface SupportersMapProps {
  supporters: Supporters[];
  selectedSupporter: Supporters | null;
  setSelectedSupporter: (supporter: Supporters | null) => void;
  centerMap: { lat: number; lng: number };
  setCenterMap: (center: { lat: number; lng: number }) => void;
  zoom: number;
  setZoom: (zoom: number) => void;
  infoWindowPos: { lat: number; lng: number } | null;
  setInfoWindowPos: (pos: { lat: number; lng: number } | null) => void;
  handleCloseInfoWindow: () => void;
}

const mapContainerStyle = {
  width: '100%',
  height: '500px',
};

const SupportersMap: React.FC<SupportersMapProps> = ({
  supporters,
  selectedSupporter,
  setSelectedSupporter,
  centerMap,
  setCenterMap,
  zoom,
  setZoom,
  infoWindowPos,
  setInfoWindowPos,
  handleCloseInfoWindow,
}) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const markerClusterRef = useRef<MarkerClusterer | null>(null);
  const [geocodedSupporters, setGeocodedSupporters] = useState<Supporters[]>([]);
  const [topTenSupporters, setTopTenSupporters] = useState<Supporters[]>([]);

  /**
   * Geocode supporters that don't have latitude/longitude
   * and identify top 10% supporters by engagementScore.
   */
  useEffect(() => {
    const geocodeAndSortSupporters = async () => {
      if (!supporters || supporters.length === 0) {
        setGeocodedSupporters([]);
        setTopTenSupporters([]);
        return;
      }

      // 1. Geocode any supporters missing coordinates.
      const geocoded = await Promise.all(
        supporters.map(async (supporter) => {
          // If supporter already has coordinates, skip geocoding.
          if (
            typeof supporter.latitude === 'number' &&
            typeof supporter.longitude === 'number'
          ) {
            return supporter;
          }

          const address = `${supporter.address}, ${supporter.city}, ${supporter.state} ${supporter.zip_code}`;
          try {
            const response = await getGeoLocation(address);
            if (response?.results?.length > 0) {
              const { lat, lng } = response.results[0].geometry.location;
              return {
                ...supporter,
                latitude: lat,
                longitude: lng,
              };
            }
          } catch (error) {
            console.error('Geocoding error:', error);
          }
          // If geocoding failed, or no results:
          return { ...supporter, latitude: undefined, longitude: undefined };
        })
      );

      // 2. Sort by engagementScore in descending order.
      const sorted = geocoded.sort(
        (a, b) => (b.engagementScore || 0) - (a.engagementScore || 0)
      );

      // 3. Identify top 10%.
      const topTenCount = Math.ceil(sorted.length * 0.1);
      const topTen = sorted.slice(0, topTenCount);

      setGeocodedSupporters(sorted);
      setTopTenSupporters(topTen);
    };

    geocodeAndSortSupporters();
  }, [supporters]);

  /**
   * Initialize or update MarkerClusterer whenever geocodedSupporters changes.
   */
  useEffect(() => {
    if (!mapRef.current) return;

    // Clear out existing cluster if it exists.
    if (markerClusterRef.current) {
      markerClusterRef.current.clearMarkers();
      markerClusterRef.current.setMap(null);
    }

    // Filter only supporters with valid coordinates.
    const markers = geocodedSupporters
      .filter((s) => typeof s.latitude === 'number' && typeof s.longitude === 'number')
      .map((supporter) => {
        const isTopTen = topTenSupporters.includes(supporter);
        const marker = new google.maps.Marker({
          position: { lat: supporter.latitude as number, lng: supporter.longitude as number },
          icon: isTopTen ? greenMarker : purpleMarker,
        });

        marker.addListener('click', () => handleMarkerClick(supporter));
        return marker;
      });

    // Use MarkerClusterer to group markers.
    markerClusterRef.current = new MarkerClusterer({
      map: mapRef.current,
      markers,
      renderer: {
        render: ({ count, position }) => {
          let icon = blueCircle;
          if (count >= 1000) {
            icon = purpleCircle;
          } else if (count >= 100) {
            icon = redCircle;
          } else if (count >= 10) {
            icon = yellowCircle;
          }
          return new google.maps.Marker({
            position,
            icon,
            label: {
              text: String(count),
              color: 'white',
              fontWeight: 'bold',
            },
          });
        },
      },
    });
  }, [geocodedSupporters, topTenSupporters]);

  /**
   * If selectedSupporter is set, center/zoom the map and show InfoWindow.
   * If selectedSupporter is cleared (null), also clear the infoWindowPos.
   */
  useEffect(() => {
    if (selectedSupporter && selectedSupporter.latitude && selectedSupporter.longitude) {
      setCenterMap({
        lat: selectedSupporter.latitude,
        lng: selectedSupporter.longitude,
      });
      setZoom(10);
      setInfoWindowPos({
        lat: selectedSupporter.latitude,
        lng: selectedSupporter.longitude,
      });
    } else {
      // If the parent clears selectedSupporter, close the info window:
      setInfoWindowPos(null);
    }
  }, [selectedSupporter, setCenterMap, setZoom, setInfoWindowPos]);

  /**
   * Called when a marker is clicked.
   */
  const handleMarkerClick = (supporter: Supporters) => {
    setSelectedSupporter(supporter);
    if (supporter.latitude && supporter.longitude) {
      setInfoWindowPos({ lat: supporter.latitude, lng: supporter.longitude });
    }
  };

  /**
   * Called when the map bounds change. You can optionally filter supporters.
   */
  const handleBoundsChanged = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      if (bounds) {
        // Example usage if you want to filter or do something with the visible supporters:
        // const visibleSupporters = geocodedSupporters.filter((supporter) => {
        //   if (supporter.latitude && supporter.longitude) {
        //     return bounds.contains({ lat: supporter.latitude, lng: supporter.longitude });
        //   }
        //   return false;
        // });
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* Legend explaining marker colors & cluster icons */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '10px',
                border: '1px solid #ccc',
                flexWrap: 'wrap',
                marginBottom: '8px',
              }}
            >
              {[
                { icon: greenMarker, label: 'Best Supporters (Top 10%)' },
                { icon: purpleMarker, label: 'All Other Supporters' },
                { icon: blueCircle, label: '1-9 Cluster' },
                { icon: yellowCircle, label: '10-99 Cluster' },
                { icon: redCircle, label: '100-999 Cluster' },
                { icon: purpleCircle, label: '>1000 Cluster' },
              ].map(({ icon, label }, index) => (
                <Box
                  key={index}
                  sx={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}
                >
                  <img
                    src={icon}
                    alt={label}
                    style={{ marginRight: '5px', maxWidth: '30px', height: 'auto' }}
                  />
                  <Typography variant="body2" sx={{ fontSize: '12px' }}>
                    {label}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>

        {/* Google Map */}
        <Box sx={{ borderRadius: '5px', overflow: 'hidden' }}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={centerMap}
            onLoad={(map) => {
              mapRef.current = map;
            }}
            onBoundsChanged={handleBoundsChanged}
            options={{
              gestureHandling: 'greedy', // Improve responsiveness
            }}
          >
            {/* Show InfoWindow for selected supporter */}
            {infoWindowPos && selectedSupporter && (
              <InfoWindow position={infoWindowPos} onCloseClick={handleCloseInfoWindow}>
                <div style={{ fontFamily: 'Arial, sans-serif', width: '220px' }}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                      marginBottom: '5px',
                    }}
                  >
                    {selectedSupporter.salutation || ''}{' '}
                    {selectedSupporter.first_name} {selectedSupporter.last_name}
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: '14px' }}>
                    Score:{' '}
                    <strong>{selectedSupporter.engagementScore || 0}</strong>
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: '14px' }}>
                    {selectedSupporter.address}
                    <br />
                    {selectedSupporter.city}, {selectedSupporter.state}{' '}
                    {selectedSupporter.zip_code}
                  </Typography>
                  {selectedSupporter.phone_number && (
                    <Typography variant="body2" style={{ fontSize: '14px' }}>
                      Phone: {selectedSupporter.phone_number}
                    </Typography>
                  )}
                  {selectedSupporter.considerationStage && (
                    <Typography variant="body2" style={{ fontSize: '14px' }}>
                      Stage: {selectedSupporter.considerationStage}
                    </Typography>
                  )}
                  {/* Link to open Google Maps in new tab */}
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      `${selectedSupporter.address}, ${selectedSupporter.city}, ${selectedSupporter.state} ${selectedSupporter.zip_code}`
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontSize: '14px',
                      color: '#007BFF',
                      textDecoration: 'underline',
                      display: 'block',
                      marginTop: '5px',
                    }}
                  >
                    View in Google Maps
                  </a>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SupportersMap;