import React, { useState } from 'react';
import { Typography, Box, Paper, Stepper, Step, StepLabel, LinearProgress, Grid, CircularProgress } from '@mui/material';
import FileUpload from './FileUpload';
import FieldMapping from './FieldMapping';
import { getPresignedUrl, submitMappings, uploadFile, getTaskStatus, getImportResults, getTempSupporters } from '../api/supporterService';
import ReviewData from './ReviewData';
import CustomStepper from './CustomStepper';
import FooterButtons from './FooterButtons';
import { FormatQuoteRounded } from '@mui/icons-material';
import ConfirmDataDialog from './ConfirmDataDialog';
import CustomLinearProgressBar from './CustomLinearProgressBar';
import ReviewDataDetails from './ReviewDataDetails';
import { useImportCSVContext } from '../contexts/ImportCSVContext.js';
import ConfirmImport from './ConfirmImport';

interface ImportCSVProps {
  cancel: (step: 'select' | 'importCsv' | 'importCrm') => void;
}

const ImportCSV: React.FC<ImportCSVProps> = ({ cancel }) => {
  const [csvFields, setCsvFields] = useState<string[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [fileKey, setFileKey] = useState<string | null>(null);
  const [url, setUrl] = useState<string | null>(null); // New state for URL
  const [loading, setLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [headers, setHeaders] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>('');
  const [sampleData, setSampleData] = useState<Record<string, string[]>>({});
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [taskStatusPercent, setTaskStatusPercent] = useState<number>(0);
  // improve when CRM integration is added
  const [isCRMImport, setIsCRMImport] = useState(false);
  const [detectedUpdatedAtField, setDetectedUpdatedAtField] = useState<string | null>(null);

  const { counts, fetchCategoriesData, finalizeSupporterImport, suspectedDuplicatesResolved, suspectedExistingResolved, resetContext } = useImportCSVContext();

  const dbFields = [
    'first_name',
    'last_name',
    'email',
    'external_id',
    'phone_number',
    'assigned_gift_officer_id',
    'salutation',
    'image',
    // 'updated_at'
  ];

  const steps = ['Choose File', 'Map Fields', 'Review Data', 'Confirm Import'];

  const onBack = () => {
    if (activeStep === 2) {
      resetContext();
    }

    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleConfirm = (selectedOption: string) => {
    console.log('Selected option:', selectedOption);
  }

  const handleStepUpdate = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleFileParsed = async (fields: string[], data: any[]) => {
    setCsvFields(fields);
    setData(data);
    console.log('Parsed fields:', fields);
    console.log('Parsed data:', data);
  
    const tempSampleData: Record<string, string[]> = {};
  
    // Initialize each field with an empty array
    fields.forEach(field => {
      tempSampleData[field] = [];
    });
  
    // Fill the arrays with up to 4 values for each field from the parsed data
    data.slice(0, 4).forEach(row => {
      fields.forEach(field => {
        if (row.hasOwnProperty(field)) {
          tempSampleData[field].push(row[field]);
        }
      });
    });
  
    setSampleData(tempSampleData);
  
    // **Automatically detect LastModifiedDate or UpdatedDate**
    // const detectedDateField = fields.find(field =>
    //   field.toLowerCase().includes("lastmodifieddate") || field.toLowerCase().includes("updateddate") || field.toLowerCase().includes("updatedat") || field.toLowerCase().includes("updated_at")
    // );
  
    // if (detectedDateField) {
    //   console.log(`Detected CRM updated field: ${detectedDateField}`);
    //   setDetectedUpdatedAtField(detectedDateField);
    //   setIsCRMImport(true); // This will mark it as a CRM import automatically
    // }
  };
  

  const handleFileUpload = async (file: File) => {
    try {
      setLoading(true);

      const data = await uploadFile(file);

      console.log('File key and URL:', data);

      setFileKey(data.file_key);
      setUrl(data.url); // Set the URL state
      setLoading(false);
      setActiveStep(1); // Move to the next step
    } catch (error) {
      console.error('Error uploading file:', error);
      setLoading(false);
    }
  };

  const pollTaskStatus = async (taskId: string, interval: number = 6000, maxAttempts: number = 100): Promise<boolean> => {
    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      try {
        const taskStatus = await getTaskStatus(taskId);

        setTaskStatusPercent((taskStatus.progress || 0));
        
  
        if (taskStatus && taskStatus.status === 'complete' && taskStatus.progress === 100) {
          return true; // Task is complete
        }
  
        console.log(`Attempt ${attempt}: Task status - ${taskStatus?.status}, progress - ${taskStatus?.progress}%. Retrying in ${interval / 1000} seconds...`);
        await new Promise(resolve => setTimeout(resolve, interval)); // Wait before retrying
      } catch (error) {
        console.error(`Attempt ${attempt}: Error fetching task status`, error);
        await new Promise(resolve => setTimeout(resolve, interval)); // Wait before retrying
      }
    }
  
    return false; // Return false if the task did not complete within the maximum number of attempts
  };

  const handleMappingSubmit = async (mappings: Record<string, string>) => {
    if (!fileKey) return;
  
    try {
      setLoading(true);
  
      // If a LastModifiedDate/UpdatedDate column was detected, automatically map it
      if (detectedUpdatedAtField) {
        mappings["updated_at"] = detectedUpdatedAtField;
      }
  
      console.log("Mappings with updated_at:", mappings);
  
      // Submit mappings to the API
      const submitResponse = await submitMappings(fileKey, mappings);
      console.log("Mappings submitted successfully:", submitResponse);
  
      const taskId = submitResponse.taskId;
  
      if (taskId) {
        const isCompleted = await pollTaskStatus(taskId);
  
        if (isCompleted) {
          await fetchCategoriesData(taskId);
          setActiveStep(2);
        } else {
          console.error('Task did not complete within the maximum attempts');
        }
      } else {
        console.error('No taskId returned from submitMappings');
      }
    } catch (error) {
      console.error('Error during mapping submission or fetching import results:', error);
    } finally {
      setLoading(false);
    }
  };  

  const handleConfirmClick = () => {
    if (activeStep === 3) {
      setShowConfirmDialog(true); // Show the dialog only if it's the final step
    }
  };

  const { 
    incompleteIDs,
    suspectedDuplicateIDs,
    suspectedExistingIDs,
    unsubscribedIDs,
    updatingIDs,
    existingIDs,
    cleanIDs,
    skipSuspectedDuplicateIDs,
    skipSuspectedExistingIDs,
    suspectedDuplicateSkipAll,
    suspectedExistingSkipAll
   } = useImportCSVContext();

  const handleReviewDataConfirm = () => {
     console.log({
      'incompleteIDs': incompleteIDs,
      'suspectedDuplicateIDs': suspectedDuplicateIDs,
      'suspectedExistingIDs': suspectedExistingIDs,
      'unsubscribedIDs': unsubscribedIDs,
      'updatingIDs': updatingIDs,
      'existingIDs': existingIDs,
      'cleanIDs': cleanIDs,
      'skipSuspectedDuplicateIDs': skipSuspectedDuplicateIDs,
      'skipSuspectedExistingIDs': skipSuspectedExistingIDs,
      'suspectedDuplicateSkipAll': suspectedDuplicateSkipAll,
      'suspectedExistingSkipAll': suspectedExistingSkipAll
     })

     finalizeSupporterImport();

     setActiveStep(0);

     resetContext();
  };

  const handleDialogClose = () => {
    setShowConfirmDialog(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item display="flex" justifyContent="center" alignItems="center" xs={12}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Inter', textAlign: 'left', color: '#33475B' }}> Import Supporters from CSV</Typography>
      </Grid>
      
      <Grid item xs={12}>
        <CustomStepper steps={steps} activeStep={activeStep} />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={11} sx={{ margin: "0 auto" }}>
          {activeStep === 0 && (
            <>
              <Typography variant="body1" gutterBottom>
                Please import your list in .CSV (Comma Separated Values) format below.
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>NOTE:</strong> The following fields are mandatory.
              </Typography>
              <ul style={{ textAlign: "left" }}>
                <li>First Name</li>
                <li>Last Name</li>
                <li>Donor ID</li>
                <li>Email Address</li>
              </ul>
              {loading ? (
                <Box p={4} border="1px dashed grey" borderRadius={4} textAlign="center" bgcolor="background.paper">
                  {/* <LinearProgress /> */}
                  {fileName && (
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                      {fileName}
                    </Typography>
                  )}
                </Box>
              ) : (
                <FileUpload onFileParsed={handleFileParsed} onFileUpload={handleFileUpload} cancel={() => cancel('select')} onBack={onBack} activeStep={activeStep} headers={headers} setHeaders={setHeaders} setFileName={setFileName} fileName={fileName} setSampleData={setSampleData} />
              )}
            </>
          )}
          {activeStep === 1 && (
            loading ? (
              <Box p={4} border="1px dashed grey" borderRadius={4} textAlign="center" bgcolor="background.paper" height="400px">
                <Paper elevation={0} sx={{ margin: "20px 0", padding: "20px", borderRadius: '5px', border: '2px solid #C8D7E1', background: '#FFF' }}>
                  <Typography variant="h5" gutterBottom fontFamily={'Inter'} color='#33475B'>
                    Importing your file...
                  </Typography>
                  <CircularProgress size={100} thickness={1.5} />
                </Paper>
              </Box>
            ) : (
            <>
              <Typography variant="body1" gutterBottom fontFamily={'Inter'} color='#33475B'>
                Match the columns from the dashboard fields to the columns in your CSV file then review your mapping.
              </Typography>
              <FieldMapping 
                csvFields={csvFields} 
                dbFields={dbFields} onSubmit={handleMappingSubmit} 
                onBack={onBack} 
                cancel={() => cancel('select')}
                activeStep={activeStep} headers={headers} setHeaders={setHeaders} sampleData={sampleData} requiredFields={['first_name','last_name', 'email']} onConfirmMapping={handleStepUpdate} />
            </>)
          )}
          {activeStep === 2 && (
            <>
              <ReviewData 
                  filename={fileName} 
                  onBack={onBack}
                  counts={counts}
                  reset={() => cancel('select')}
              />
              <FooterButtons onClickCancel={onBack} onClickConfirm={handleStepUpdate} cancelLabel="Back" confirmDisabled={!(counts['Suspected Duplicate Records'] === 0 && counts['Suspected Existing Records'] === 0)}></FooterButtons>
            </>
          )}
          {activeStep === 3 && (
            <>
              <ConfirmImport/>
              <FooterButtons onClickCancel={onBack} onClickConfirm={handleReviewDataConfirm} />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ImportCSV;
