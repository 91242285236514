import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  Paper,
  Button,
  Typography,
  Pagination,
  Grid,
  Menu,
  MenuItem,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ViewSwitchIconList from '../../../components/icons/view_supporters/ViewSwitchIconList.svg';
import ViewSwitchIconGrid from '../../../components/icons/view_supporters/ViewSwitchIconGrid.svg';
import MapIcon from '../../../components/icons/view_supporters/MapIcon.svg';
import FilterIcon from '../../../components/icons/view_supporters/FilterIcon.svg';
import SupporterCard from '../../../components/view_supporters/SupporterCard';
import SupporterCardHorizontal from '../../../components/view_supporters/SupporterCardHorizontal';
import SupportersTable from '../../../components/view_supporters/SupportersTable';
import SupportersMap from '../../../components/view_supporters/SupportersMap';
import UnsubscribeIcon from '../../../components/icons/view_supporters/Unsubscribe.svg';
import ResubscribeIcon from '../../../components/icons/view_supporters/Resubscribe.svg';
import SaveToListIcon from '../../../components/icons/view_supporters/SaveToList.svg';
import MarkAsDeceasedIcon from '../../../components/icons/view_supporters/MarkAsDeceased.svg';
import UnmarkAsDeceasedIcon from '../../../components/icons/view_supporters/Un-markAsDeceased.svg';
import AssignGiftOfficerIcon from '../../../components/icons/view_supporters/AssignGiftOfficer.svg';
import AssignEmailSignerIcon from '../../../components/icons/view_supporters/AssignEmailSigner.svg';
import RemoveFromListIcon from '../../../components/icons/view_supporters/RemoveFromList.svg';
import BulkActionsIcon from '../../../components/icons/view_supporters/BulkActions.svg';
import AddToEffortIcon from '../../../components/icons/view_supporters/AddToEffort.svg';
import ConfirmBulkActionDialog from '../../../components/view_supporters/ConfirmBulkActionDialog';
import FilterSidebar from '../../../components/view_supporters/FilterSidebar';
import { useAuth } from '../../../contexts/AuthContext';

import {
  getAllSupportersByOrg,
  updateSupportersWithAction,
  createSupporterList,
  updateSupporterList,
} from '../../../api/supporterService';

interface Supporters {
  salutation?: string | null;
  assigned_gift_officer_id?: number | null;
  unsubscribed?: boolean | null;
  external_id?: string | null;
  group_id?: number | null;
  org_id?: number | null;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string | null;
  address?: string;
  city?: string | null;
  state?: string | null;
  zip_code?: string | null;
  image?: string | null;
  version?: number;
  is_head?: boolean;
  id: number;
  created_at?: string;
  created_by?: string | null;
  advancement_stage?: string | null;
  engagementScore?: number;
  considerationStage?: string;
  latitude?: number;
  longitude?: number;
}

const SupportersView: React.FC = () => {
  const [supporters, setSupporters] = useState<Supporters[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [isListView, setIsListView] = useState(true);
  const [isMapSelected, setIsMapSelected] = useState(false);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(3);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedSupporter, setSelectedSupporter] = useState<Supporters | null>(null);
  const [centerMap, setCenterMap] = useState({ lat: 39.8283, lng: -98.5795 });
  const [zoom, setZoom] = useState(3);
  const [searchQuery, setSearchQuery] = useState('');
  
  // IMPORTANT: This is the array of selected supporter IDs.
  const [allSupportersSelected, setAllSupportersSelected] = useState<number[]>([]);

  const isBulkActionsOpen = Boolean(anchorEl);
  const [selectedList, setSelectedList] = useState<any>(null);
  const [selectedFilterLists, setSelectedFilterLists] = useState<Set<number>>(new Set());
  const [selectedOfficer, setSelectedOfficer] = useState('');
  const [selectedSigner, setSelectedSigner] = useState('');
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [infoWindowPos, setInfoWindowPos] = useState<{ lat: number; lng: number } | null>(null);
  const { user } = useAuth();

  const handleCloseInfoWindow = () => {
    setSelectedSupporter(null);
    setInfoWindowPos(null);
  };

  const handleFilterClick = () => {
    setIsFilterSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setIsFilterSidebarOpen(false);
  };

  const handleBulkActionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBulkActionsClose = () => {
    setAnchorEl(null);
  };

  // Conditionally enable the button based on whether supporters are selected
  const isBulkActionsEnabled = allSupportersSelected.length > 0;

  const bulkActions = [
    { label: "Unsubscribe", icon: UnsubscribeIcon },
    { label: "Resubscribe", icon: ResubscribeIcon },
    { label: "Save to List", icon: SaveToListIcon },
    { label: "Mark as Deceased", icon: MarkAsDeceasedIcon },
    { label: "Un-mark as Deceased", icon: UnmarkAsDeceasedIcon },
    { label: "Assign Gift Officer", icon: AssignGiftOfficerIcon },
    { label: "Assign Email Signer", icon: AssignEmailSignerIcon },
    { label: "Remove from List", icon: RemoveFromListIcon },
    { label: "Add to Effort", icon: AddToEffortIcon },
  ];

  const handleBulkActionSelect = (action: string) => {
    setSelectedAction(action);
    setDialogOpen(true);
    handleBulkActionsClose();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = async () => {
    try {
      setDialogOpen(false);
      let response;

      const actionKeyMapping: { [key: string]: string } = {
        'Unsubscribe': 'unsubscribe',
        'Resubscribe': 'resubscribe',
        'Mark as Deceased': 'mark_as_deceased',
        'Un-mark as Deceased': 'unmark_as_deceased',
        'Assign Gift Officer': 'assign_gift_officer',
        'Assign Email Signer': 'assign_email_signer',
      };

      if (selectedAction in actionKeyMapping) {
        const actionKey = actionKeyMapping[selectedAction];
        const actionValue = true; // Hardcoded since the backend expects this format
        const actionUpdate = { [actionKey]: actionValue };

        response = await updateSupportersWithAction(actionUpdate, allSupportersSelected);
      } else if (selectedAction === 'Save to List' || selectedAction === 'Remove from List') {
        // Use the selectedList directly
        response = await updateSupporterList(
          selectedList.id,
          allSupportersSelected,
          selectedAction === 'Remove from List'
        );
      }
    } catch (error) {
      console.error(`Error performing ${selectedAction}:`, error);
    }
  };

  const handleViewSwitch = () => {
    setIsListView(!isListView);
  };

  const handleMapClick = () => {
    setIsMapSelected(!isMapSelected);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleSupporterClick = (supporter: Supporters) => {
    setSelectedSupporter(supporter);
    if (supporter.latitude !== undefined && supporter.longitude !== undefined) {
      setCenterMap({ lat: supporter.latitude, lng: supporter.longitude });
      setZoom(5);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const cardWidth = 350;
    const cardMargin = 16;
    const containerPadding = 32;
    const availableWidth = windowWidth - containerPadding;

    if (isMapSelected) {
      setCardsPerPage(2);
    } else {
      const maxCardsPerRow = Math.floor(
        (availableWidth + cardMargin) / (cardWidth + cardMargin * 5)
      );
      setCardsPerPage(maxCardsPerRow);
    }
  }, [windowWidth, isMapSelected]);

  // Fetch supporters for org_id 30010
  useEffect(() => {
    const fetchSupporters = async () => {
      try {
        console.log("user?.org_id", user?.org_id); 
        const response = await getAllSupportersByOrg(user?.org_id || 0);
        setSupporters(response.results);
      } catch (error) {
        console.error("Error fetching supporters:", error);
      }
    };
    fetchSupporters();
  }, []);

  // Filter supporters based on the search query
  const filteredSupporters = supporters.filter((supporter) => {
    const fullName = `${supporter.first_name.toLowerCase()} ${supporter.last_name.toLowerCase()}`;
    return (
      fullName.includes(searchQuery) || 
      supporter.email.toLowerCase().includes(searchQuery)
    );
  });

  const paginatedSupporters = filteredSupporters.slice(
    (currentPage - 1) * cardsPerPage,
    currentPage * cardsPerPage
  );
  const totalPages = Math.ceil(filteredSupporters.length / cardsPerPage);

  return (
    <Grid sx={{ width: '100%', maxWidth: 'calc(100%)', margin: '0 auto' }}>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Supporters
      </Typography>

      {/* Top Bar */}
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row', md: 'row' }} alignItems={{ xs: 'flex-start', sm: 'center', md: 'center' }} justifyContent="space-between" marginLeft={-1}>
        {/* Left side: View switcher, Map, and Filter options */}
        <Box display="flex" alignItems="center" gap={2}>
          <IconButton onClick={handleViewSwitch} disableRipple sx={{ pr: 0 }}>
            <img
              src={isListView ? ViewSwitchIconGrid : ViewSwitchIconList}
              alt="View switch"
              width={70}
              height={70}
              aria-label={isListView ? 'List View' : 'Card View'}
            />
          </IconButton>
          <Button
            variant="outlined"
            onClick={handleMapClick}
            aria-label='Toggle Map'
            startIcon={<img src={MapIcon} alt="Map view" />}
            sx={{
              backgroundColor: isMapSelected
                ? 'rgba(108, 142, 165, 0.42)'
                : 'rgba(108, 142, 165, 0.17)',
              color: '#406074',
              borderColor: '#6C8EA5',
              '&:hover': {
                backgroundColor: 'rgba(108, 142, 165, 0.42)',
              },
              fontSize: '1rem',
              padding: '3px 12px',
            }}
          >
            Map
          </Button>
          <Button
            variant="outlined"
            onClick={handleFilterClick}
            aria-label='Toggle Filter Options'
            startIcon={<img src={FilterIcon} alt="Filter options" />}
            sx={{
              backgroundColor: isFilterSelected
                ? 'rgba(108, 142, 165, 0.42)'
                : 'rgba(108, 142, 165, 0.17)',
              color: '#406074',
              borderColor: '#6C8EA5',
              '&:hover': {
                backgroundColor: 'rgba(108, 142, 165, 0.42)',
              },
              fontSize: '1rem',
              padding: '3px 12px',
            }}
          >
            Filters
          </Button>
        </Box>

        {/* Right side: Search */}
        <Box display="flex" alignItems="center" gap={2} sx={{ mb: { xs: '16px' , sm: '0px', md: '0px', lg: '0px' } }}>
        <Paper
            component="form"
            sx={{
              display: 'flex',
              alignItems: 'center',
              minWidth: {xs: '300px', sm: '330px'},
              maxWidth: 480,
              height: 35,
              borderRadius: '5px',
              border: '1px solid #6C8EA5',
              background: 'rgba(108, 142, 165, 0.08)',
              boxShadow: 'none',
              padding: '2px 4px',
              ml: '0.5rem',
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by name or email"
              inputProps={{ 'aria-label': 'search by name or email' }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          
        </Box>
      </Box>
        
      {/* Filter Sidebar */}
      <FilterSidebar
        isOpen={isFilterSidebarOpen}
        onClose={handleSidebarClose}
        selectedFilterLists={selectedFilterLists}
        setSelectedFilterLists={setSelectedFilterLists}
      />
          
      {/* Bulk Actions */}
      <Box display="flex" alignItems="center" gap={2} sx={{ marginBottom: 2 }}>
        <Typography variant="body1" sx={{ color: '#406074' }}>
          {allSupportersSelected.length} selected
        </Typography>
        
        <Box
          component="span"
          sx={{
            height: '24px',
            width: '1px',
            backgroundColor: '#406074',
          }}
        />

        {/* Bulk Actions Button with Icon */}
        <Button
          disableRipple
          disableElevation
          onClick={handleBulkActionsClick}
          disabled={!isBulkActionsEnabled}
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#406074',
            textTransform: 'none',
            fontWeight: 'bold',
            padding: '0',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <img
            src={BulkActionsIcon}
            alt="Bulk Actions"
            width={16}
            height={16}
            style={{ marginRight: '8px' }}
          />
          Bulk Actions
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={isBulkActionsOpen}
          onClose={handleBulkActionsClose}
          PaperProps={{
            elevation: 0,
            sx: {
              maxWidth: 220,
              border: '2px solid #C8D7E1',
              borderRadius: '8px',
              boxShadow: 'none',
            },
          }}
        >
          {bulkActions.map((action) => (
            <MenuItem
              key={action.label}
              onClick={() => handleBulkActionSelect(action.label)}
              disableRipple
            >
              <img
                src={action.icon}
                alt={action.label}
                width={16}
                height={16}
                style={{ marginRight: '8px' }}
              />
              {action.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      {/* Confirm Bulk Action Dialog */}
      <ConfirmBulkActionDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        actionType={selectedAction}
        selectedSupporterCount={allSupportersSelected.length}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        onConfirm={handleDialogConfirm}
        selectedFilterLists={selectedFilterLists}
      />

      {/* Main Content Area */}
      <Grid container spacing={2}>
        {/* Adjust Grid layout if map is shown */}
        <Grid item xs={12} sm={12} md={isMapSelected ? 6 : 12} lg={isMapSelected ? 6 : 12}>
          {isListView ? (
            <SupportersTable
              supporters={filteredSupporters}
              handleSupporterClick={handleSupporterClick}
              // Pass the parent's state updater so the table can manage checked rows:
              onSelectSupporters={setAllSupportersSelected}
            />
          ) : (
            <>
              {isMapSelected ? (
                <Box display="flex" flexDirection="column" gap={2}>
                  {paginatedSupporters.slice(0, 2).map((supporter, index) => (
                    <Box key={index} sx={{ width: '100%' , borderRadius: '10px', border: '2px solid #C8D7E1' }}>
                      <SupporterCardHorizontal person={supporter} />
                    </Box>
                  ))}
                </Box>
              ) : (
                <Grid container
                  spacing={2}
                  display="table"
                  justifyContent="center"
                  sx={{ tableLayout: 'fixed'  , borderCollapse: 'separate', borderSpacing: '1em'  }}
                >
                  {paginatedSupporters.map((supporter, index) => (
                    <Grid item 
                      xs={12} 
                      sm={12} 
                      md={4} 
                      lg={4} 
                      key={index} 
                      display="table-cell" 
                      maxWidth="850px"
                      sx={{ borderRadius: '10px', border: '2px solid #C8D7E1' , paddingTop: '0px !important', paddingLeft: '0px !important' }}
                    >
                      <SupporterCardHorizontal person={supporter} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          )}
        </Grid>

        {isMapSelected && (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <SupportersMap
              supporters={supporters}
              selectedSupporter={selectedSupporter}
              setSelectedSupporter={setSelectedSupporter}
              centerMap={centerMap}
              setCenterMap={setCenterMap}
              zoom={zoom}
              setZoom={setZoom}
              infoWindowPos={infoWindowPos}
              setInfoWindowPos={setInfoWindowPos}
              handleCloseInfoWindow={handleCloseInfoWindow}
            />
          </Grid>
        )}
      </Grid>

      {/* Pagination for card/grid view */}
      {!isListView && totalPages > 1 && (
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </Grid>
  );
};

export default SupportersView;
