import React, { useState } from 'react';
import stewardshipExample from '../../assets/images/stewardshipExample.png';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface StewardshipLearnMoreProps {
  onBack: () => void;
}

const StewardshipLearnMore: React.FC<StewardshipLearnMoreProps> = ({ onBack }) => {
  const [showImageDialog, setShowImageDialog] = useState(false);

  return (
    <div style={{ padding: '1rem', textAlign: 'left', position: 'relative' }}>
      {/* Back Button and Page Header */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        <button
          onClick={onBack}
          style={{
            background: 'none',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            marginRight: '0.5rem',
          }}
        >
          <ArrowBackIosIcon style={{ fontSize: '2rem', color: '#004F6E' }} />
        </button>
        <h1
          style={{
            color: '#004F6E',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 400,
            fontSize: '2rem',
            margin: 0,
          }}
        >
          Stewardship Series
        </h1>
      </div>
      <p
        style={{
          color: '#000',
          fontFamily: 'Inter, sans-serif',
          fontSize: '1rem',
          marginBottom: '1.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        Steward your legacy society members to thank them for their support and give them the special attention they deserve.
      </p>

      {/* Impact Recommendation Box */}
      <div
        style={{
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '1.5rem',
          marginBottom: '2rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <h2
          style={{
            color: '#004F6E',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 400,
            fontSize: '1.5rem',
            margin: '0 0 0.75rem 0',
            paddingLeft: '0.5rem',
          }}
        >
          Impact Recommendation
        </h2>
        <div
          style={{
            fontFamily: 'Inter, sans-serif',
            fontSize: '1rem',
            lineHeight: '1.5',
            marginBottom: '1rem',
            paddingLeft: '0.5rem',
          }}
        >
          <p style={{ margin: '0 0 0.5rem 0' }}>
            <strong>You have 128 supporters who are eligible for this Effort!</strong>
          </p>
          <p style={{ margin: '0 0 0.5rem 0' }}>
          <strong>Why the Stewardship Series appeals to your supporters:</strong>
          </p>
          <ul style={{ margin: 0, paddingLeft: '1.5rem' }}>
            <li>Makes them feel like a special partner in the organization's mission</li>
            <li>Gives notoriety and recognition to donors for their support</li>
            <li>
              Provides valuable offers to donors so they can learn more about how they can further their impact
            </li>
          </ul>
        </div>
        <button
          style={{
            backgroundColor: '#004F6E',
            color: '#fff',
            fontFamily: 'Inter, sans-serif',
            border: 'none',
            padding: '0.5rem 1rem',
            borderRadius: '4px',
            cursor: 'pointer',
            alignSelf: 'flex-start',
            marginLeft: '0.5rem',
          }}
        >
          CONTACT MY CSM
        </button>
      </div>

      {/* Bottom Section */}
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-start' }}>
        {/* Square Image */}
        <div style={{ flex: '0 0 auto' }}>
          <img
            onClick={() => setShowImageDialog(true)}
            src={stewardshipExample}
            alt="Stewardship Series"
            style={{
              width: '300px',
              height: '300px',
              objectFit: 'cover',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
          />
        </div>
        {/* What's Included Box */}
        <div
          style={{
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '1.5rem',
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <h2
            style={{
              color: '#004F6E',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 400,
              fontSize: '1.5rem',
              margin: '0 0 0.75rem 0',
              paddingLeft: '0.5rem',
            }}
          >
            What's Included:
          </h2>
          <ul
            style={{
              fontFamily: 'Inter, sans-serif',
              fontSize: '1rem',
              lineHeight: '1.5',
              margin: 0,
              paddingLeft: '1.5rem',
            }}
          >
            <li>(1) Invite Email</li>
            <li>(1) Thank You Email</li>
            <li>(6) Stewardship Email Series</li>
            <li>(1) Birthday Email</li>
            <li>Stewardship Questionnaire Landing Page & Form</li>
          </ul>
        </div>
      </div>

      {/* Image Dialog */}
      {showImageDialog && (
        <div
          onClick={() => setShowImageDialog(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
            cursor: 'pointer',
          }}
        >
          <img
            src={stewardshipExample}
            alt="Stewardship Series Large"
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              objectFit: 'contain',
              borderRadius: '8px',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default StewardshipLearnMore;
