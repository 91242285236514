import React, { useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import CustomStepper from '../../components/CustomStepper';

// Step Components
import EnterURLPage from '../../components/content_audit/EnterURLPage';
import AuditSite from '../../components/content_audit/AuditSite';
import AuditReport from '../../components/content_audit/AuditReport';

const ContentAudit: React.FC = () => {
  const steps = ['Enter URL', 'Audit Site', 'Audit Report'];
  const [activeStep, setActiveStep] = useState<number>(0);

  // Show/hide the "View Report" button on the AuditSite step
  const [showViewReport, setShowViewReport] = useState<boolean>(true);

  // Store URL + stats for the audit
  const [auditData, setAuditData] = useState({
    url: '',
    pagesCrawled: 42,
    pagesMeetRequirements: 30,
    pagesNeedMoreInfo: 5,
    topicsMissing: 2,
    pagesFailed: 3,

    // New arrays for the dropdowns in AuditReport:
    crawledURLs: [
      'https://imarketsmart.com/',
      'https://imarketsmart.com/about/',
      'https://imarketsmart.com/contact/',
    ],
    meetReqURLs: [
      'https://imarketsmart.com/about/',
      'https://imarketsmart.com/contact/',
    ],
    missingTopicsList: ['Planned Giving Info', 'Major Gifts FAQ'],
    failedURLsList: [
      'https://imarketsmart.com/blog/old-post',
      'https://imarketsmart.com/dummy-page',
    ],
  });

  // Whether the site passed the audit or not
  const [passedAudit, setPassedAudit] = useState<boolean>(false);

  const handleNext = () => {
    setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const handleSetURL = (url: string) => {
    setAuditData((prev) => ({ ...prev, url }));
    handleNext(); // Move to step 1 (AuditSite) after setting URL
  };

  const handleViewReport = () => {
    // Demonstration logic: randomly pass or fail
    setPassedAudit(Math.random() > 0.5);
    handleNext(); // Move to step 2 (AuditReport)
  };

  return (
    <Grid container spacing={2}>
      {/* Title */}
      <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Inter', color: '#33475B' }}>
            Website Content Audit
          </Typography>
        </Box>
      </Grid>

      {/* Stepper */}
      <Grid item xs={12}>
        <CustomStepper steps={steps} activeStep={activeStep} />
      </Grid>

      {/* Step Content */}
      <Grid item xs={8} sx={{ margin: '0 auto' }}>
        {activeStep === 0 && <EnterURLPage onNext={handleSetURL} />}
        {activeStep === 1 && (
          <AuditSite
            auditData={auditData}
            showViewReport={showViewReport}
            onViewReportClick={handleViewReport}
          />
        )}
        {activeStep === 2 && <AuditReport auditData={auditData} passedAudit={passedAudit} />}
      </Grid>
    </Grid>
  );
};

export default ContentAudit;
