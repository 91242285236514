import React, { useState } from 'react';
import {
  Box,
  Paper,
  InputBase,
  Typography,
  Checkbox,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

interface Filter {
  id: number;
  name: string;
}

interface SavedFiltersProps {
  filters: Filter[];
  selectedFilters: Set<number>;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Set<number>>>;
}

const SavedFilters: React.FC<SavedFiltersProps> = ({
  filters,
  selectedFilters,
  setSelectedFilters,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredFilters = filters.filter((filter) =>
    filter.name.toLowerCase().includes(searchQuery)
  );

  const handleToggleSelect = (filterId: number) => {
    setSelectedFilters((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.has(filterId)
        ? newSelected.delete(filterId)
        : newSelected.add(filterId);
      return newSelected;
    });
  };

  return (
    <Box sx={{ padding: '1em 0' }}>
      {/* Search bar */}
      <Paper
        component="form"
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: { sm: '100px' , md: '150px' , lg: '250px' },
          width: '100%',
          height: 35,
          borderRadius: '5px',
          border: '1px solid #6C8EA5',
          background: 'rgba(108, 142, 165, 0.08)',
          boxShadow: 'none',
          padding: '2px 4px',
          mb: 4,
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search filters..."
          inputProps={{ 'aria-label': 'search for filters' }}
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </Paper>

      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Inter',
          fontWeight: 700,
          color: '#000',
          fontSize: '18px',
        }}
      >
        Select Filter(s)
      </Typography>

      {/* List of checkable filters */}
      <List>
        {filteredFilters.map((filter) => (
          <ListItem
            key={filter.id}
            button
            disablePadding
            disableRipple
            onClick={() => handleToggleSelect(filter.id)}
            sx={{ marginBottom: '2px' }}
          >
            <Checkbox
              checked={selectedFilters.has(filter.id)}
              onClick={(e) => e.stopPropagation()} // Prevent list item click
              onChange={() => handleToggleSelect(filter.id)}
              color="primary"
              disableRipple
              sx={{ paddingLeft: 0, paddingBottom: 0 , paddingTop: 0 }}
            />
            <ListItemText
              primary={filter.name}
              sx={{
                fontFamily: 'Inter',
                fontWeight: 500,
                color: '#004F6E',
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SavedFilters;
