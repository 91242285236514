import React from 'react';
import { Card, CardActionArea, CardContent, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useActivePage } from '../../contexts/ActivePageContext';
import { useAuth } from '../../contexts/AuthContext';
import SupporterOption from '../../components/SupporterOption';

interface DashboardProps {
    selectedOrg?: any;
    setSelectedOrg?: React.Dispatch<React.SetStateAction<any>>;
}

const Dashboard: React.FC<DashboardProps> = ({ selectedOrg, setSelectedOrg }) => {
   const { logout, isLoading, setActivePage } = useAuth();

   return (
        <div className="dashboard">
            <h1>Dashboard</h1>
            {/* Add Supporter Options Below */}
            <Grid container spacing={2} style={{ padding: '20px' }}>
              <SupporterOption title="Import Supporters" page="import-supporters" setActivePage={setActivePage} />
              <SupporterOption title="Import History" page="import-history" setActivePage={setActivePage} />
              <SupporterOption title="View Supporters" page="view-supporters" setActivePage={setActivePage} />
              <SupporterOption title="Email Settings" page="org-email-settings" setActivePage={setActivePage} />
              <SupporterOption title="Content Audit" page="content-audit" setActivePage={setActivePage} />
              <SupporterOption title="Email Overview" page="email-overview" setActivePage={setActivePage} />
              <SupporterOption title="Effort Overview" page="effort-overview" setActivePage={setActivePage} />
            </Grid>
        </div>
    );
};

export default Dashboard;
