import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AuditData {
  url: string;
  pagesCrawled: number;
  pagesMeetRequirements: number;
  pagesNeedMoreInfo: number;
  topicsMissing: number;
  pagesFailed: number;

  // Arrays for dropdown content:
  crawledURLs: string[];
  meetReqURLs: string[];
  missingTopicsList: string[];
  failedURLsList: string[];
}

interface AuditReportProps {
  auditData: AuditData;
  passedAudit: boolean; // true => pass, false => action required
}

const AuditReport: React.FC<AuditReportProps> = ({ auditData, passedAudit }) => {
  const {
    url,
    pagesCrawled,
    pagesMeetRequirements,
    pagesNeedMoreInfo,
    topicsMissing,
    pagesFailed,
    crawledURLs,
    meetReqURLs,
    missingTopicsList,
    failedURLsList,
  } = auditData;

  // Stats for the 5 top boxes
  const stats = [
    {
      label: 'Total Pages Crawled',
      count: pagesCrawled,
      color: '#458D0C', // green
    },
    {
      label: 'Pages Meet Requirements',
      count: pagesMeetRequirements,
      color: '#458D0C', // green
    },
    {
      label: 'Pages Need More Info',
      count: pagesNeedMoreInfo,
      color: '#E4BB07', // yellow
    },
    {
      label: 'Required Topics Missing',
      count: topicsMissing,
      color: '#BB4E4E', // red
    },
    {
      label: 'Pages Failed to Crawl',
      count: pagesFailed,
      color: '#BB4E4E', // red
    },
  ];

  // Four categories for the dropdowns (excluding "Pages Need More Info")
  const dropdowns = [
    {
      title: 'Total Pages Crawled',
      color: '#458D0C',
      items: crawledURLs,
    },
    {
      title: 'Pages Meet Requirements',
      color: '#458D0C',
      items: meetReqURLs,
    },
    {
      title: 'Required Topics Missing',
      color: '#BB4E4E',
      items: missingTopicsList,
    },
    {
      title: 'Pages Failed to Crawl',
      color: '#BB4E4E',
      items: failedURLsList,
    },
  ];

  return (
    <Box>
      {/* Heading */}
      <Typography
        variant="h5"
        mt={-1}
        mb={2}
        sx={{ fontFamily: 'Inter', color: '#004F6E' }}
      >
        Audit Summary for {url || 'No URL Provided'}
      </Typography>

      {/* Pass/Fail Box + Explanation */}
      {passedAudit ? (
        <Box display="flex" alignItems="center" gap={2} mb={2}>
          {/* Green Pass Box */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '3px',
              border: '1px solid #458D0C',
              backgroundColor: '#E5FAD4',
              color: '#458D0C',
              px: 1.5,
              py: 0.5,
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontFamily: 'Inter', textAlign: 'center', fontWeight: 'bold' }}
            >
              Pass
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{ fontFamily: 'Inter', color: '#33475B' }}
          >
            The website {url || 'No URL Provided'} passes our requirements to omit MarketSmart’s Microsite
            from your Engagement Fundraising System.
          </Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" gap={2} mb={2}>
          {/* Red Action Required Box */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '3px',
              border: '1px solid #A62929',
              backgroundColor: '#FFDEDE',
              color: '#A62929',
              px: 1.5,
              py: 0.5,
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontFamily: 'Inter', textAlign: 'center', fontWeight: 'bold' }}
            >
              Action Required
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{ fontFamily: 'Inter', color: '#33475B' }}
          >
            The website {url || 'No URL Provided'} fails our requirements to omit MarketSmart’s Microsite
            from your Engagement Fundraising System.
          </Typography>
        </Box>
      )}

      {/* Disclaimer text: Only show if NOT passed */}
      {!passedAudit && (
        <Typography variant="body2" sx={{ fontFamily: 'Inter', mb: 3 }}>
          Failing to address the issues will require that our Microsite be a part of your Engagement
          Fundraising System. While we strive for accuracy, please keep in mind that the audit may not
          be perfect if certain pages couldn't be assessed. Please review the details of the results
          and if you have any questions, please contact your Implementation Specialist.
        </Typography>
      )}

      {/* Five top boxes for high-level stats */}
      <Grid container columns={5} spacing={2} sx={{ mb: 3 }}>
        {stats.map((stat) => (
          <Grid item xs={1} key={stat.label}>
            <Box
              sx={{
                borderRadius: '16px',
                backgroundColor: '#FFF',
                boxShadow: '0px 0px 11px 4px rgba(124, 144, 163, 0.15)',
                p: 2,
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {/* Circle with the count */}
              <Box
                sx={{
                  width: 70,
                  height: 70,
                  borderRadius: '50%',
                  border: `3px solid ${stat.color}`,
                  color: stat.color,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '26px',
                  fontWeight: 'bold',
                  margin: '0 auto',
                }}
              >
                {stat.count}
              </Box>

              {/* Label under the circle */}
              <Typography
                variant="body1"
                sx={{
                  color: '#33475B',
                  fontFamily: 'Inter',
                  fontWeight: 'bold',
                  mt: 1,
                }}
              >
                {stat.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Four vertical "dropdown" boxes (Accordion) */}
        <Box>
        {dropdowns.map((category) => (
            <Accordion
            key={category.title}
            sx={{
                mb: 1,
                // 1px border with same radius around all sides
                borderRadius: '8px',
                border: '1px solid #C8D7E1',
                boxShadow: 'none',
                overflow: 'hidden', // ensures corners remain rounded
                '&:before': {
                display: 'none', // remove default MUI line
                },
            }}
            >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                backgroundColor: '#FFFFFF',
                // Remove any extra styling so corners remain consistent
                padding: '0 16px',
                '& .MuiAccordionSummary-content': {
                    margin: '12px 0',
                },
                }}
            >
                <Box display="flex" alignItems="center">
                {/* Color indicator on the left */}
                <Box
                    sx={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: category.color,
                    mr: 1.5,
                    }}
                />
                {/* Title + (count) */}
                <Typography
                    variant="subtitle1"
                    sx={{ fontFamily: 'Inter', fontWeight: 600, color: '#33475B' }}
                >
                    {category.title} ({category.items.length})
                </Typography>
                </Box>
            </AccordionSummary>

            <AccordionDetails sx={{ backgroundColor: '#FFFFFF' }}>
                {category.title === 'Required Topics Missing'
                ? category.items.map((topic, idx) => (
                    <Typography
                        key={idx}
                        variant="body2"
                        sx={{ fontFamily: 'Inter', color: '#33475B', mb: 1 }}
                    >
                        - {topic}
                    </Typography>
                    ))
                : category.items.map((item, idx) => (
                    <Typography
                        key={idx}
                        variant="body2"
                        sx={{ fontFamily: 'Inter', color: '#33475B', mb: 1 }}
                    >
                        {item}
                    </Typography>
                    ))}
            </AccordionDetails>
            </Accordion>
        ))}
        </Box>
    </Box>
  );
};

export default AuditReport;
