import React from "react";

// component imports
import Dashboard from "../views/dashboard";
import OrgPage from "../views/org";
import UserPage from "../views/user";
import ImportSupporters from "../views/supporters/import";
import Supporters from "../views/supporters";
import NotFoundPage from "../views/NotFoundPage";
import Layout from "../pages/layout";
import LoginPage from "../views/auth/login";
import withAuth from "../hoc/withAuth";
import ViewSupporters from "../views/supporters/view_supporters";
import EmailSettings from "../views/org_email_settings/EmailSettings";
import ContentAudit from "../views/content_audit/ContentAudit";
import EmailOverview from "../views/email_overview/EmailOverview";
import EffortOverview from "../views/effort_overview/EffortOverview";
import { useAuth } from "../contexts/AuthContext";

interface Props {
  isLoggedIn: boolean;
  selectedOrg: any;
  setSelectedOrg: React.Dispatch<React.SetStateAction<any>>;
  anchorEl: HTMLElement | null;
  avatarEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setAvatarEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  handleClose: () => void;
  handleOrgSelect: (org: any) => void;
}

const RoutesTree: React.FC<Props> = ({
    isLoggedIn,
    selectedOrg,
    setSelectedOrg,
    anchorEl,
    avatarEl,
    setAnchorEl,
    setAvatarEl,
    handleClose,
    handleOrgSelect
  }) => {
    // Auth-wrapped components
    const ProtectedDashboard = withAuth(Dashboard);
    const ProtectedOrgPage = withAuth(OrgPage);
    const ProtectedUserPage = withAuth(UserPage);
    const ProtectedImportSupporters = withAuth(ImportSupporters);
    const ProtectedSupporters = withAuth(Supporters);
    const ProtectedViewSupporters = withAuth(ViewSupporters);
    const ProtectedEmailSettings = withAuth(EmailSettings);
    const ProtectedContentAudit = withAuth(ContentAudit);
    const ProtectedEmailOverview = withAuth(EmailOverview);
    const ProtectedEffortOverview = withAuth(EffortOverview);
    const { activePage, setActivePage } = useAuth();
  
    const renderPage = () => {
      switch (activePage) {
        case "dashboard":
          return (
            <Layout {...{selectedOrg, setSelectedOrg, anchorEl, avatarEl, setAnchorEl, setAvatarEl, handleClose, handleOrgSelect}}>
              <ProtectedDashboard />
            </Layout>
          );
        case "supporters":
          return (
            <Layout {...{selectedOrg, setSelectedOrg, anchorEl, avatarEl, setAnchorEl, setAvatarEl, handleClose, handleOrgSelect}}>
              <ProtectedSupporters />
            </Layout>
          );
        case "import-supporters":
          return (
            <Layout {...{selectedOrg, setSelectedOrg, anchorEl, avatarEl, setAnchorEl, setAvatarEl, handleClose, handleOrgSelect}}>
              <ProtectedImportSupporters />
            </Layout>
          );
        case "users":
          return (
            <Layout {...{selectedOrg, setSelectedOrg, anchorEl, avatarEl, setAnchorEl, setAvatarEl, handleClose, handleOrgSelect}}>
              <ProtectedUserPage />
            </Layout>
          );
        case "organization":
          return (
            <Layout {...{selectedOrg, setSelectedOrg, anchorEl, avatarEl, setAnchorEl, setAvatarEl, handleClose, handleOrgSelect}}>
              <ProtectedOrgPage selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} />
            </Layout>
          );
        case "view-supporters":
          return (
            <Layout {...{selectedOrg, setSelectedOrg, anchorEl, avatarEl, setAnchorEl, setAvatarEl, handleClose, handleOrgSelect}}>
              <ProtectedViewSupporters />
            </Layout>
          );
        case "org-email-settings":
          return (
            <Layout {...{selectedOrg, setSelectedOrg, anchorEl, avatarEl, setAnchorEl, setAvatarEl, handleClose, handleOrgSelect}}>
              <ProtectedEmailSettings />
            </Layout>
          );
        case "content-audit":
          return (
            <Layout {...{selectedOrg, setSelectedOrg, anchorEl, avatarEl, setAnchorEl, setAvatarEl, handleClose, handleOrgSelect}}>
              <ProtectedContentAudit />
            </Layout>
          );
        case "email-overview":
          return (
            <Layout {...{selectedOrg, setSelectedOrg, anchorEl, avatarEl, setAnchorEl, setAvatarEl, handleClose, handleOrgSelect}}>
              <ProtectedEmailOverview />
            </Layout>
          );
        case "effort-overview":
          return (
            <Layout {...{selectedOrg, setSelectedOrg, anchorEl, avatarEl, setAnchorEl, setAvatarEl, handleClose, handleOrgSelect}}>
              <ProtectedEffortOverview />
            </Layout>
          );
        case "login":
          return <LoginPage />;
        default:
          return <NotFoundPage />;
      }
    };
  
    return <div>{!isLoggedIn ? <LoginPage /> : renderPage()}</div>;
  };
  
  export default RoutesTree;  