import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Button,
  TextField
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

interface PauseEmailDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (date: Dayjs) => void;
}

const PauseEmailDialog: React.FC<PauseEmailDialogProps> = ({ open, onClose, onConfirm }) => {
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs());

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box sx={{ backgroundColor: '#DEF2FF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px' }}>
        <Typography variant="h5" sx={{ fontFamily: 'Inter', fontWeight: 'bold', marginX: 2 }}>Pause Emails</Typography>
        <IconButton onClick={onClose}><Close /></IconButton>
      </Box>
      <DialogContent sx={{ padding: '24px', textAlign: 'left' }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          All emails* in your System will be paused from now until the date you set to resume sending.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          *If you set exceptions for Thank You and Birthday type emails, they will not be affected.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Select date to resume email sending:
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* <DatePicker
            label=""
            value={selectedDate}
            onChange={setSelectedDate}
            renderInput={(params) => <TextField {...params} />}
          /> */}
        </LocalizationProvider>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', mt: 2, mb: 1 }}>
        <Button onClick={() => onClose()} color="primary">Cancel</Button>
        <Button onClick={() => selectedDate && onConfirm(selectedDate)} sx={{
          borderRadius: '5px',
          backgroundColor: '#C8D7E1',
          "&:hover": { backgroundColor: '#A9C0CD' },
          "&:active": { backgroundColor: '#A9C0CD' },
          color: '#000',
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: '700',
          textTransform: 'none',
          padding: '5px 10px',
          alignSelf: 'center', // Align the button to the center of the container
        }}>
          Pause Emails
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PauseEmailDialog;
