import { apiClient } from './apiClient';
import { User } from '../types/auth';

// Get all users
export const getUsers = async (): Promise<User[] | null> => {
  try {
    const response = await apiClient(`/api/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Ensure Authorization if needed
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    });

    if (response.ok) {
      const data: User[] = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      console.error('Failed to fetch users:', errorData);
      return null;
    }
  } catch (error) {
    console.error('Error fetching users:', error);
    return null;
  }
};

// Get user by ID
export const getUser = async (userId: number): Promise<User | null> => {
  try {
    const response = await apiClient(`/api/users/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Ensure Authorization if needed
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    });

    if (response.ok) {
      const data: User = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      console.error(`Failed to fetch user with ID ${userId}:`, errorData);
      return null;
    }
  } catch (error) {
    console.error('Error fetching user by ID:', error);
    return null;
  }
};

// update User org_id
export const updateUserOrgId = async (user: User): Promise<User | null> => {
  try {
    // change user object keys to snake_case
    const formattedUser = {
      id: user.id,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      phone_number: user.phoneNumber,
      image: user.image,
      platform_role: user.platformRole,
      organization_role: user.organizationRole,
      org_id: user.org_id,
    };

    const response = await apiClient(`/api/users/${formattedUser.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
      body: JSON.stringify(formattedUser),
    });

    if (response.ok) {
      const data: User = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      console.error(`Failed to update user org_id for user ${formattedUser.id}:`, errorData);
      return null;
    }
  } catch (error) {
    console.error('Error updating user org_id:', error);
    return null;
  }
};

