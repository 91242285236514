import React, {useState, useEffect} from 'react';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, Box, Button, Checkbox, FormControlLabel, Grid, TablePagination } from '@mui/material';
import { useImportCSVContext } from '../contexts/ImportCSVContext.js';
import { exportToCsv } from '../utilities/exportToCSV'; // Import the utility function

const tableBoxStyle = {
  borderRadius: '5px',
  border: '2px solid #C8D7E1',
  background: '#FFF',
  overflow: 'hidden', // This should ensure the borderRadius is respected
};

const tableStyle = {
  minWidth: 650,
  '& thead th': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    borderBottom: '2px solid #C8D7E1',
    padding: '8px 12px', // Adjusted padding to reduce height
    whiteSpace: 'nowrap',
  },
  '& tbody tr': {
    height: '48px', // Set a fixed height for rows
  },
  '& tbody tr:nth-of-type(odd)': {
    backgroundColor: '#FFF',
  },
  '& tbody tr:nth-of-type(even)': {
    backgroundColor: '#ECF4F9',
  },
  '& tbody td': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '1rem',
    padding: '8px 12px', // Adjusted padding to reduce height
    borderBottom: '0px',
    overflow: 'hidden', // Ensure that content does not cause rows to expand
    textOverflow: 'ellipsis', // Add ellipsis for overflowing text
    whiteSpace: 'nowrap', // Prevent text wrapping
  }
};

const tableContainerStyle = {
  overflowX: 'auto' // Enable horizontal scrolling
};

const buttonStyle = {
  borderRadius: '5px',
  border: '2px solid #C8D7E1',
  background: '#ECF4F9',
  "&:hover": { backgroundColor: "#C8D7E1" },
  "&:active": { backgroundColor: "#C8D7E1" },
  color: '#33475B',
  fontFamily: 'Inter',
  fontWeight: 'bold',
  fontSize: '1rem',
  margin: '10px 0', // Adds margin at the top for spacing
  '& .MuiTouchRipple-root': {
    color: 'transparent' // Get rid of that ripple effect from MUI
  }
};

const buttonStyleBottom = {
  borderRadius: '5px',
  border: '2px solid #C8D7E1',
  background: '#C8D7E1',
  "&:hover": { backgroundColor: "#A9C0CD" },
  "&:active": { backgroundColor: "#A9C0CD" },
  color: '#33475B',
  fontFamily: 'Inter',
  fontWeight: 'bold',
  fontSize: '1rem',
  width: 'fit-content', // Fit to content width
  alignSelf: 'center', // Center align within its container
  padding: '5px 20px', // Padding for the button
  '& .MuiTouchRipple-root': {
    color: 'transparent' // Get rid of that ripple effect from MUI
  }
};

const checkboxStyle = {
  color: '#7C90A3', // Color when unchecked
  border: '2px #33475B',
  '&.Mui-checked': {
    color: '#7C90A3', // Color when checked
  },
  '&:hover': {
    bgcolor: 'transparent'
  },
  
  '& .MuiTouchRipple-root': {
    color: 'transparent' // Get rid of that ripple effect from MUI
  }
};

const TableComponent = ({ data, hasImportColumn, hasPagination, checked, handleCheck, category }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const { fetchNextPageForCategory } = useImportCSVContext();

  useEffect(() => {
    if (hasPagination && page === Math.floor(data.length / rowsPerPage) - 1) {
      if (category !== 'Suspected_Duplicates' && category !== 'Suspected_Existing') {
        fetchNextPageForCategory(category);
      }
    }
  }, [page, data.length, rowsPerPage, hasPagination, category, fetchNextPageForCategory]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Ensures only one checkbox can be selected at a time
  const handleCheckSingle = (id) => {
    handleCheck({ [id]: true });
  };

  const displayedData = hasPagination ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data;
  const tableHeaders = data[0] ? Object.keys(data[0]).filter(key => key !== 'id' && key !== 'created_at' && key !== 'created_by' && key !== 'task_id') : [];

  return (
    <Box>
      <Box sx={tableBoxStyle}>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={tableStyle}>
            <TableHead>
              <TableRow>
                {hasImportColumn && <TableCell>Import</TableCell>}
                {tableHeaders.map(header => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedData.map((item, index) => (
                <TableRow key={item.id}>
                  {hasImportColumn && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={!!checked[item.id]}
                        onChange={() => handleCheckSingle(item.id)}
                        sx={{
                          color: '#7C90A3',
                          '&.Mui-checked': {
                            color: '#7C90A3',
                          },
                          '&:hover': {
                            bgcolor: 'transparent'
                          },
                          '& .MuiTouchRipple-root': {
                            color: 'transparent'
                          },
                          transform: 'scale(1.35)',
                        }}
                      />
                    </TableCell>
                  )}
                  {tableHeaders.map(header => (
                    <TableCell key={header}>{item[header]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
      {hasPagination && (
        <Box>
          <TablePagination
            rowsPerPageOptions={[6, 12, 24]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ fontFamily: 'Inter' }}
          />
        </Box>
      )}
    </Box>
  );
};

export default TableComponent;



export const IncompleteRecordsComponent = ({ onConfirm }) => {
  const { incompleteRecords } = useImportCSVContext();
  const handleExport = () => {
    exportToCsv(incompleteRecords, 'incomplete_records.csv');
  };
  
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={buttonStyle} onClick={handleExport}>Export All</Button>
      </Box>
      <TableComponent data={incompleteRecords} hasPagination={true} category='Incomplete_Record'/>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button sx={buttonStyleBottom} onClick={onConfirm}>Confirm</Button>
      </Box>
    </Box>
  );
};

export const DuplicateRecordsComponent = ({ onConfirm }) => {
  const [selection, setSelection] = useState(0);  // 0: none, 1: skip import, 2: import selected
  const [currentConflictIndex, setCurrentConflictIndex] = useState(0); // Track the current conflict
  const [checked, setChecked] = useState({}); // Track which records are checked
  const [showError, setShowError] = useState(false); // Track whether to show the error
  const [showFinalTable, setShowFinalTable] = useState(false); // Track whether to show the final table
  const [finalRecords, setFinalRecords] = useState([]); // Records to show in the final table
  const [isFinalRenderTriggered, setIsFinalRenderTriggered] = useState(false); // Track whether final rendering should be triggered
  const [isSkipAll, setIsSkipAll] = useState(false); // Track if "Skip all imports" is active

  const { 
    suspectedDuplicateRecords, 
    skipSuspectedDuplicateIDs, 
    setSkipSuspectedDuplicateIDs, 
    suspectedDuplicateSkipAll, 
    setSuspectedDuplicateSkipAll, 
    counts,
    setCounts,
    filterRecordsBySkippedIDs,
    setSuspectedDuplicatesResolved
  } = useImportCSVContext();

  const handleExport = () => {
    const flattenedRecords = suspectedDuplicateRecords.flat(); // Flattening the array
    exportToCsv(flattenedRecords, 'suspected_duplicates.csv');
  };

  const handleCheckboxChange = (newSelection) => {
    setSelection(selection === newSelection ? 0 : newSelection);
    setShowError(false); // Hide error when a selection is made
  };

  const handleSkipAllChange = (event) => {
    setSuspectedDuplicateSkipAll(event.target.checked);
    setIsSkipAll(event.target.checked); // Update the state to reflect that Skip All is selected
    setShowError(false); // Hide error when Skip All is selected
  };

  const handleCheck = (newCheckedState) => {
    setChecked(newCheckedState); // Set the state to ensure only one checkbox is selected
  };

  const handleNextConflict = () => {
    const currentGroup = suspectedDuplicateRecords[currentConflictIndex] || [];

    if (selection === 0 && !isSkipAll) {
      setShowError(true);
      return;
    }

    if (isSkipAll) {
      // Skip all imports: Add any IDs not already in skipSuspectedDuplicateIDs
      const allIDsToSkip = suspectedDuplicateRecords
        .flat()
        .map((record) => record.id)
        .filter((id) => !skipSuspectedDuplicateIDs.includes(id));
      setSkipSuspectedDuplicateIDs((prev) => [...prev, ...allIDsToSkip]);
    } else if (selection === 1) {
      // Skip import for these records: Add all records in the current group to the skip list
      const toSkipIDs = currentGroup.map((record) => record.id);
      setSkipSuspectedDuplicateIDs((prev) => [...prev, ...toSkipIDs]);
    } else if (selection === 2) {
      // Import selected records: Add unchecked records to the skip list
      const toSkipIDs = currentGroup
        .filter((record) => !checked[record.id])
        .map((record) => record.id);
      setSkipSuspectedDuplicateIDs((prev) => [...prev, ...toSkipIDs]);
    }

    // Reset checked state for the next conflict
    setChecked({});

    if (!isSkipAll) {
      setCounts(prevCounts => ({
        ...prevCounts,
        ['Suspected Duplicate Records']: suspectedDuplicateRecords.length - currentConflictIndex - 1,
      }));
    }

    if (currentConflictIndex >= suspectedDuplicateRecords.length - 1 || isSkipAll) {
      // Trigger final render in useEffect
      setIsFinalRenderTriggered(true);
    } else {
      // Move to the next conflict
      setCurrentConflictIndex(currentConflictIndex + 1);
    }
  };

  // Trigger final rendering when skip IDs are fully updated
  useEffect(() => {
    if (isFinalRenderTriggered) {
      const filteredRecords = isSkipAll 
        ? suspectedDuplicateRecords.flat() // Show all records if "Skip all imports" was selected
        : filterRecordsBySkippedIDs(suspectedDuplicateRecords.flat());
        
      setFinalRecords(filteredRecords);
      setShowFinalTable(true);
      setIsFinalRenderTriggered(false); // Reset the trigger
      setCounts(prevCounts => ({
        ...prevCounts,
        ['Suspected Duplicate Records']: 0,
      }));
      setSuspectedDuplicatesResolved(true);
      console.log('skipSuspectedDuplicateIDs', skipSuspectedDuplicateIDs);
    }
  }, [skipSuspectedDuplicateIDs, isFinalRenderTriggered, suspectedDuplicateRecords, isSkipAll]);

  const handleRedoSelections = () => {
    // Reset all necessary states to start over
    setCurrentConflictIndex(0);
    setSkipSuspectedDuplicateIDs([]);
    setSuspectedDuplicateSkipAll(false);
    setShowFinalTable(false);
    setSelection(0);
    setChecked({});
    setShowError(false);
    setIsSkipAll(false);
    setCounts(prevCounts => ({
      ...prevCounts,
      ['Suspected Duplicate Records']: suspectedDuplicateRecords.length,
    }));
    setSuspectedDuplicatesResolved(false);
  };

  // Get the current group of records to display
  const currentGroup = suspectedDuplicateRecords[currentConflictIndex] || [];

  if (showFinalTable) {
    // Render the final table with pagination and without the import column
    return (
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Inter', mt: 2, mb: 2 }}>
          <strong>{isSkipAll ? "All Records Skipped:" : "Records to Import"}</strong>
        </Typography>
        <TableComponent 
          data={finalRecords} 
          hasImportColumn={false} 
          hasPagination={true} 
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button 
            sx={buttonStyleBottom} 
            onClick={handleRedoSelections}
          >
            Redo Selections
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Inter' }}>
          <strong>{currentConflictIndex + 1} of {suspectedDuplicateRecords.length} Conflicts:</strong>&nbsp;
        </Typography>
        <Button sx={buttonStyle} onClick={handleExport}>Export All</Button>
      </Box>
      <TableComponent 
        data={currentGroup} 
        hasImportColumn={true} 
        hasPagination={false} 
        checked={checked}           // Pass checked state to TableComponent
        handleCheck={handleCheck}  // Pass handleCheck function to TableComponent
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap', gap: 2 }}>
        <Typography variant="body1">
          Please choose how to resolve this conflict:
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={selection === 1} onChange={() => handleCheckboxChange(1)} sx={checkboxStyle} color="primary" />}
          label="Skip import for these records"
        />
        <FormControlLabel
          control={<Checkbox checked={selection === 2} onChange={() => handleCheckboxChange(2)} sx={checkboxStyle} color="primary" />}
          label="Import selected record"
        />
      </Box>
      <Box sx={{ display: 'flex', mt: 3, alignItems: 'center', justifyContent: 'space-between' }}>
        <FormControlLabel
          control={<Checkbox checked={suspectedDuplicateSkipAll} onChange={handleSkipAllChange} sx={checkboxStyle} color="primary" />}
          label={
            currentConflictIndex > 0
              ? `Override and skip import for all ${suspectedDuplicateRecords.length} conflicts`
              : `Skip import for all ${suspectedDuplicateRecords.length} conflicts`
          }
          sx={{ flex: 1 }} // Allows this element to take minimum required space
        />
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          <Button 
            sx={buttonStyleBottom} 
            onClick={handleNextConflict}
          >
            {isSkipAll ? "Skip all imports" : currentConflictIndex >= suspectedDuplicateRecords.length - 1 ? "Confirm" : "Next Conflict"}
          </Button>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {showError && (
            <Typography variant="body2" color="error" sx={{ fontFamily: 'Inter' }}>
              Please make a selection before proceeding.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};



export const SuspectedExistingRecordsComponent = ({ onConfirm }) => {
  const [selection, setSelection] = useState(0);  // 0: none, 1: import from list, 2: keep from dashboard
  const [currentConflictIndex, setCurrentConflictIndex] = useState(0); // Track the current conflict
  const [showError, setShowError] = useState(false); // Track whether to show the error
  const [isDoForAll, setIsDoForAll] = useState(false); // Track if "Do this for all" is active
  const [showFinalTable, setShowFinalTable] = useState(false); // Track whether to show the final table
  const [finalRecords, setFinalRecords] = useState([]); // Records to show in the final table
  const [isFinalRenderTriggered, setIsFinalRenderTriggered] = useState(false); // Track whether final rendering should be triggered

  const { 
    suspectedExistingRecords, 
    suspectedExistingDoForAll, 
    setSuspectedExistingDoForAll, 
    counts, 
    setCounts,
    skipSuspectedExistingIDs, 
    setSkipSuspectedExistingIDs,
    filterRecordsBySkippedIDs,
    setSuspectedExistingResolved
  } = useImportCSVContext();

  const handleExport = () => {
    const firstEntries = suspectedExistingRecords.map(group => group[0]); // Select the first entry
    exportToCsv(firstEntries, 'suspected_existing.csv');
  };

  const handleCheckboxChange = (newSelection) => {
    setSelection(selection === newSelection ? 0 : newSelection);
    setShowError(false); // Hide error when a selection is made
  };

  const handleDoForAllChange = (event) => {
    if (selection !== 0) { // Only allow if a primary option is selected
      setSuspectedExistingDoForAll(event.target.checked);
      setIsDoForAll(event.target.checked); // Update the state to reflect that Do For All is selected
      setShowError(false); // Hide error when Do For All is selected
    }
  };

  const handleNextConflict = () => {
    const currentGroup = suspectedExistingRecords[currentConflictIndex] || [];

    if (selection === 0 && !isDoForAll) {
      setShowError(true);
      return;
    }

    if (isDoForAll) {
      if (selection === 1) {
        // "Update all records" behavior
        setSkipSuspectedExistingIDs([]); // Clear skip IDs since all will be imported
        setIsFinalRenderTriggered(true); // Trigger final rendering to show all records to import
      } else if (selection === 2) {
        // "Keep all Dashboard Records" behavior
        const allRecordIDs = suspectedExistingRecords.map(record => record[0].id); // Collect all tempSupporter IDs
        setSkipSuspectedExistingIDs(allRecordIDs); // Add all tempSupporter IDs to skip list
        setIsFinalRenderTriggered(true); // Trigger final rendering to show all records skipped
      }
    } else {
      if (selection === 2) {
        // "Keep Record from Dashboard" behavior: Add the tempSupporter ID to the skip list
        setSkipSuspectedExistingIDs(prev => [...prev, currentGroup[0].id]);
      }

      // Move to the next conflict or trigger final rendering
      if (currentConflictIndex >= suspectedExistingRecords.length - 1) {
        setIsFinalRenderTriggered(true);
      } else {
        setCurrentConflictIndex(currentConflictIndex + 1);
      }

      setCounts(prevCounts => ({
        ...prevCounts,
        ['Suspected Existing Records']: suspectedExistingRecords.length - currentConflictIndex - 1,
      }));

    }

    
  };

  // Trigger final rendering when skip IDs are fully updated
  useEffect(() => {
    if (isFinalRenderTriggered) {
      // Adjust to show all records with correct title based on user choice
      const filteredRecords = (isDoForAll && selection === 2) || skipSuspectedExistingIDs.length === suspectedExistingRecords.length 
        ? suspectedExistingRecords.map(group => group[0]) // Show all tempSupporters if "Keep all Dashboard Records" was selected or all were skipped
        : filterRecordsBySkippedIDs(suspectedExistingRecords.map(group => group[0])); // Filter tempSupporters based on skips

      setFinalRecords(filteredRecords);
      setShowFinalTable(true);
      setIsFinalRenderTriggered(false); // Reset the trigger
      setCounts(prevCounts => ({
        ...prevCounts,
        ['Suspected Existing Records']: 0,
      }));
      setSuspectedExistingResolved(true);
      console.log('skipSuspectedExistingIDs', skipSuspectedExistingIDs);

    }
  }, [skipSuspectedExistingIDs, isFinalRenderTriggered, suspectedExistingRecords, isDoForAll, selection]);

  const handleRedoSelections = () => {
    // Reset all necessary states to start over
    setCurrentConflictIndex(0);
    setSkipSuspectedExistingIDs([]);
    setSuspectedExistingDoForAll(false);
    setShowFinalTable(false);
    setSelection(0);
    setShowError(false);
    setIsDoForAll(false);
    setCounts(prevCounts => ({
      ...prevCounts,
      ['Suspected Existing Records']: suspectedExistingRecords.length,
    }));
    setSuspectedExistingResolved(false);
  };

  // Get the current record to display
  const currentGroup = suspectedExistingRecords[currentConflictIndex] || [];

  if (showFinalTable) {
    // Render the final table with pagination and without the import column
    return (
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Inter', mt: 2, mb: 2 }}>
          <strong>{isDoForAll && selection === 2 || skipSuspectedExistingIDs.length === suspectedExistingRecords.length ? "All Records Skipped:" : "Records to Import"}</strong>
        </Typography>
        <TableComponent 
          data={finalRecords} 
          hasImportColumn={false} 
          hasPagination={true} 
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button 
            sx={buttonStyleBottom} 
            onClick={handleRedoSelections}
          >
            Redo Selections
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Inter' }}>
          <strong>{currentConflictIndex + 1} of {suspectedExistingRecords.length} Conflicts:</strong>&nbsp;
        </Typography>
        <Button sx={buttonStyle} onClick={handleExport}>Export All</Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ fontFamily: 'Inter', fontWeight: 'bold', mb: 0.5 }}>CSV File:</Typography>
          {currentGroup[0] && (
            <TableComponent data={[currentGroup[0]]} title="Table 1" />
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ fontFamily: 'Inter', fontWeight: 'bold', mb: 0.5 }}>Dashboard:</Typography>
          {currentGroup[1] && (
            <TableComponent data={[currentGroup[1]]} title="Table 2" />
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap', mt: 2, gap: 2 }}>
        <Typography variant="body1">
          Please choose how to resolve this conflict:
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={selection === 1} onChange={() => handleCheckboxChange(1)} sx={checkboxStyle} color="primary" />}
          label="Import Record from List and Update Dashboard"
        />
        <FormControlLabel
          control={<Checkbox checked={selection === 2} onChange={() => handleCheckboxChange(2)} sx={checkboxStyle} color="primary" />}
          label="Keep Record from Dashboard"
        />
      </Box>
      <Box sx={{ display: 'flex', mt: 3, alignItems: 'center', justifyContent: 'space-between' }}>
        <FormControlLabel
          control={
            <Checkbox 
              checked={suspectedExistingDoForAll} 
              onChange={handleDoForAllChange} 
              sx={checkboxStyle} 
              color="primary" 
              disabled={selection === 0} // Disable until a primary option is selected
            />
          }
          label={`Do this for all ${counts['Suspected Existing Records']} conflicts`}
          sx={{ flex: 1 }} // Allows this element to take minimum required space
        />
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          <Button 
            sx={buttonStyleBottom} 
            onClick={handleNextConflict}
          >
            {isDoForAll 
              ? selection === 1 ? "Update all records" : "Keep all Dashboard Records"
              : currentConflictIndex >= suspectedExistingRecords.length - 1 ? "Confirm" : "Next Conflict"}
          </Button>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {showError && (
            <Typography variant="body2" color="error" sx={{ fontFamily: 'Inter' }}>
              Please make a selection before proceeding.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const NewUpdateRecordsComponent = ({ onConfirm }) => {
  const [selection, setSelection] = useState(0);  // 0: none, 1: import from list, 2: keep from dashboard
  const [currentConflictIndex, setCurrentConflictIndex] = useState(0); // Track the current conflict
  const [showError, setShowError] = useState(false); // Track whether to show the error
  const [isDoForAll, setIsDoForAll] = useState(false); // Track if "Do this for all" is active
  const [showFinalTable, setShowFinalTable] = useState(false); // Track whether to show the final table
  const [finalRecords, setFinalRecords] = useState([]); // Records to show in the final table
  const [isFinalRenderTriggered, setIsFinalRenderTriggered] = useState(false); // Track whether final rendering should be triggered

  const { 
    newUpdateRecords, 
    newUpdateDoForAll, 
    setNewUpdateDoForAll, 
    counts, 
    setCounts,
    skipNewUpdateIDs, 
    setSkipNewUpdateIDs,
    filterRecordsBySkippedIDs,
    setNewUpdateResolved
  } = useImportCSVContext();

  const handleExport = () => {
    const firstEntries = newUpdateRecords.map(group => group[0]); // Select the first entry
    exportToCsv(firstEntries, 'new_update.csv');
  };

  const handleCheckboxChange = (newSelection) => {
    setSelection(selection === newSelection ? 0 : newSelection);
    setShowError(false); // Hide error when a selection is made
  };

  const handleDoForAllChange = (event) => {
    if (selection !== 0) { // Only allow if a primary option is selected
      setNewUpdateDoForAll(event.target.checked);
      setIsDoForAll(event.target.checked); // Update the state to reflect that Do For All is selected
      setShowError(false); // Hide error when Do For All is selected
    }
  };

  const handleNextConflict = () => {
    const currentGroup = newUpdateRecords[currentConflictIndex] || [];

    if (selection === 0 && !isDoForAll) {
      setShowError(true);
      return;
    }

    if (isDoForAll) {
      if (selection === 1) {
        // "Update all records" behavior
        setSkipNewUpdateIDs([]); // Clear skip IDs since all will be imported
        setIsFinalRenderTriggered(true); // Trigger final rendering to show all records to import
      } else if (selection === 2) {
        // "Keep all Dashboard Records" behavior
        const allRecordIDs = newUpdateRecords.map(record => record[0].id); // Collect all tempSupporter IDs
        setSkipNewUpdateIDs(allRecordIDs); // Add all tempSupporter IDs to skip list
        setIsFinalRenderTriggered(true); // Trigger final rendering to show all records skipped
      }
    } else {
      if (selection === 2) {
        // "Keep Record from Dashboard" behavior: Add the tempSupporter ID to the skip list
        setSkipNewUpdateIDs(prev => [...prev, currentGroup[0].id]);
      }

      // Move to the next conflict or trigger final rendering
      if (currentConflictIndex >= newUpdateRecords.length - 1) {
        setIsFinalRenderTriggered(true);
      } else {
        setCurrentConflictIndex(currentConflictIndex + 1);
      }

      setCounts(prevCounts => ({
        ...prevCounts,
        ['New Update']: newUpdateRecords.length - currentConflictIndex - 1,
      }));

    }

    
  };

  // Trigger final rendering when skip IDs are fully updated
  useEffect(() => {
    if (isFinalRenderTriggered) {
      // Adjust to show all records with correct title based on user choice
      const filteredRecords = (isDoForAll && selection === 2) || skipNewUpdateIDs.length === newUpdateRecords.length 
        ? newUpdateRecords.map(group => group[0]) // Show all tempSupporters if "Keep all Dashboard Records" was selected or all were skipped
        : filterRecordsBySkippedIDs(newUpdateRecords.map(group => group[0])); // Filter tempSupporters based on skips

      setFinalRecords(filteredRecords);
      setShowFinalTable(true);
      setIsFinalRenderTriggered(false); // Reset the trigger
      setCounts(prevCounts => ({
        ...prevCounts,
        ['New Update']: 0,
      }));
      setNewUpdateResolved(true);
      console.log('skipNewUpdateIDs', skipNewUpdateIDs);

    }
  }, [skipNewUpdateIDs, isFinalRenderTriggered, newUpdateRecords, isDoForAll, selection]);

  const handleRedoSelections = () => {
    // Reset all necessary states to start over
    setCurrentConflictIndex(0);
    setSkipNewUpdateIDs([]);
    setNewUpdateDoForAll(false);
    setShowFinalTable(false);
    setSelection(0);
    setShowError(false);
    setIsDoForAll(false);
    setCounts(prevCounts => ({
      ...prevCounts,
      ['New Update']: newUpdateRecords.length,
    }));
    setNewUpdateResolved(false);
  };

  // Get the current record to display
  const currentGroup = newUpdateRecords[currentConflictIndex] || [];

  if (showFinalTable) {
    // Render the final table with pagination and without the import column
    return (
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Inter', mt: 2, mb: 2 }}>
          <strong>{isDoForAll && selection === 2 || skipNewUpdateIDs.length === newUpdateRecords.length ? "All Records Skipped:" : "Records to Import"}</strong>
        </Typography>
        <TableComponent 
          data={finalRecords} 
          hasImportColumn={false} 
          hasPagination={true} 
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button 
            sx={buttonStyleBottom} 
            onClick={handleRedoSelections}
          >
            Redo Selections
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Inter' }}>
          <strong>{currentConflictIndex + 1} of {newUpdateRecords.length} Conflicts:</strong>&nbsp;
        </Typography>
        <Button sx={buttonStyle} onClick={handleExport}>Export All</Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ fontFamily: 'Inter', fontWeight: 'bold', mb: 0.5 }}>CSV File:</Typography>
          {currentGroup[0] && (
            <TableComponent data={[currentGroup[0]]} title="Table 1" />
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ fontFamily: 'Inter', fontWeight: 'bold', mb: 0.5 }}>Dashboard:</Typography>
          {currentGroup[1] && (
            <TableComponent data={[currentGroup[1]]} title="Table 2" />
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap', mt: 2, gap: 2 }}>
        <Typography variant="body1">
          Please choose how to resolve this conflict:
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={selection === 1} onChange={() => handleCheckboxChange(1)} sx={checkboxStyle} color="primary" />}
          label="Import Record from List and Update Dashboard"
        />
        <FormControlLabel
          control={<Checkbox checked={selection === 2} onChange={() => handleCheckboxChange(2)} sx={checkboxStyle} color="primary" />}
          label="Keep Record from Dashboard"
        />
      </Box>
      <Box sx={{ display: 'flex', mt: 3, alignItems: 'center', justifyContent: 'space-between' }}>
        <FormControlLabel
          control={
            <Checkbox 
              checked={newUpdateDoForAll} 
              onChange={handleDoForAllChange} 
              sx={checkboxStyle} 
              color="primary" 
              disabled={selection === 0} // Disable until a primary option is selected
            />
          }
          label={`Do this for all ${counts['New Update']} conflicts`}
          sx={{ flex: 1 }} // Allows this element to take minimum required space
        />
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          <Button 
            sx={buttonStyleBottom} 
            onClick={handleNextConflict}
          >
            {isDoForAll 
              ? selection === 1 ? "Update all records" : "Keep all Dashboard Records"
              : currentConflictIndex >= newUpdateRecords.length - 1 ? "Confirm" : "Next Conflict"}
          </Button>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {showError && (
            <Typography variant="body2" color="error" sx={{ fontFamily: 'Inter' }}>
              Please make a selection before proceeding.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};






export const UnsubscribedRecordsComponent = ({ onConfirm }) => {
  const { unsubscribedRecords, filterRecordsBySkippedIDs, unsubscribedSkipAll, setUnsubscribedSkipAll, counts } = useImportCSVContext();

  const handleExport = () => {
    exportToCsv(unsubscribedRecords, 'unsubscribed_records.csv');
  };

  const handleDoForAllChange = (event) => {
    setUnsubscribedSkipAll(event.target.checked);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={buttonStyle} onClick={handleExport}>Export All</Button>
      </Box>
      <TableComponent data={filterRecordsBySkippedIDs(unsubscribedRecords)} hasPagination={true} category='Updating_Unsubscribe'/>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <FormControlLabel
            control={<Checkbox checked={unsubscribedSkipAll} onChange={handleDoForAllChange} sx={checkboxStyle} color="primary" />}
            label={`Skip import for all ${counts['Unsubscribed Records']} records`}
            sx={{ flex: 1 }} // Allows this element to take minimum required space
          />
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Button sx={buttonStyleBottom} onClick={onConfirm}>Confirm</Button>
          </Box>
          <Box sx={{ flex: 1 }} /> {/* Empty box to balance the layout */}
        </Box>
    </Box>
  );
};

export const UpdatingRecordsComponent = ({ onConfirm }) => {
  const [doForAll, setDoForAll] = useState(false);  // State for skipping all conflicts
  const { updatingRecords, updatingSkipAll, setUpdatingSkipAll, counts, filterRecordsBySkippedIDs } = useImportCSVContext();

  const handleExport = () => {
    exportToCsv(updatingRecords, 'updating_records.csv');
  };

  const handleDoForAllChange = (event) => {
    setUpdatingSkipAll(event.target.checked);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={buttonStyle} onClick={handleExport}>Export All</Button>
      </Box>
      <TableComponent data={filterRecordsBySkippedIDs(updatingRecords)} hasPagination={true} category='Needs_Update'/>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <FormControlLabel
            control={<Checkbox checked={updatingSkipAll} onChange={handleDoForAllChange} sx={checkboxStyle} color="primary" />}
            label={`Skip import for all ${counts['Updating Records']} records`}
            sx={{ flex: 1 }}
          />
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Button sx={buttonStyleBottom} onClick={onConfirm}>Confirm</Button>
          </Box>
          <Box sx={{ flex: 1 }} />
        </Box>
    </Box>
  );
};

export const ExistingRecordsComponent = ({ onConfirm }) => {
  const [doForAll, setDoForAll] = useState(false);  // State for skipping all conflicts
  const { existingRecords, existingSkipAll, setExistingSkipAll, counts, filterRecordsBySkippedIDs } = useImportCSVContext();

  const handleExport = () => {
    exportToCsv(existingRecords, 'existing_records.csv');
  };

  const handleDoForAllChange = (event) => {
    setExistingSkipAll(event.target.checked);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={buttonStyle} onClick={handleExport}>Export All</Button>
      </Box>
      <TableComponent data={filterRecordsBySkippedIDs(existingRecords)} hasPagination={true} category='Existing_Data'/>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <FormControlLabel
            control={<Checkbox checked={existingSkipAll} onChange={handleDoForAllChange} sx={checkboxStyle} color="primary" />}
            label={`Skip import for all ${counts['Existing Records']} existing records`}
            sx={{ flex: 1 }} // Allows this element to take minimum required space
          />
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Button sx={buttonStyleBottom} onClick={onConfirm}>Confirm</Button>
          </Box>
          <Box sx={{ flex: 1 }} /> {/* Empty box to balance the layout */}
        </Box>
    </Box>
  );
};

export const CleanRecordsComponent = ({ onConfirm }) => {
  const { cleanRecords, cleanSkipAll, setCleanSkipAll, counts } = useImportCSVContext();

  const handleExport = () => {
    exportToCsv(cleanRecords, 'clean_records.csv');
  };

  const handleSkipAllChange = (event) => {
    setCleanSkipAll(event.target.checked);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={buttonStyle} onClick={handleExport}>Export All</Button>
      </Box>
      <TableComponent data={cleanRecords} hasPagination={true} category='No_Conflicts'/>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <FormControlLabel
            control={<Checkbox checked={cleanSkipAll} onChange={handleSkipAllChange} sx={checkboxStyle} color="primary" />}
            label={`Skip import for all ${counts['Clean Records']} records`}
            sx={{ flex: 1 }}
          />
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Button sx={buttonStyleBottom} onClick={onConfirm}>Confirm</Button>
          </Box>
          <Box sx={{ flex: 1 }} />
        </Box>
    </Box>
  );
};
