import React, { useState } from 'react';
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Button,
  autocompleteClasses,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface SupporterVoiceFilters {
  missionAgreementOptions: string[];
  locationOptions: string[];
  optIn: string | null;
}

interface FilterData {
  category: string;
  filterType: string;
  value: any;
  description: string;
}

interface SupporterVoiceProps {
  filters?: SupporterVoiceFilters;
  setFilters?: React.Dispatch<React.SetStateAction<SupporterVoiceFilters>>;
  onFilterSelect?: (filterData: FilterData) => void;
  isAddingAdvancedFilter?: boolean;
}

const sampleMissionAgreementOptions = [
  'Strongly Agree',
  'Agree',
  'Neutral',
  'Disagree',
  'Strongly Disagree',
];

const usStateAbbreviations = [
  'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI',
  'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN',
  'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH',
  'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA',
  'WI', 'WV', 'WY',
].sort(); // Sorted alphabetically

// Styles for buttons to match FooterButtons component
const buttonStyle = {
  borderRadius: '5px',
  border: '2px solid #7C90A3',
  background: '#FFF',
  '&:hover': { backgroundColor: '#ECECEC' },
  '&:active': { backgroundColor: '#ECECEC' },
  color: '#7C90A3',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal' as const,
  fontWeight: '700',
  lineHeight: 'normal',
  textTransform: 'none' as const,
  px: 3,
  py: 1,
  minWidth: '150px',
  maxWidth: '170px'
};

// Styles for selected button
const selectedButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#C8D7E1',
  color: '#000',
  '&:hover': { backgroundColor: '#A9C0CD' },
  '&:active': { backgroundColor: '#A9C0CD' },
};

const SupporterVoice: React.FC<SupporterVoiceProps> = ({
  filters,
  setFilters,
  onFilterSelect,
  isAddingAdvancedFilter = false,
}) => {
  // Provide default filters if undefined
  const effectiveFilters = filters ?? {
    missionAgreementOptions: [],
    locationOptions: [],
    optIn: null,
  };

  // Use local state when adding an advanced filter
  const [localFilters, setLocalFilters] = useState<SupporterVoiceFilters>(
    effectiveFilters
  );

  // State to track which filter category is being changed
  const [activeFilterCategory, setActiveFilterCategory] = useState<string | null>(null);

  // Function to generate filter description
  const getFilterDescription = (): string => {
    switch (activeFilterCategory) {
      case 'Mission Agreement':
        return localFilters.missionAgreementOptions.length > 0
          ? `Mission Agreement: ${localFilters.missionAgreementOptions.join(', ')}`
          : '';
      case 'Location':
        return localFilters.locationOptions.length > 0
          ? `Location: ${localFilters.locationOptions.join(', ')}`
          : '';
      case 'Opt-in':
        return localFilters.optIn
          ? `Opt-in: ${localFilters.optIn}`
          : '';
      default:
        return '';
    }
  };

  // Handlers to update filters
  const handleMissionAgreementChange = (event: any, newValue: string[]) => {
    if (isAddingAdvancedFilter) {
      setLocalFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters, missionAgreementOptions: newValue };
        if (newValue.length > 0) {
          setActiveFilterCategory('Mission Agreement');
        } else {
          setActiveFilterCategory(null);
        }
        return updatedFilters;
      });
    } else if (setFilters) {
      setFilters((prevFilters) => ({
        ...prevFilters!,
        missionAgreementOptions: newValue,
      }));
    }
  };

  const handleLocationChange = (event: any, newValue: string[]) => {
    if (isAddingAdvancedFilter) {
      setLocalFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters, locationOptions: newValue };
        if (newValue.length > 0) {
          setActiveFilterCategory('Location');
        } else {
          setActiveFilterCategory(null);
        }
        return updatedFilters;
      });
    } else if (setFilters) {
      setFilters((prevFilters) => ({
        ...prevFilters!,
        locationOptions: newValue,
      }));
    }
  };

  const handleOptInChange = (option: string | null) => {
    if (isAddingAdvancedFilter) {
      setLocalFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters, optIn: option };
        if (option !== null) {
          setActiveFilterCategory('Opt-in');
        } else {
          setActiveFilterCategory(null);
        }
        return updatedFilters;
      });
    } else if (setFilters) {
      setFilters((prevFilters) => ({
        ...prevFilters!,
        optIn: option,
      }));
    }
  };

  // Function to handle adding the filter
  const handleAddFilter = () => {
    if (onFilterSelect && activeFilterCategory) {
      const filterData: FilterData = {
        category: 'Supporter Voice',
        filterType: activeFilterCategory,
        value: localFilters,
        description: getFilterDescription(),
      };
      onFilterSelect(filterData);
      // Reset local state
      setLocalFilters(effectiveFilters);
      setActiveFilterCategory(null);
    }
  };

  // Determine if the confirmation area should be displayed
  const showConfirmationArea =
    isAddingAdvancedFilter && activeFilterCategory && getFilterDescription();

  return (
    <Box sx={{ padding: '1em 0' }}>
      {/* Mission Agreement */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Inter',
          fontWeight: 700,
          color: '#000',
          fontSize: '18px',
          marginBottom: 1.5
        }}
      >
        Mission Agreement
      </Typography>
      <Autocomplete
        multiple
        options={sampleMissionAgreementOptions}
        value={
          isAddingAdvancedFilter
            ? localFilters.missionAgreementOptions
            : effectiveFilters.missionAgreementOptions
        }
        onChange={handleMissionAgreementChange}
        disabled={
          isAddingAdvancedFilter &&
          activeFilterCategory !== null &&
          activeFilterCategory !== 'Mission Agreement'
        }
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: '#DEF2FF',
                borderColor: '#6C8EA5',
                color: '#004F6E',
                fontFamily: 'Inter',
                fontWeight: 500,
              }}
              clickable={false}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Mission Agreement Options"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '5px',
                height: 'auto',
                fontFamily: 'Inter',
                fontWeight: 500,
                width: '100%',
                minWidth: '170px',
                maxWidth: '100%',
                paddingRight: '16px !important'
              },
            }}
          />
        )}
        sx={{
          minWidth: { xs: '170px' , sm: '315px' , md: '315px' },
          maxWidth: '100%',
          height: '100%',
          display: { xs: 'inline-block' , sm: 'inline-block' }
        }}
      />

      {/* Location */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Inter',
          fontWeight: 700,
          color: '#000',
          fontSize: '18px',
          marginTop: 3,
          marginBottom: 1.5
        }}
      >
        Location
      </Typography>
      <Autocomplete
        multiple
        options={usStateAbbreviations}
        value={
          isAddingAdvancedFilter
            ? localFilters.locationOptions
            : effectiveFilters.locationOptions
        }
        onChange={handleLocationChange}
        disabled={
          isAddingAdvancedFilter &&
          activeFilterCategory !== null &&
          activeFilterCategory !== 'Location'
        }
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: '#DEF2FF',
                borderColor: '#6C8EA5',
                color: '#004F6E',
                fontFamily: 'Inter',
                fontWeight: 500,
              }}
              clickable={false}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Location Options"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '5px',
                height: 'auto',
                fontFamily: 'Inter',
                fontWeight: 500,
                width: '100%',
                minWidth: '170px',
                maxWidth: '100%',
                paddingRight: '16px !important'
              },
            }}
          />
        )}
        sx={{
          minWidth: { xs: '170px' , sm: '315px' , md: '315px' },
          maxWidth: '100%',
          height: '100%',
          display: { xs: 'inline-block' , sm: 'inline-block' }
        }}
      />

      {/* Opt-in */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Inter',
          fontWeight: 700,
          color: '#000',
          fontSize: '18px',
          marginTop: 3,
          marginBottom: 1
        }}
      >
        Opt-in
      </Typography>
      <Box sx={{ display: 'flex', gap: '16px' }} flexDirection={{ xs: 'column' , sm: 'row', md: 'row' }}>
        {['Yes', 'No'].map((option) => {
          const isSelected = isAddingAdvancedFilter
            ? localFilters.optIn === option
            : effectiveFilters.optIn === option;
          const isDisabled =
            isAddingAdvancedFilter &&
            activeFilterCategory !== null &&
            activeFilterCategory !== 'Opt-in';
          return (
            <Button
              key={`optIn-${option}`}
              onClick={() => handleOptInChange(isSelected ? null : option)}
              sx={isSelected ? selectedButtonStyle : buttonStyle}
              disableRipple
              disabled={isDisabled}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: '170px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontWeight: '700',
                    fontSize: '16px',
                    color: isSelected ? '#000' : '#7C90A3',
                  }}
                >
                  {option}
                </Typography>
                {isSelected ? (
                  <RemoveIcon sx={{ color: '#7C90A3' }} />
                ) : (
                  <AddIcon sx={{ color: '#7C90A3' }} />
                )}
              </Box>
            </Button>
          );
        })}
      </Box>

      {/* Confirmation Area */}
      {showConfirmationArea && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            Selected Filter:
          </Typography>
          <Typography variant="body1">{getFilterDescription()}</Typography>
          <Button
            variant="contained"
            onClick={handleAddFilter}
            sx={{
              fontFamily: 'Inter',
              fontWeight: 600,
              color: '#fff',
              backgroundColor: '#004F6E',
              textTransform: 'none',
              marginTop: 2,
            }}
          >
            Add Filter
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SupporterVoice;
