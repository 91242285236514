import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Chip,
  TextField,
  Autocomplete,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface SupporterJourneyFilters {
  outreachOptions: string[];
  preQualified: string | null;
  originalIdentity: string[];
}

interface FilterData {
  category: string;
  filterType: string;
  value: any;
  description: string;
}

interface SupporterJourneyProps {
  filters?: SupporterJourneyFilters;
  setFilters?: React.Dispatch<React.SetStateAction<SupporterJourneyFilters>>;
  onFilterSelect?: (filterData: FilterData) => void;
  isAddingAdvancedFilter?: boolean;
}

const sampleOutreachOptions = ['Email', 'Phone Call', 'Text Message', 'Direct Mail'];
const sampleOriginalIdentityOptions = ['Donor', 'Volunteer', 'Member', 'Subscriber'];

// Styles for buttons to match SupporterTrends component
const buttonStyle = {
  borderRadius: '5px',
  border: '2px solid #7C90A3',
  background: '#FFF',
  '&:hover': { backgroundColor: '#ECECEC' },
  '&:active': { backgroundColor: '#ECECEC' },
  color: '#7C90A3',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal' as const,
  fontWeight: '700',
  lineHeight: 'normal',
  textTransform: 'none' as const,
  px: 3,
  py: 1,
  minWidth: '150px',
};

// Styles for selected button
const selectedButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#C8D7E1',
  color: '#000',
  '&:hover': { backgroundColor: '#A9C0CD' },
  '&:active': { backgroundColor: '#A9C0CD' },
};

// Styles for filter headers
const filterHeader = {
  color: '#000',
  fontSize: '18px',
  letterSpacing: '0px',
  lineHeight: '1.3',
  fontWeight: '700',
  fontFamily: 'Inter',
  marginBottom: '8px',
}

const SupporterJourney: React.FC<SupporterJourneyProps> = ({
  filters,
  setFilters,
  onFilterSelect,
  isAddingAdvancedFilter = false,
}) => {
  // Provide default filters if undefined
  const effectiveFilters = filters ?? {
    outreachOptions: [],
    preQualified: null,
    originalIdentity: [],
  };

  // Use local state when adding an advanced filter
  const [localFilters, setLocalFilters] = useState<SupporterJourneyFilters>(
    effectiveFilters
  );

  // State to track which filter category is being changed
  const [activeFilterCategory, setActiveFilterCategory] = useState<string | null>(null);

  // Function to generate filter description
  const getFilterDescription = (): string => {
    switch (activeFilterCategory) {
      case 'Outreach Options':
        return localFilters.outreachOptions.length > 0
          ? `Outreach Options: ${localFilters.outreachOptions.join(', ')}`
          : '';
      case 'Pre Qualified':
        return localFilters.preQualified
          ? `Pre Qualified: ${localFilters.preQualified}`
          : '';
      case 'Original Identity':
        return localFilters.originalIdentity.length > 0
          ? `Original Identity: ${localFilters.originalIdentity.join(', ')}`
          : '';
      default:
        return '';
    }
  };

  // Handlers to update filters
  const handleOutreachOptionsChange = (event: any, newValue: string[]) => {
    if (isAddingAdvancedFilter) {
      setLocalFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters, outreachOptions: newValue };
        if (newValue.length > 0) {
          setActiveFilterCategory('Outreach Options');
        } else {
          setActiveFilterCategory(null);
        }
        return updatedFilters;
      });
    } else if (setFilters) {
      setFilters((prevFilters) => ({
        ...prevFilters!,
        outreachOptions: newValue,
      }));
    }
  };

  const handlePreQualifiedChange = (option: string | null) => {
    if (isAddingAdvancedFilter) {
      setLocalFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters, preQualified: option };
        if (option !== null) {
          setActiveFilterCategory('Pre Qualified');
        } else {
          setActiveFilterCategory(null);
        }
        return updatedFilters;
      });
    } else if (setFilters) {
      setFilters((prevFilters) => ({
        ...prevFilters!,
        preQualified: option,
      }));
    }
  };

  const handleOriginalIdentityChange = (event: any, newValue: string[]) => {
    if (isAddingAdvancedFilter) {
      setLocalFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters, originalIdentity: newValue };
        if (newValue.length > 0) {
          setActiveFilterCategory('Original Identity');
        } else {
          setActiveFilterCategory(null);
        }
        return updatedFilters;
      });
    } else if (setFilters) {
      setFilters((prevFilters) => ({
        ...prevFilters!,
        originalIdentity: newValue,
      }));
    }
  };

  // Function to handle adding the filter
  const handleAddFilter = () => {
    if (onFilterSelect && activeFilterCategory) {
      const filterData: FilterData = {
        category: 'Supporter Journey',
        filterType: activeFilterCategory,
        value: localFilters,
        description: getFilterDescription(),
      };
      onFilterSelect(filterData);
      // Reset local state
      setLocalFilters(effectiveFilters);
      setActiveFilterCategory(null);
    }
  };

  // Determine if the confirmation area should be displayed
  const showConfirmationArea = isAddingAdvancedFilter && activeFilterCategory && getFilterDescription();

  return (
    <Box sx={{ padding: 1 }}>
      {/* Outreach Options */}
      <Typography
        variant="h4"
        sx={filterHeader}
      >
        Outreach Options
      </Typography>
      <Autocomplete
        multiple
        options={sampleOutreachOptions}
        value={
          isAddingAdvancedFilter ? localFilters.outreachOptions : effectiveFilters.outreachOptions
        }
        onChange={handleOutreachOptionsChange}
        disabled={
          isAddingAdvancedFilter &&
          activeFilterCategory !== null &&
          activeFilterCategory !== 'Outreach Options'
        }
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: '#DEF2FF',
                borderColor: '#6C8EA5',
                color: '#004F6E',
                fontFamily: 'Inter',
                fontWeight: 500,
              }}
              clickable={false}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Outreach Options"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '5px',
                height: 60,
                fontFamily: 'Inter',
                fontWeight: 500,
                marginBottom: '16px',
                width: '100%',
                minWidth: '170px',
                maxWidth: '100%',
                paddingRight: '16px !important'
              },
            }}
          />
        )}
        sx={{
          minWidth: { xs: '170px' , sm: '315px' , md: '315px' },
          maxWidth: '100%',
          height: '100%',
          display: { xs: 'inline-block' , sm: 'inline-block' }
        }}
      />

      {/* Pre Qualified */}
      <Typography
        sx={filterHeader}
      >
        Pre Qualified
      </Typography>
      <Box sx={{ display: 'flex', gap: '16px' , marginBottom: '16px' }} flexDirection={{ xs: 'column' , sm: 'row', md: 'row' }}>
        {['Yes', 'No'].map((option) => {
          const isSelected = isAddingAdvancedFilter
            ? localFilters.preQualified === option
            : effectiveFilters.preQualified === option;
          const isDisabled =
            isAddingAdvancedFilter &&
            activeFilterCategory !== null &&
            activeFilterCategory !== 'Pre Qualified';
          return (
            <Button
              key={`preQualified-${option}`}
              onClick={() => handlePreQualifiedChange(isSelected ? null : option)}
              sx={isSelected ? selectedButtonStyle : buttonStyle}
              disableRipple
              disabled={isDisabled}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontWeight: '700',
                    fontSize: '16px',
                    maxWidth: '170px',
                    color: isSelected ? '#000' : '#7C90A3',
                  }}
                >
                  {option}
                </Typography>
                {isSelected ? (
                  <RemoveIcon sx={{ color: '#7C90A3' }} />
                ) : (
                  <AddIcon sx={{ color: '#7C90A3' }} />
                )}
              </Box>
            </Button>
          );
        })}
      </Box>

      {/* Original Identity */}
      <Typography
        variant="h4"
        sx={filterHeader}
      >
        Original Identity
      </Typography>
      <Autocomplete
        multiple
        options={sampleOriginalIdentityOptions}
        value={
          isAddingAdvancedFilter ? localFilters.originalIdentity : effectiveFilters.originalIdentity
        }
        onChange={handleOriginalIdentityChange}
        disabled={
          isAddingAdvancedFilter &&
          activeFilterCategory !== null &&
          activeFilterCategory !== 'Original Identity'
        }
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: '#DEF2FF',
                borderColor: '#6C8EA5',
                color: '#004F6E',
                fontFamily: 'Inter',
                fontWeight: 500,
              }}
              clickable={false}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Original Identities"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '5px',
                height: 60,
                fontFamily: 'Inter',
                fontWeight: 500,
                width: '100%',
                minWidth: '170px',
                maxWidth: '100%',
                paddingRight: '16px !important'
              },
            }}
          />
        )}
        sx={{
          minWidth: { xs: '170px' , sm: '315px' , md: '315px' },
          maxWidth: '100%',
          height: '100%',
          display: { xs: 'inline-block' , sm: 'inline-block' }
        }}
      />

      {/* Confirmation Area */}
      {showConfirmationArea && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            Selected Filter:
          </Typography>
          <Typography variant="body1">{getFilterDescription()}</Typography>
          <Button
            variant="contained"
            onClick={handleAddFilter}
            sx={{
              fontFamily: 'Inter',
              fontWeight: 600,
              color: '#fff',
              backgroundColor: '#004F6E',
              textTransform: 'none',
              marginTop: 2,
            }}
          >
            Add Filter
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SupporterJourney;
