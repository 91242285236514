import React, { useEffect, useRef, useMemo } from 'react';
import { useEmailData } from './EmailContext';

// ── Helper Functions ──

// Format a Date as "YYYY-MM-DD"
function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// Parse a date string ("YYYY-MM-DD") to get short weekday name and day number.
function parseDateStr(dateStr: string) {
  const [year, month, day] = dateStr.split('-').map(Number);
  const dateObj = new Date(year, month - 1, day);
  const dayName = dateObj.toLocaleString('en-US', { weekday: 'short' }).toUpperCase();
  const dayNumber = dateObj.getDate();
  return { dayName, dayNumber };
}

// ── Email Box Components ──

function SingleEmailBox({
  dateStr,
  emailName,
  queuedCount,
  sentCount,
}: {
  dateStr: string;
  emailName: string;
  queuedCount: number;
  sentCount: number;
}) {
  const { dayName, dayNumber } = parseDateStr(dateStr);

  return (
    <div
      style={{
        height: '100px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        marginBottom: '1.5rem',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'stretch',
      }}
    >
      {/* Left: Date section */}
      <div
        style={{
          width: '100px',
          backgroundColor: '#f6f6f6',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ fontSize: '0.9rem', fontWeight: 500, color: '#004F6E' }}>
            {dayName}
          </div>
          <div style={{ fontSize: '1.8rem', fontWeight: 700, color: '#004F6E' }}>
            {dayNumber}
          </div>
        </div>
      </div>
      {/* Right: Email details */}
      <div
        style={{
          flex: 1,
          backgroundColor: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div style={{ flex: 1, margin: '0 1rem' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0.5rem 1.5rem',
              minHeight: '80px',
            }}
          >
            <div
              style={{
                fontFamily: 'Inter, sans-serif',
                color: '#121212',
                fontWeight: 700,
                fontSize: '1.2rem',
              }}
            >
              {emailName}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <div
                style={{
                  borderRadius: '25px',
                  border: '1px solid #878787',
                  background: 'rgba(225, 233, 238, 0.77)',
                  padding: '0.3rem 0.8rem',
                  fontFamily: 'Inter, sans-serif',
                  fontWeight: 700,
                  color: '#000',
                  fontSize: '0.9rem',
                  whiteSpace: 'nowrap',
                }}
              >
                {`${sentCount.toLocaleString()} SENT`}
              </div>
              <div
                style={{
                  borderRadius: '25px',
                  border: '1px solid rgba(4, 144, 200, 0.87)',
                  background: 'rgba(4, 144, 200, 0.15)',
                  padding: '0.3rem 0.8rem',
                  fontFamily: 'Inter, sans-serif',
                  fontWeight: 700,
                  color: '#004F6E',
                  fontSize: '0.9rem',
                  whiteSpace: 'nowrap',
                }}
              >
                {`${queuedCount.toLocaleString()} QUEUED`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MultiEmailBox({
  dateStr,
  entries,
}: {
  dateStr: string;
  entries: { email_name: string; queued_count: number; sent_count: number }[];
}) {
  const { dayName, dayNumber } = parseDateStr(dateStr);

  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: '8px',
        marginBottom: '1.5rem',
        overflow: 'hidden',
      }}
    >
      <div style={{ display: 'flex' }}>
        {/* Left: Date section */}
        <div
          style={{
            width: '100px',
            backgroundColor: '#f6f6f6',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          <div style={{ fontSize: '0.9rem', fontWeight: 500, color: '#004F6E' }}>
            {dayName}
          </div>
          <div style={{ fontSize: '1.8rem', fontWeight: 700, color: '#004F6E' }}>
            {dayNumber}
          </div>
        </div>
        {/* Right: Multiple email entries */}
        <div
          style={{
            flex: 1,
            backgroundColor: '#ffffff',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {entries.map((row, index) => (
            <div
              key={row.email_name}
              style={{
                borderTop: index > 0 ? '1px solid #ccc' : 'none',
                margin: '0 1rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0.5rem 1.5rem',
                  minHeight: '80px',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    color: '#121212',
                    fontWeight: 700,
                    fontSize: '1.2rem',
                  }}
                >
                  {row.email_name}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <div
                    style={{
                      borderRadius: '25px',
                      border: '1px solid #878787',
                      background: 'rgba(225, 233, 238, 0.77)',
                      padding: '0.3rem 0.8rem',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 700,
                      color: '#000',
                      fontSize: '0.9rem',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {`${row.sent_count.toLocaleString()} SENT`}
                  </div>
                  <div
                    style={{
                      borderRadius: '25px',
                      border: '1px solid rgba(4, 144, 200, 0.87)',
                      background: 'rgba(4, 144, 200, 0.15)',
                      padding: '0.3rem 0.8rem',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 700,
                      color: '#004F6E',
                      fontSize: '0.9rem',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {`${row.queued_count.toLocaleString()} QUEUED`}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// A sticky divider for each month with extra bottom margin.
function MonthDivider({ monthLabel }: { monthLabel: string }) {
  return (
    <div
      style={{
        position: 'sticky',
        top: 0,
        background: '#fff',
        padding: '0.5rem 1rem',
        borderBottom: '1px solid #ccc',
        marginBottom: '10px',
        zIndex: 2,
        fontFamily: 'Inter, sans-serif',
        fontWeight: 700,
        fontSize: '1.2rem',
      }}
    >
      {monthLabel}
    </div>
  );
}

// ── EmailListView Component ──

interface EmailListViewProps {
  initialDate: Date;
  scrollToDate?: string | null;
}

export default function EmailListView({ initialDate, scrollToDate }: EmailListViewProps) {
  const emailData = useEmailData();

  // Sort the unique dates by properly parsing YYYY-MM-DD in local time.
  const sortedDates = useMemo(() => {
    const uniqueDates = Array.from(new Set(emailData.map((entry) => entry.date)));
    return uniqueDates.sort((a, b) => {
      const [ay, am, ad] = a.split('-').map(Number);
      const [by, bm, bd] = b.split('-').map(Number);
      const dateA = new Date(ay, am - 1, ad).getTime();
      const dateB = new Date(by, bm - 1, bd).getTime();
      return dateA - dateB;
    });
  }, [emailData]);

  // Group the sorted dates by month using the local parse approach.
  const groups = useMemo(() => {
    const grouped: { monthKey: string; monthLabel: string; days: string[] }[] = [];
    let currentGroup: { monthKey: string; monthLabel: string; days: string[] } | null = null;

    for (const dateStr of sortedDates) {
      const [year, month] = dateStr.split('-').map(Number);
      const dateObj = new Date(year, month - 1); // We only need year & month for grouping

      const monthKey = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)
        .toString()
        .padStart(2, '0')}`;
      const monthLabel = dateObj.toLocaleString('en-US', { month: 'long', year: 'numeric' });

      if (!currentGroup || currentGroup.monthKey !== monthKey) {
        // Close out the old group if it exists.
        if (currentGroup) grouped.push(currentGroup);
        // Start a new group.
        currentGroup = { monthKey, monthLabel, days: [dateStr] };
      } else {
        currentGroup.days.push(dateStr);
      }
    }

    // Push the final group if it exists.
    if (currentGroup) grouped.push(currentGroup);

    return grouped;
  }, [sortedDates]);

  // Refs for scrolling.
  const containerRef = useRef<HTMLDivElement>(null);
  const dayRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    let targetDate: string | undefined = scrollToDate || undefined;

    if (!targetDate && sortedDates.length > 0) {
      const todayStr = formatDate(initialDate);
      // If there's a date in sortedDates >= todayStr, pick that. Otherwise pick the first date.
      targetDate = sortedDates.find((d) => d >= todayStr) || sortedDates[0];
    }

    if (targetDate && dayRefs.current[targetDate] && containerRef.current) {
      // Instead of scrollIntoView, do a manual scroll so we don't move the outer page.
      const topOffset = dayRefs.current[targetDate]!.offsetTop;
      // +40 so that the day box sits below the sticky month label with some extra space
      containerRef.current.scrollTo({
        top: topOffset - 225,
        behavior: 'smooth',
      });
    }
  }, [scrollToDate, initialDate, sortedDates]);

  return (
    <div
      ref={containerRef}
      style={{
        // Remove top padding so month divider can truly stick at the top with no gap
        padding: '0 1rem 1rem 1rem',
        height: '100%',
        overflowY: 'auto',
        // Prevent scrolling the outer page when at top/bottom:
        overscrollBehavior: 'contain',
      }}
    >
      {groups.map((group) => (
        <div key={group.monthKey}>
          <MonthDivider monthLabel={group.monthLabel} />
          {group.days.map((dateStr) => {
            const entries = emailData.filter((entry) => entry.date === dateStr);
            return (
              <div key={dateStr} ref={(el) => (dayRefs.current[dateStr] = el)}>
                {entries.length === 1 ? (
                  <SingleEmailBox
                    dateStr={dateStr}
                    emailName={entries[0].email_name}
                    queuedCount={entries[0].queued_count}
                    sentCount={entries[0].sent_count}
                  />
                ) : (
                  <MultiEmailBox
                    dateStr={dateStr}
                    entries={entries.map((x) => ({
                      email_name: x.email_name,
                      queued_count: x.queued_count,
                      sent_count: x.sent_count,
                    }))}
                  />
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}
