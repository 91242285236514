import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from "react";
import { login as authenticate, getCurrentUser, logout as apiLogout, ssoLogin } from "../api/authService";
import { getOrganizations, getOrganization } from "../api/orgService";
import { Box, CircularProgress } from "@mui/material";
import { toast } from "react-hot-toast";
import { Organization } from "../types/organization";
import { User, AuthContextType } from "../types/auth";
import { updateUserOrgId } from "../api/userService";

// Helper function to format organization data
const formatOrganizations = (orgs: any[]): Organization[] =>
  orgs.map((org) => ({
    id: org.id,
    name: org.name,
    sector: org.sector,
    fiscalYearStart: org.fiscal_year_start,
    crm: org.crm,
    csmId: org.csm_id,
    domain: org.domain,
    legacyGiftThreshold: org.legacy_gift_threshold,
    majorGiftThreshold: org.major_gift_threshold,
    logo: org.logo || "defaultBase64String",
  }));

  const formatOrganization = (org: any): Organization => ({
    id: org.id,
    name: org.name,
    sector: org.sector,
    fiscalYearStart: org.fiscal_year_start,
    crm: org.crm,
    csmId: org.csm_id,
    domain: org.domain,
    legacyGiftThreshold: org.legacy_gift_threshold,
    majorGiftThreshold: org.major_gift_threshold,
    logo: org.logo || "defaultBase64String",
  });

  const formatUser = (user: any): User => ({
    id: user.id,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    phoneNumber: user.phone_number,
    image: user.image || null,
    platformRole: user.platform_role,
    organizationRole: user.organization_role,
    lastLogin: user.last_login || null,
    failedLoginAttempts: user.failed_login_attempts || null,
    org_id: user.org_id || null,
  });

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [userOrgs, setUserOrgs] = useState<Organization[]>([]);
  const [activePage, setActivePage] = useState<string>("login");

  const initializeApp = async () => {
    setIsLoading(true);
    try {
      const fetchedUser = await getCurrentUser();
      if (fetchedUser) {
        setUser(formatUser(fetchedUser));

        // Fetch and process organizations
        const orgs = await fetchAndSetOrganizations();
        if (orgs.length > 0) {
          // Get selectedOrgId from localStorage
          const selectedOrgId = localStorage.getItem("selectedOrgId");
          const selectedOrg = orgs.find((org) => org.id === parseInt(selectedOrgId || "", 10));

          if (selectedOrg) {
            setOrganization(selectedOrg); // Set to the saved organization
          } else {
            const fetchedUserOrg = orgs.find((org) => org.id === fetchedUser.org_id);
            setOrganization(fetchedUserOrg || orgs[0]); // Default to the first organization
            localStorage.setItem("selectedOrgId", (fetchedUserOrg || orgs[0]).id.toString());
          }
        }

        setIsLoggedIn(true);
        setActivePage("dashboard");
      } else {
        resetAuthState();
      }
    } catch (error) {
      console.error("Error initializing app:", error);
      resetAuthState();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initializeApp();
  }, []);  

  const fetchAndSetOrganizations = async (): Promise<Organization[]> => {
    try {
      const orgs = await getOrganizations();
      if (orgs && orgs.length > 0) {
        const formattedOrgs = formatOrganizations(orgs);
        setUserOrgs(formattedOrgs);
        return formattedOrgs;
      } else {
        toast.error("No organizations found.");
        setUserOrgs([]);
        return [];
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
      toast.error("Failed to fetch organizations. Please try again later.");
      setUserOrgs([]);
      return [];
    }
  };

  const resetAuthState = () => {
    setIsLoggedIn(false);
    setUser(null);
    setUserOrgs([]);
    setOrganization(null);
    setActivePage("login");
  };

  const changeOrganization = async (orgId: number) => {
    try {
      const org = await getOrganization(orgId);
      const fetchedUser_ = await getCurrentUser();
      if (org) {
        const formattedOrg = formatOrganization(org); // Format the organization object
        // update the formattedUser with the new orgId
        const updatedUser = { ...fetchedUser_, org_id: org.id };
        await updateUserOrgId(formatUser(updatedUser)).then(() => {
          initializeApp();
        });
        setUser(formatUser(updatedUser));
        localStorage.setItem("selectedOrgId", formattedOrg.id.toString()); 
        toast.success("Organization updated successfully.");
      } else {
        toast.error("Failed to update organization. Organization not found.");
      }
    } catch (error) {
      console.error("Error changing organization:", error);
      toast.error("Failed to update organization.");
    }
  };

  const login = async (userOrEmail: string, password: string) => {
    setIsLoading(true);
    try {
      const loginSuccess = await authenticate(userOrEmail, password);
      if (loginSuccess) {
        const fetchedUser = await getCurrentUser();
        if (fetchedUser) {
          setUser(formatUser(fetchedUser));
  
          // Fetch organizations after login
          const orgs = await fetchAndSetOrganizations();
          if (orgs.length > 0) {
            // Check localStorage for selected organization
            const selectedOrgId = localStorage.getItem("selectedOrgId");
            const selectedOrg = orgs.find((org) => org.id === parseInt(selectedOrgId || "", 10));
  
            if (selectedOrg) {
              setOrganization(selectedOrg); // Set to the saved organization
            } else {
              const fetchedUserOrg = orgs.find((org) => org.id === fetchedUser.org_id);
              setOrganization(fetchedUserOrg || orgs[0]); // Default to the first organization
              localStorage.setItem("selectedOrgId", (fetchedUserOrg || orgs[0]).id.toString());
            }
          }
  
          setIsLoggedIn(true);
          setActivePage("dashboard");
        } else {
          toast.error("Login failed: Could not retrieve user data.");
          resetAuthState();
        }
      } else {
        toast.error("Authentication failed. Please try again.");
        resetAuthState();
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Login error. Please try again.");
      resetAuthState();
    } finally {
      setIsLoading(false);
    }
  };  

  const logout = useCallback(() => {
    apiLogout();
    resetAuthState();
    toast.success("You have been logged out.");
  }, []);

  const initiateSSOLogin = async (email: string) => {
    setIsLoading(true);
    try {
      await ssoLogin(email);
    } catch (error: any) {
      console.error("SSO login error:", error);
      toast.error("SSO login error: " + error.message, {
        style: { backgroundColor: "#f44336", color: "#ffffff" },
        iconTheme: { primary: "#ffffff", secondary: "#f44336" },
        position: "top-center",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        isLoading,
        user,
        ssoLogin: initiateSSOLogin,
        activePage,
        setActivePage,
        userOrgs,
        organization,
        setOrganization,
        changeOrganization,
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};