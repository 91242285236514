import React, { useState } from 'react';
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Button,
  Slider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface SupporterTrendsFilters {
  lybuntOptions: string[];
  legacyDonor: string | null;
  majorDonor: string | null;
  totalLifetimeGifts: number[];
}

interface FilterData {
  category: string;
  filterType: string;
  value: any;
  description: string;
}

interface SupporterTrendsProps {
  filters?: SupporterTrendsFilters;
  setFilters?: React.Dispatch<React.SetStateAction<SupporterTrendsFilters>>;
  onFilterSelect?: (filterData: FilterData) => void;
  isAddingAdvancedFilter?: boolean;
}

const sampleLYBUNTOptions = [
  'Donated Last Year',
  'Donated Two Years Ago',
  'Donated Three Years Ago',
];

// Styles for buttons to match FooterButtons component
const buttonStyle = {
  borderRadius: '5px',
  border: '2px solid #7C90A3',
  background: '#FFF',
  '&:hover': { backgroundColor: '#ECECEC' },
  '&:active': { backgroundColor: '#ECECEC' },
  color: '#7C90A3',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal' as const,
  fontWeight: '700',
  lineHeight: 'normal',
  textTransform: 'none' as const,
  px: 3,
  py: 1,
  minWidth: '150px',
  maxWidth: '170px'
};

// Styles for selected button
const selectedButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#C8D7E1',
  color: '#000',
  '&:hover': { backgroundColor: '#A9C0CD' },
  '&:active': { backgroundColor: '#A9C0CD' },
};

// Styles for filter headers
const filterHeader = {
  color: '#000',
  fontSize: '18px',
  letterSpacing: '0px',
  lineHeight: '1.3',
  fontWeight: '700',
  fontFamily: 'Inter',
  marginBottom: '8px',
}

const SupporterTrends: React.FC<SupporterTrendsProps> = ({
  filters,
  setFilters,
  onFilterSelect,
  isAddingAdvancedFilter = false,
}) => {
  // Provide default filters if undefined
  const effectiveFilters = filters ?? {
    lybuntOptions: [],
    legacyDonor: null,
    majorDonor: null,
    totalLifetimeGifts: [0, 1000000],
  };

  // Use local state when adding an advanced filter
  const [localFilters, setLocalFilters] = useState<SupporterTrendsFilters>(
    effectiveFilters
  );

  // State to track which filter category is being changed
  const [activeFilterCategory, setActiveFilterCategory] = useState<string | null>(null);

  // Handlers to update filters
  const handleLYBUNTOptionsChange = (event: any, newValue: string[]) => {
    if (isAddingAdvancedFilter) {
      setLocalFilters((prevFilters) => ({
        ...prevFilters,
        lybuntOptions: newValue,
      }));
      if (newValue.length > 0) {
        setActiveFilterCategory('LYBUNT');
      } else {
        setActiveFilterCategory(null);
      }
    } else if (setFilters) {
      setFilters((prevFilters) => ({
        ...prevFilters!,
        lybuntOptions: newValue,
      }));
    }
  };

  const handleLegacyDonorChange = (option: string | null) => {
    if (isAddingAdvancedFilter) {
      setLocalFilters((prevFilters) => ({
        ...prevFilters,
        legacyDonor: option,
      }));
      if (option !== null) {
        setActiveFilterCategory('Legacy Donor');
      } else {
        setActiveFilterCategory(null);
      }
    } else if (setFilters) {
      setFilters((prevFilters) => ({
        ...prevFilters!,
        legacyDonor: option,
      }));
    }
  };

  const handleMajorDonorChange = (option: string | null) => {
    if (isAddingAdvancedFilter) {
      setLocalFilters((prevFilters) => ({
        ...prevFilters,
        majorDonor: option,
      }));
      if (option !== null) {
        setActiveFilterCategory('Major Donor');
      } else {
        setActiveFilterCategory(null);
      }
    } else if (setFilters) {
      setFilters((prevFilters) => ({
        ...prevFilters!,
        majorDonor: option,
      }));
    }
  };

  const handleTotalLifetimeGiftsChange = (event: Event, newValue: number | number[]) => {
    const value = newValue as number[];
    if (isAddingAdvancedFilter) {
      setLocalFilters((prevFilters) => ({
        ...prevFilters,
        totalLifetimeGifts: value,
      }));
      if (value[0] !== 0 || value[1] !== 1000000) {
        setActiveFilterCategory('Total Lifetime Gifts');
      } else {
        setActiveFilterCategory(null);
      }
    } else if (setFilters) {
      setFilters((prevFilters) => ({
        ...prevFilters!,
        totalLifetimeGifts: value,
      }));
    }
  };

  // Function to handle adding the filter
  const handleAddFilter = () => {
    if (onFilterSelect && activeFilterCategory) {
      const filterData: FilterData = {
        category: 'Supporter Trends',
        filterType: activeFilterCategory,
        value: localFilters,
        description: getFilterDescription(),
      };
      onFilterSelect(filterData);
      // Reset local state
      setLocalFilters(effectiveFilters);
      setActiveFilterCategory(null);
    }
  };

  // Function to generate filter description
  const getFilterDescription = (): string => {
    switch (activeFilterCategory) {
      case 'LYBUNT':
        return localFilters.lybuntOptions.length > 0
          ? `LYBUNT Options: ${localFilters.lybuntOptions.join(', ')}`
          : '';
      case 'Legacy Donor':
        return localFilters.legacyDonor
          ? `Legacy Donor: ${localFilters.legacyDonor}`
          : '';
      case 'Major Donor':
        return localFilters.majorDonor
          ? `Major Donor: ${localFilters.majorDonor}`
          : '';
      case 'Total Lifetime Gifts':
        return (localFilters.totalLifetimeGifts[0] !== 0 || localFilters.totalLifetimeGifts[1] !== 1000000)
          ? `Total Lifetime Gifts: $${localFilters.totalLifetimeGifts[0]} - $${localFilters.totalLifetimeGifts[1]}`
          : '';
      default:
        return '';
    }
  };

  // Determine if the confirmation area should be displayed
  const showConfirmationArea = isAddingAdvancedFilter && activeFilterCategory && getFilterDescription();

  return (
    <Box sx={{ padding: '1em 0' }}>
      {/* LYBUNT */}
      <Typography
        variant="h4"
        sx={filterHeader}
      >
        LYBUNT
      </Typography>
      <Autocomplete
        multiple
        options={sampleLYBUNTOptions}
        value={
          isAddingAdvancedFilter ? localFilters.lybuntOptions : effectiveFilters.lybuntOptions
        }
        onChange={handleLYBUNTOptionsChange}
        disabled={
          isAddingAdvancedFilter &&
          activeFilterCategory !== null &&
          activeFilterCategory !== 'LYBUNT'
        }
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: '#DEF2FF',
                borderColor: '#6C8EA5',
                color: '#004F6E',
                fontFamily: 'Inter',
                fontWeight: 500,
              }}
              clickable={false}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="LYBUNT Options"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '5px',
                height: 'auto',
                fontFamily: 'Inter',
                fontWeight: 500,
                marginBottom: '16px',
                width: '100%',
                minWidth: '170px',
                maxWidth: '100%',
                paddingRight: '16px !important'
              },
            }}
          />
        )}
        sx={{
          minWidth: { xs: '170px' , sm: '315px' , md: '315px' },
          maxWidth: '100%',
          height: '100%',
          display: { xs: 'inline-block' , sm: 'inline-block' }
        }}
      />

      {/* Legacy Donor */}
      <Typography
        variant="h4"
        sx={filterHeader}
      >
        Legacy Donor
      </Typography>
      <Box sx={{ display: 'flex', gap: '16px' , marginBottom: '16px' }} flexDirection={{ xs: 'column' , sm: 'row', md: 'row' }}>
        {['Yes', 'No'].map((option) => {
          const isSelected =
            (isAddingAdvancedFilter ? localFilters.legacyDonor : effectiveFilters.legacyDonor) ===
            option;
          const isDisabled =
            isAddingAdvancedFilter &&
            activeFilterCategory !== null &&
            activeFilterCategory !== 'Legacy Donor';
          return (
            <Button
              key={`legacyDonor-${option}`}
              onClick={() => {
                handleLegacyDonorChange(isSelected ? null : option);
              }}
              sx={ isSelected ? selectedButtonStyle : buttonStyle}
              disableRipple
              disabled={isDisabled}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontWeight: '700',
                    fontSize: '16px',
                    color: isSelected ? '#000' : '#7C90A3',
                  }}
                >
                  {option}
                </Typography>
                {isSelected ? (
                  <RemoveIcon sx={{ color: '#7C90A3' }} />
                ) : (
                  <AddIcon sx={{ color: '#7C90A3' }} />
                )}
              </Box>
            </Button>
          );
        })}
      </Box>

      {/* Major Donor */}
      <Typography
        variant="h4"
        sx={filterHeader}
        mt={2}
      >
        Major Donor
      </Typography>
      <Box sx={{ display: 'flex', gap: '16px' , marginBottom: '16px' }} flexDirection={{ xs: 'column' , sm: 'row', md: 'row' }}>
        {['Yes', 'No'].map((option) => {
          const isSelected =
            (isAddingAdvancedFilter ? localFilters.majorDonor : effectiveFilters.majorDonor) ===
            option;
          const isDisabled =
            isAddingAdvancedFilter &&
            activeFilterCategory !== null &&
            activeFilterCategory !== 'Major Donor';
          return (
            <Button
              key={`majorDonor-${option}`}
              onClick={() => {
                handleMajorDonorChange(isSelected ? null : option);
              }}
              sx={isSelected ? selectedButtonStyle : buttonStyle}
              disableRipple
              disabled={isDisabled}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: '170px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontWeight: '700',
                    fontSize: '16px',
                    color: isSelected ? '#000' : '#7C90A3',
                  }}
                >
                  {option}
                </Typography>
                {isSelected ? (
                  <RemoveIcon sx={{ color: '#7C90A3' }} />
                ) : (
                  <AddIcon sx={{ color: '#7C90A3' }} />
                )}
              </Box>
            </Button>
          );
        })}
      </Box>

      {/* Total Lifetime Gifts */}
      <Typography
        variant="h4"
        sx={filterHeader}
        mt={2}
      >
        Total Lifetime Gifts
      </Typography>
      <Slider
        value={
          isAddingAdvancedFilter
            ? localFilters.totalLifetimeGifts
            : effectiveFilters.totalLifetimeGifts
        }
        onChange={handleTotalLifetimeGiftsChange}
        valueLabelDisplay="auto"
        min={0}
        max={1000000}
        step={1000}
        disabled={
          isAddingAdvancedFilter &&
          activeFilterCategory !== null &&
          activeFilterCategory !== 'Total Lifetime Gifts'
        }
        marks={[
          { value: 0, label: '$0' },
          { value: 250000, label: '$250k' },
          { value: 500000, label: '$500k' },
          { value: 750000, label: '$750k' },
          { value: 1000000, label: '$1M' },
        ]}
        sx={{
          color: '#004F6E',
          '& .MuiSlider-thumb': {
            borderRadius: '50%',
          },
          '& .MuiSlider-valueLabel': {
            fontFamily: 'Inter',
          },
          margin: '0 1em',
          maxWidth: '90%',
        }}
      />

      {/* Confirmation Area */}
      {showConfirmationArea && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            Selected Filter:
          </Typography>
          <Typography variant="body1">{getFilterDescription()}</Typography>
          <Button
            variant="contained"
            onClick={handleAddFilter}
            sx={{
              fontFamily: 'Inter',
              fontWeight: 600,
              color: '#fff',
              backgroundColor: '#004F6E',
              textTransform: 'none',
              marginTop: 2,
            }}
          >
            Add Filter
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SupporterTrends;
