/**
 * filterUtils.ts
 *
 * Example TypeScript file that:
 * 1) Defines a config object for mapping filter keys -> transformed criteria
 * 2) Builds a "single filter" payload (with multiple criteria)
 * 3) Optionally provides a function to call the /apply-filters endpoint
 */

// ------------------------------------
// 1. DEFINE TYPES
// ------------------------------------
export interface SupporterTrendsFilters {
    lybuntOptions: string[];        // e.g. ["Donated Last Year"]
    legacyDonor: string | null;     // "Yes" | "No" | null
    majorDonor: string | null;      // "Yes" | "No" | null
    totalLifetimeGifts: number[];   // e.g. [min, max]
  }
  
  interface Criterion {
    field: string;
    operator:
      | 'greater_than'
      | 'less_than'
      | 'greater_than_or_equals'
      | 'less_than_or_equals'
      | 'equals'
      | 'not_equals'
      | 'contains'
      | 'does_not_contain';
    value: any;
  }
  
  interface SingleFilterPayload {
    filterObject: {
      criteria: Criterion[];
    };
  }
  
  /**
   * If your real filters differ, adjust these interfaces accordingly.
   * For example, if "lybuntOptions" might have many possible string values,
   * you could create an enum or define more complex logic.
   */
  
  // ------------------------------------
  // 2. DEFINE A MANUAL CONFIG OBJECT
  // ------------------------------------
  /**
   * Each property in this config corresponds to a filter key in `SupporterTrendsFilters`.
   * The value is a function that receives the user-chosen value
   * and returns an array of Criterion objects (which may be empty if no filter applies).
   */
  export const filterConfig: Record<
    keyof SupporterTrendsFilters,
    (inputValue: any) => Criterion[]
  > = {
    /**
     * Example: lybuntOptions -> ["Donated Last Year"]
     * Transform it to something like:
     *   { field: "lybuntStatus", operator: "equals", value: "last_year" }
     */
    lybuntOptions: (inputValue: string[]) => {
      if (!inputValue || inputValue.length === 0) return [];
      // Example transformation (simplified):
      // If user picks "Donated Last Year", we interpret that as "lybuntStatus = last_year".
      // If there might be multiple lybuntOptions, handle them as needed.
      return [
        {
          field: 'lybuntStatus',
          operator: 'equals',
          value: 'last_year',
        },
      ];
    },
  
    /**
     * Example: legacyDonor -> "Yes" | "No" | null
     */
    legacyDonor: (inputValue: string | null) => {
      if (inputValue === 'Yes') {
        return [
          {
            field: 'is_legacy_donor',
            operator: 'equals',
            value: true,
          },
        ];
      } else if (inputValue === 'No') {
        return [
          {
            field: 'is_legacy_donor',
            operator: 'equals',
            value: false,
          },
        ];
      }
      // If null or something else, return an empty array to indicate "no filter"
      return [];
    },
  
    /**
     * Example: majorDonor -> "Yes" | "No" | null
     */
    majorDonor: (inputValue: string | null) => {
      if (inputValue === 'Yes') {
        return [
          {
            field: 'is_major_donor',
            operator: 'equals',
            value: true,
          },
        ];
      } else if (inputValue === 'No') {
        return [
          {
            field: 'is_major_donor',
            operator: 'equals',
            value: false,
          },
        ];
      }
      return [];
    },
  
    /**
     * Example: totalLifetimeGifts -> [min, max]
     */
    totalLifetimeGifts: (inputValue: number[]) => {
      if (!Array.isArray(inputValue) || inputValue.length < 2) {
        return [];
      }
  
      const [minGifts, maxGifts] = inputValue;
      const criteria: Criterion[] = [];
  
      if (minGifts !== null && minGifts !== undefined) {
        criteria.push({
          field: 'totalLifetimeGifts',
          operator: 'greater_than_or_equals',
          value: minGifts,
        });
      }
  
      if (maxGifts !== null && maxGifts !== undefined) {
        criteria.push({
          field: 'totalLifetimeGifts',
          operator: 'less_than_or_equals',
          value: maxGifts,
        });
      }
  
      return criteria;
    },
  };
  
  // ------------------------------------
  // 3. BUILD THE SINGLE FILTER PAYLOAD
  // ------------------------------------
  /**
   * This function:
   *   - Iterates over the keys of your `SupporterTrendsFilters` object
   *   - Looks up each corresponding function in `filterConfig`
   *   - Collects all Criterion objects into a single array
   *   - Wraps them in the final { filterObject: { criteria } } structure
   *
   * Use this for constructing the body of your /apply-filters POST request
   * whenever you want a "single filter" that combines multiple user-chosen criteria (via logical AND).
   */
  export function buildSingleFilterPayload(
    filters: SupporterTrendsFilters
  ): SingleFilterPayload {
    let criteria: Criterion[] = [];
  
    // For each key in the user filters, apply the transform function
    for (const [key, value] of Object.entries(filters)) {
      // If the config has a transform for this key, use it
      if (key in filterConfig) {
        const transformFn = filterConfig[key as keyof SupporterTrendsFilters];
        if (typeof transformFn === 'function') {
          const result = transformFn(value);
          criteria = criteria.concat(result);
        }
      }
    }
  
    return {
      filterObject: {
        criteria,
      },
    };
  }
  
  // ------------------------------------
  // 4. (OPTIONAL) CALL THE ENDPOINT
  // ------------------------------------
  /**
   * If you want to keep the API call in the same file, here's a simple example.
   * Otherwise, you can place it in a separate file like `api.ts`.
   */
  export async function callApplyFilters(payload: SingleFilterPayload) {
    try {
      const response = await fetch('/api/apply-filters', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(
          `Failed to apply filters. Status: ${response.status} - ${response.statusText}`
        );
      }
  
      const data = await response.json();
      // data should have shape: { supporters: [...] }
      return data;
    } catch (error) {
      console.error('Error calling /apply-filters:', error);
      throw error;
    }
  }
  