import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Container from '@mui/system/Container';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import dayjs, { Dayjs } from 'dayjs';

import PauseEmailDialog from '../../components/org_email_settings/PauseEmailDialog';
import EmergencyStopDialog from '../../components/org_email_settings/EmergencyStopDialog';

/** Helper to convert numeric hours to a user-friendly string. */
function getTimeLabel(hour: number): string {
  const isPM = hour >= 12;
  const displayHour = isPM ? hour - 12 : hour;
  const ampm = isPM ? 'PM' : 'AM';
  return `${displayHour}:00 ${ampm}`;
}

// Numeric hours from 9 AM (9) to 5 PM (17)
const validTimes = [9, 10, 11, 12, 13, 14, 15, 16, 17];

interface DaySchedule {
  day: string;
  active: boolean;
  start: number; // e.g., 9 for 9:00 AM
  end: number;   // e.g., 17 for 5:00 PM
}

interface BlackoutDate {
  start: Dayjs;
  end: Dayjs;
  reason: string;
}

/** Exceptions state: radio selection for each category. */
interface ExceptionsState {
  thankYouEmails: 'alwaysSend' | 'followRules' | 'doNotSend';
  birthdayEmails: 'alwaysSend' | 'followRules' | 'doNotSend';
}

const EmailSettings: React.FC = () => {
  // Toggle read-only / editing mode
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // Sending schedule for weekdays
  const [schedules, setSchedules] = useState<DaySchedule[]>([
    { day: 'Monday', active: true, start: 9, end: 17 },
    { day: 'Tuesday', active: true, start: 9, end: 17 },
    { day: 'Wednesday', active: true, start: 9, end: 17 },
    { day: 'Thursday', active: true, start: 9, end: 17 },
    { day: 'Friday', active: true, start: 9, end: 17 },
  ]);

  // Blackout dates
  const [blackouts, setBlackouts] = useState<BlackoutDate[]>([]);

  // Exceptions radio states
  const [exceptions, setExceptions] = useState<ExceptionsState>({
    thankYouEmails: 'alwaysSend',
    birthdayEmails: 'alwaysSend',
  });

  // Daily Sending Limit
  const [dailyLimit, setDailyLimit] = useState<number>(49000);

  /** Toggle Edit / Save */
  const handleToggleEdit = () => {
    // If isEditing was true, you'd typically save changes to your API here
    setIsEditing(!isEditing);
  };

  // Pause and Emergency dialog states
  const [isPauseDialogOpen, setIsPauseDialogOpen] = useState(false);
  const [isEmergencyDialogOpen, setIsEmergencyDialogOpen] = useState(false);
  const [isEmailPaused, setIsEmailPaused] = useState(false);  // Track if the email sending is paused

  const [isRegularPauseActive, setIsRegularPauseActive] = useState(false);
  const [isEmergencyStopActive, setIsEmergencyStopActive] = useState(false);

  const openPauseDialog = () => setIsPauseDialogOpen(true);
  const closePauseDialog = () => setIsPauseDialogOpen(false);
  const openEmergencyDialog = () => setIsEmergencyDialogOpen(true);
  const closeEmergencyDialog = () => setIsEmergencyDialogOpen(false);

  const handlePauseConfirm = (date: Dayjs) => {
    // Logic to set the pause until the specified date
    closePauseDialog();
    setIsEmailPaused(true);
    setIsRegularPauseActive(true);
    setIsEmergencyStopActive(false);
  };
  
  const handleEmergencyConfirm = () => {
    // Logic to handle an emergency stop
    closeEmergencyDialog();
    setIsEmailPaused(true);
    setIsEmergencyStopActive(true);
    setIsRegularPauseActive(false);
  };
  
  const handleResumeSending = () => {
    setIsEmailPaused(false); // Resume email sending
    setIsRegularPauseActive(false);
    setIsEmergencyStopActive(false);
  };

  /** Toggle a weekday active/inactive */
  const handleToggleDay = (index: number) => {
    if (!isEditing) return;
    setSchedules((prev) => {
      const newSchedules = [...prev];
      newSchedules[index].active = !newSchedules[index].active;
      return newSchedules;
    });
  };

  /** Change start/end numeric hour for a given day */
  const handleTimeChange = (index: number, field: 'start' | 'end', newValue: number) => {
    if (!isEditing) return;
  
    setSchedules((prev) => {
      const newSchedules = [...prev];
      const schedule = newSchedules[index];
  
      // Temporarily store the old value
      const oldValue = schedule[field];
  
      // Assign the new value
      schedule[field] = newValue;
  
      // Now validate: start < end must be true
      if (schedule.start >= schedule.end) {
        // Revert to old value OR handle it in your preferred way
        schedule[field] = oldValue;
        alert("Invalid time range: start must be earlier than end.");
      }
  
      return newSchedules;
    });
  };

  /** Add a new blackout row */
  const handleAddBlackout = () => {
    if (!isEditing) return;
    setBlackouts((prev) => [
      ...prev,
      {
        start: dayjs(),
        end: dayjs(),
        reason: '',
      },
    ]);
  };

  /** Delete a blackout row */
  const handleDeleteBlackout = (idx: number) => {
    if (!isEditing) return;
    setBlackouts((prev) => prev.filter((_, i) => i !== idx));
  };

  /** Change a blackout date or reason */
  const handleBlackoutChange = (
    idx: number,
    field: 'start' | 'end' | 'reason',
    newValue: Dayjs | string
  ) => {
    if (!isEditing) return;
    setBlackouts((prev) => {
      const newBlackouts = [...prev];
      const blackout = newBlackouts[idx];
  
      if (field === 'reason') {
        blackout.reason = newValue as string;
      } else {
        // field is 'start' or 'end'
        const oldDate = blackout[field] as Dayjs;
        blackout[field] = newValue as Dayjs;
  
        // Validate: end >= start is allowed
        const startDate = (field === 'start') ? newValue as Dayjs : blackout.start;
        const endDate = (field === 'end') ? newValue as Dayjs : blackout.end;
  
        if (endDate.isBefore(startDate)) {
          // revert
          blackout[field] = oldDate;
          alert("Invalid date range: End date cannot be earlier than start date.");
        }
      }
  
      return newBlackouts;
    });
  };

  /** Update Exceptions (radio groups) */
  const handleExceptionsChange = (
    category: 'thankYouEmails' | 'birthdayEmails',
    value: 'alwaysSend' | 'followRules' | 'doNotSend'
  ) => {
    if (!isEditing) return;
    setExceptions((prev) => ({ ...prev, [category]: value }));
  };

  /** Update daily limit numeric input */
  const handleDailyLimitChange = (value: string) => {
    if (!isEditing) return;
    const parsed = parseInt(value, 10);
    if (!isNaN(parsed)) {
      setDailyLimit(parsed);
    } else if (value === '') {
      // If empty, set to 0 or handle differently
      setDailyLimit(0);
    }
  };

  /** Utility to get the read-only text for a radio selection. */
  const getExceptionLabel = (val: 'alwaysSend' | 'followRules' | 'doNotSend') => {
    switch (val) {
      case 'alwaysSend':
        return 'Always send (ignores all other sending rules)';
      case 'followRules':
        return 'Follow all sending rules';
      case 'doNotSend':
        return 'Do not send';
      default:
        return '';
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: { xs: '24px', sm: '24px', md: '32px', lg: '40px' } }}>
        {/* Title row */}
        <Box display="block" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'center' }} justifyContent="space-between" sx={{ width: '100%' }}>
            <Typography variant="h4" minWidth="250px" sx={{ fontFamily: 'Inter', color: '#33475B', mr: 2 , mb: { xs: 1, sm: 1 } }}>
              Email Settings
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '3px',
                  border: isEmailPaused ? '1px solid #A62929' : '1px solid #458D0C',
                  backgroundColor: isEmailPaused ? '#FFDEDE' : '#E5FAD4',
                  color: isEmailPaused ? '#A62929' : '#458D0C',
                  px: 1.5,
                  py: 0.5,
                  fontFamily: 'Inter',
                  fontWeight: 'bold',
                  fontSize: '16px'
                }}
              >
                {isEmailPaused ? 'SENDING PAUSED' : 'SENDING ACTIVE'}
              </Box>
              <Button
                onClick={handleToggleEdit}
                sx={{
                  borderRadius: '3px',
                  border: '1px solid #00A7E0',
                  backgroundColor: '#FFF',
                  color: '#00A7E0',
                  fontFamily: 'Inter',
                  textTransform: 'none',
                  px: 2,
                  py: 0.5,
                }}
              >
                {isEditing ? 'Save section' : 'Edit settings'}
              </Button>
            </Box>
            
          </Box>
          
        </Box>

        {/* Main box for categories */}
        <Container maxWidth={false} sx={{ border: '1px solid #C8D7E1', borderRadius: '5px', p: 2 , width: '100%', maxWidth: 'calc(100%)' }}>
          <Grid container columnSpacing={4} rowSpacing={3}>
            {/* === 1) Sending Schedule === */}
            <Grid item sm={12} lg={3}>
            <Divider textAlign="left" aria-hidden="true">
                <Typography sx={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: 18 }}>
                  Sending Schedule
                </Typography>
              </Divider>
              <Typography sx={{ fontFamily: 'Inter', fontStyle: 'italic', fontSize: 14 }}>
                Emails will only be able to be scheduled between the days and hours specified here.
              </Typography>
            </Grid>
            <Grid item  sm={12} lg={9}>
              {schedules.map((sched, index) => (
                <Box display="flex" alignItems="center" mb={1} gap={2} key={sched.day}>
                  <Checkbox
                    checked={sched.active}
                    disabled={!isEditing}
                    onChange={() => handleToggleDay(index)}
                    sx={{ p: 0 }}
                  />
                  <Typography sx={{ fontFamily: 'Inter', minWidth: 80 }}>
                    {sched.day}
                  </Typography>

                  {sched.active ? (
                    isEditing ? (
                      <Box display="flex" alignItems="center" gap={1}>
                        <FormControl size="small">
                          <Select
                            value={sched.start}
                            onChange={(e: SelectChangeEvent<number>) =>
                              handleTimeChange(index, 'start', e.target.value as number)
                            }
                            sx={{ fontFamily: 'Inter', width: 120 }}
                          >
                            {validTimes.map((hour) => (
                              <MenuItem key={hour} value={hour} sx={{ fontFamily: 'Inter' }}>
                                {getTimeLabel(hour)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Typography>-</Typography>
                        <FormControl size="small">
                          <Select
                            value={sched.end}
                            onChange={(e: SelectChangeEvent<number>) =>
                              handleTimeChange(index, 'end', e.target.value as number)
                            }
                            sx={{ fontFamily: 'Inter', width: 120 }}
                          >
                            {validTimes.map((hour) => (
                              <MenuItem key={hour} value={hour} sx={{ fontFamily: 'Inter' }}>
                                {getTimeLabel(hour)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    ) : (
                      <Typography sx={{ fontFamily: 'Inter', fontSize: 14 }}>
                        {getTimeLabel(sched.start)} to {getTimeLabel(sched.end)}
                      </Typography>
                    )
                  ) : (
                    <Typography
                      sx={{ fontFamily: 'Inter', fontStyle: 'italic', color: '#777', fontSize: 14 }}
                    >
                      inactive
                    </Typography>
                  )}
                </Box>
              ))}
            </Grid>

            {/* === 2) Blackout Dates === */}
            <Grid item  sm={12} lg={3}>
              <Divider textAlign="left" aria-hidden="true">
                <Typography sx={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: 18 }}>
                  Blackout Dates
                </Typography>
              </Divider>
              <Typography sx={{ fontFamily: 'Inter', fontStyle: 'italic', fontSize: 14 }}>
                These are no-send days. Emails cannot be scheduled on days that are specified here.
              </Typography>
            </Grid>
            <Grid item  sm={12} lg={9}>
              <Box sx={{ width: '100%', maxWidth: '100%' }}>
                {/* Header row: also set wrap="nowrap" */}
                <Grid
                  container
                  alignItems="center"
                  wrap="nowrap"
                  sx={{ 
                    mb: 2 ,
                    display: 'flex'
                  }}
                >
                  <Grid item xs={5} sm={3} md={3} lg={3} sx={{fontWeight: 'bold'}}>Start</Grid>
                  <Grid item xs={5} sm={3} md={3} lg={3} sx={{fontWeight: 'bold'}}>End</Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} sx={{ fontWeight: 'bold' }}>Days</Grid>
                  <Grid item sm={4} md={4} lg={4} sx={{ fontWeight: 'bold' , display: { xs: 'none', md: 'block' }}}>Reason</Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                </Grid>

                {blackouts.length === 0 && (
                  <Box mb={1}>
                    <Typography sx={{ fontFamily: 'Inter', fontSize: 14 }}>
                      No blackout date entries
                    </Typography>
                  </Box>
                )}

                {/* Each blackout row: wrap="nowrap" and columns sum to 12 */}
                {blackouts.map((blk, idx) => (
                  <Grid
                    container
                    key={idx}
                    alignItems="center"
                    spacing={2}
                    wrap="wrap"
                    sx={{ mb: 2 }}
                    maxWidth="100%"
                  >
                    {/* xs=3 */}
                    <Grid item xs={5} sm={3} md={3} lg={3}>
                      {isEditing ? (
                        <DatePicker
                          label="Start Date"
                          value={blk.start}
                          onChange={(date) => {
                            if (date) {
                              handleBlackoutChange(idx, 'start', date);
                            }
                          }}
                          slotProps={{
                            textField: {
                              size: 'small',
                              sx: { fontFamily: 'Inter', minWidth: '150px', mr: 5},
                            },
                          }}
                        />
                      ) : (
                        <Typography sx={{ fontFamily: 'Inter' }}>
                          {blk.start.format('MM/DD/YYYY')}
                        </Typography>
                      )}
                    </Grid>
                    {/* xs=3 */}
                    <Grid item xs={5} sm={3} md={3} lg={3}>
                      {isEditing ? (
                        <DatePicker
                          label="End Date"
                          value={blk.end}
                          onChange={(date) => {
                            if (date) {
                              handleBlackoutChange(idx, 'end', date);
                            }
                          }}
                          slotProps={{
                            textField: {
                              size: 'small',
                              sx: { fontFamily: 'Inter', minWidth: '150px', mr: 5 },
                            },
                          }}
                        />
                      ) : (
                        <Typography sx={{ fontFamily: 'Inter' }}>
                          {blk.end.format('MM/DD/YYYY')}
                        </Typography>
                      )}
                    </Grid>
                    {/* xs=1 */}
                    <Grid item xs={1} sm={1} md={1} lg={1}  >
                      {(() => {
                        const startDate = blk.start;
                        const endDate = blk.end;
                        // +1 so 2/14 - 2/14 is 1 day
                        const dayCount = endDate.diff(startDate, 'day') + 1; 
                        const isOver180 = dayCount > 180;

                        return (
                          <Typography
                            sx={{
                              fontFamily: 'Inter',
                              color: isOver180 ? '#A62929' : '#000', 
                              fontSize: 14,
                              fontWeight: isOver180 ? 'bold' : 'normal'
                            }}
                          >
                            {dayCount}
                          </Typography>
                        );
                      })()}
                    </Grid>
                    {/* xs=4 */}
                    <Grid item xs={11} sm={9} md={4} lg={4}>
                      {isEditing ? (
                        <TextField
                          label="Reason"
                          value={blk.reason}
                          onChange={(e) => handleBlackoutChange(idx, 'reason', e.target.value)}
                          size="small"
                          multiline
                          sx={{ 
                            width: '100%', 
                            fontFamily: 'Inter' ,
                            maxWidth: '100%'
                          }}
                        />
                      ) : (
                        <Typography sx={{ fontFamily: 'Inter', whiteSpace: 'pre-wrap' }}>
                          {blk.reason || '(no reason)'}
                        </Typography>
                      )}
                    </Grid>
                    {/* xs=1 */}
                    <Grid item xs={1} sm={2} md={1} lg={1}>
                      {isEditing && (
                        <IconButton onClick={() => handleDeleteBlackout(idx)} sx={{ color: '#A62929' }}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                ))}

                {isEditing && (
                  <Box mt={1} display="flex" justifyContent="flex-start">
                    <IconButton onClick={handleAddBlackout} sx={{ color: '#00A7E0' }}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Grid>

            {/* === 3) Exceptions === */}
            <Grid item xs={12} sm={12} lg={3}>
            <Divider textAlign="left" aria-hidden="true">
                <Typography sx={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: 18 }}>
                  Exceptions
                </Typography>
              </Divider>
              <Typography sx={{ fontFamily: 'Inter', fontStyle: 'italic', fontSize: 14 }}>
                Specify special behavior for certain email types.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={9}>
              {/* Thank You Emails */}
              <Box mb={2}>
                <Typography sx={{ fontFamily: 'Inter', fontWeight: 'bold', mb: 1 }}>
                  Thank you emails
                </Typography>
                {isEditing ? (
                  <RadioGroup
                    value={exceptions.thankYouEmails}
                    onChange={(e) =>
                      handleExceptionsChange('thankYouEmails', e.target.value as any)
                    }
                  >
                    <FormControlLabel
                      value="alwaysSend"
                      control={<Radio />}
                      label="Always send (ignores all other sending rules)"
                      sx={{ fontFamily: 'Inter' }}
                    />
                    <FormControlLabel
                      value="followRules"
                      control={<Radio />}
                      label="Follow all sending rules"
                      sx={{ fontFamily: 'Inter' }}
                    />
                    <FormControlLabel
                      value="doNotSend"
                      control={<Radio />}
                      label="Do not send"
                      sx={{ fontFamily: 'Inter' }}
                    />
                  </RadioGroup>
                ) : (
                  <Typography sx={{ fontFamily: 'Inter' }}>
                    {(() => {
                      switch (exceptions.thankYouEmails) {
                        case 'alwaysSend':
                          return 'Always send (ignores all other sending rules)';
                        case 'followRules':
                          return 'Follow all sending rules';
                        case 'doNotSend':
                          return 'Do not send';
                        default:
                          return '';
                      }
                    })()}
                  </Typography>
                )}
              </Box>

              {/* Birthday Emails */}
              <Box mb={2}>
                <Typography sx={{ fontFamily: 'Inter', fontWeight: 'bold', mb: 1 }}>
                  Birthday emails
                </Typography>
                {isEditing ? (
                  <RadioGroup
                    value={exceptions.birthdayEmails}
                    onChange={(e) =>
                      handleExceptionsChange('birthdayEmails', e.target.value as any)
                    }
                  >
                    <FormControlLabel
                      value="alwaysSend"
                      control={<Radio />}
                      label="Always send (ignores all other sending rules)"
                      sx={{ fontFamily: 'Inter' }}
                    />
                    <FormControlLabel
                      value="followRules"
                      control={<Radio />}
                      label="Follow all sending rules"
                      sx={{ fontFamily: 'Inter' }}
                    />
                    <FormControlLabel
                      value="doNotSend"
                      control={<Radio />}
                      label="Do not send"
                      sx={{ fontFamily: 'Inter' }}
                    />
                  </RadioGroup>
                ) : (
                  <Typography sx={{ fontFamily: 'Inter' }}>
                    {(() => {
                      switch (exceptions.birthdayEmails) {
                        case 'alwaysSend':
                          return 'Always send (ignores all other sending rules)';
                        case 'followRules':
                          return 'Follow all sending rules';
                        case 'doNotSend':
                          return 'Do not send';
                        default:
                          return '';
                      }
                    })()}
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* === 4) Daily Sending Limit === */}
            <Grid item xs={12} md={8} lg={3}>
            <Divider textAlign="left" aria-hidden="true">
                <Typography sx={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: 18 }}>
                  Daily Sending Limit
                </Typography>
              </Divider>
              <Typography sx={{ fontFamily: 'Inter', fontStyle: 'italic', fontSize: 14 }}>
                Maximum is 49k.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              {isEditing ? (
                <TextField
                  type="number"
                  value={dailyLimit}
                  onChange={(e) => handleDailyLimitChange(e.target.value)}
                  size="small"
                  sx={{ fontFamily: 'Inter', width: '120px' }}
                />
              ) : (
                <Typography sx={{ fontFamily: 'Inter', fontSize: 16 }}>
                  {dailyLimit}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>

        <Box mt={4}>
          <Typography variant="h4" gutterBottom mb={2} sx={{ fontFamily: 'Inter', color: '#33475B' }}>
            Pause Email Sending
          </Typography>

          <Grid container spacing={2}>
            {/* Left Box: Pause for a Set Period */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  border: '1px solid #C8D7E1',
                  borderRadius: '5px',
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%'
                }}
              >
                <div>
                  <Typography
                    sx={{
                      color: '#33475B',
                      fontFamily: 'Inter',
                      fontWeight: 700,
                      fontSize: 16,
                      mb: 1
                    }}
                  >
                    Pause for a Set Period
                  </Typography>
                  <Typography sx={{ fontFamily: 'Inter', fontSize: 14, flexGrow: 1 }}>
                    This will pause all emails* in your System until the date you set to resume sending. 
                    <br />
                    <br />
                    *If you have set exceptions for Thank You and Birthday type emails, 
                    they will not be affected.
                  </Typography>
                </div>
                <Button
                  onClick={isRegularPauseActive ? handleResumeSending : openPauseDialog}
                  sx={{
                    borderRadius: '5px',
                    backgroundColor: '#C8D7E1',
                    "&:hover": { backgroundColor: '#A9C0CD' },
                    "&:active": { backgroundColor: '#A9C0CD' },
                    color: '#000',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: '700',
                    textTransform: 'none',
                    mt: 3,
                    width: 'fit-content',
                    alignSelf: 'center',
                    padding: '7px 14px'
                  }}
                >
                  {isRegularPauseActive ? 'Resume Sending' : 'Pause Sending'}
                </Button>
              </Box>
            </Grid>

            {/* Right Box: Emergency Stop Indefinitely */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  border: '1px solid #C8D7E1',
                  borderRadius: '5px',
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%'
                }}
              >
                <div>
                  <Typography
                    sx={{
                      color: '#33475B',
                      fontFamily: 'Inter',
                      fontWeight: 700,
                      fontSize: 16,
                      mb: 1
                    }}
                  >
                    Emergency Stop Indefinitely
                  </Typography>
                  <Typography sx={{ fontFamily: 'Inter', fontSize: 14, flexGrow: 1 }}>
                    This will pause all emails* in your System indefinitely. 
                    Emails will not resume until you have re-activated sending again. 
                    <br />
                    <br />
                    *If you have set exceptions for Thank You and Birthday type emails, 
                    they will not be affected.
                  </Typography>
                </div>
                <Button
                  onClick={isEmergencyStopActive ? handleResumeSending : openEmergencyDialog}
                  sx={{
                    borderRadius: '5px',
                    backgroundColor: '#AD2119',
                    "&:hover": { backgroundColor: '#8A1B14' },
                    "&:active": { backgroundColor: '#8A1B14' },
                    color: '#FFF',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: '700',
                    textTransform: 'none',
                    mt: 3,
                    width: 'fit-content',
                    alignSelf: 'center',
                    padding: '7px 14px'
                  }}
                >
                  {isEmergencyStopActive ? 'Resume Sending' : 'Emergency Stop'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <PauseEmailDialog
          open={isPauseDialogOpen}
          onClose={closePauseDialog}
          onConfirm={handlePauseConfirm}
        />
        <EmergencyStopDialog
          open={isEmergencyDialogOpen}
          onClose={closeEmergencyDialog}
          onConfirm={handleEmergencyConfirm}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default EmailSettings;
