import React from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { PieChart } from '@mui/x-charts/PieChart';

interface AuditData {
  url: string;
  pagesCrawled: number;
  pagesMeetRequirements: number;
  pagesNeedMoreInfo: number;
  topicsMissing: number;
  pagesFailed: number;
}

interface AuditSiteProps {
  auditData: AuditData;
  showViewReport: boolean;            // New prop: whether to show the button
  onViewReportClick: () => void;     // New prop: callback for "View Report"
}

const AuditSite: React.FC<AuditSiteProps> = ({ auditData, showViewReport, onViewReportClick }) => {
  const {
    url,
    pagesCrawled,
    pagesMeetRequirements,
    pagesNeedMoreInfo,
    topicsMissing,
    pagesFailed,
  } = auditData;

  // Prepare data for the donut (PieChart)
  const chartData = [
    {
      id: 'Pages Crawled',
      label: 'Pages Crawled',
      value: pagesCrawled,
      color: '#458D0C', // green
    },
    {
      id: 'Pages Meet Requirements',
      label: 'Pages Meet Requirements',
      value: pagesMeetRequirements,
      color: '#458D0C', // green
    },
    {
      id: 'Pages Need More Info',
      label: 'Pages Need More Info',
      value: pagesNeedMoreInfo,
      color: '#E4BB07', // yellow
    },
    {
      id: 'Topics Missing',
      label: 'Topics Missing',
      value: topicsMissing,
      color: '#BB4E4E', // red
    },
    {
      id: 'Pages Failed to Crawl',
      label: 'Pages Failed to Crawl',
      value: pagesFailed,
      color: '#BB4E4E', // red
    },
  ];

  return (
    <Box
      p={2}
      sx={{
        border: '2px solid #C8D7E1',
        borderRadius: '5px',
        backgroundColor: '#FFF',
      }}
    >
      {/* URL Heading */}
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Inter',
          color: '#33475B',
          marginBottom: '8px', // Slightly less gap below heading
        }}
      >
        Auditing: {url || 'No URL Provided'}
      </Typography>

      {/* Flex container for chart & legend */}
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems="center"        // Vertically centers chart & legend together
        justifyContent="center"    // Horizontally centers chart & legend as a unit
        columnGap={4}             // Space between chart and legend
      >
        {/* Donut Chart */}
        <Box display="flex" justifyContent="center" alignItems="center">
          <PieChart
            series={[
              {
                data: chartData,
                innerRadius: '35%',
                outerRadius: '90%',
                paddingAngle: 3,
                cornerRadius: 3,
              },
            ]}
            width={350}
            height={350}
            colors={chartData.map((item) => item.color)}
            aria-label="Audit Data Chart"
            slotProps={{ legend: { hidden: true } }}
          />
        </Box>

        {/* Legend */}
        <Box>
          <Typography
            variant="subtitle1"
            component="h3"
            gutterBottom
            fontWeight="bold"
            fontFamily="'Inter', sans-serif"
            color="#33475B"
          >
            Audit Summary:
          </Typography>

          <List sx={{ paddingTop: 0 }}>
            {chartData.map((category) => (
              <ListItem
                key={category.id}
                sx={{ padding: 0, marginBottom: '8px', alignItems: 'center' }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '30px',
                    color: category.color,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CircleIcon sx={{ color: category.color, verticalAlign: 'middle' }} />
                </ListItemIcon>
                <ListItemText
                  primary={`${category.value} ${category.label}`}
                  sx={{
                    color: category.color,
                    margin: 0.5,
                    fontFamily: "'Inter', sans-serif",
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>

      {/* "View Report" Button: always shown for now */}
      {showViewReport && (
        <Box mt={0} mb={2} display="flex" justifyContent="center">
          <Button
            onClick={onViewReportClick}
            sx={{
              borderRadius: '5px',
              backgroundColor: '#C8D7E1',
              '&:hover': { backgroundColor: '#A9C0CD' },
              '&:active': { backgroundColor: '#A9C0CD' },
              color: '#000',
              fontFamily: 'Inter',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              textTransform: 'none',
              px: 3,
              height: 40, // Matches style from "Audit" button
            }}
          >
            View Report
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AuditSite;
