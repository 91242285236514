import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Divider,
  Button, // NEW: so we can use Button
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import SavedLists from './SavedLists';
import SavedFilters from './SavedFilters';
import SupporterJourney from './SupporterJourney';
import SupporterTrends from './SupporterTrends';
import SupporterVoice from './SupporterVoice';
import AdvancedFilters from './AdvancedFilters';
import { getUserLists } from '../../api/supporterService';
import { useAuth } from '../../contexts/AuthContext';
import { SelectChangeEvent } from '@mui/material/Select';

// 1) IMPORT YOUR UTILITY FUNCTIONS
import {
  buildSingleFilterPayload,
  callApplyFilters,
} from '../../api/filterUtils';

// 2) INTERFACES
interface FilterSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  selectedFilterLists: Set<number>;
  setSelectedFilterLists: React.Dispatch<React.SetStateAction<Set<number>>>;
}

interface SupporterJourneyFilters {
  outreachOptions: string[];
  preQualified: string | null;
  originalIdentity: string[];
}

interface SupporterTrendsFilters {
  lybuntOptions: string[];
  legacyDonor: string | null;
  majorDonor: string | null;
  totalLifetimeGifts: number[];
}

interface SupporterVoiceFilters {
  missionAgreementOptions: string[];
  locationOptions: string[];
  optIn: string | null;
}

interface AdvancedFilter {
  id: number;
  data: any; // The filter data selected from categories
  not: boolean;
}

interface Filter {
  id: number;
  name: string;
}

const FilterSidebar: React.FC<FilterSidebarProps> = ({
  isOpen,
  onClose,
  selectedFilterLists,
  setSelectedFilterLists,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [lists, setLists] = useState<any[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<Set<number>>(new Set());
  const [loading, setLoading] = useState(true);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const { user } = useAuth();

  // Mocked saved filters for the "Saved Filters" panel
  const [filters, setFilters] = useState<Filter[]>([
    { id: 1, name: 'Filter One' },
    { id: 2, name: 'Filter Two' },
  ]);

  // States for each category of filters
  const [supporterJourneyFilters, setSupporterJourneyFilters] =
    useState<SupporterJourneyFilters>({
      outreachOptions: [],
      preQualified: null,
      originalIdentity: [],
    });

  const [supporterTrendsFilters, setSupporterTrendsFilters] =
    useState<SupporterTrendsFilters>({
      lybuntOptions: [],
      legacyDonor: null,
      majorDonor: null,
      totalLifetimeGifts: [0, 1000000],
    });

  const [supporterVoiceFilters, setSupporterVoiceFilters] =
    useState<SupporterVoiceFilters>({
      missionAgreementOptions: [],
      locationOptions: [],
      optIn: null,
    });

  // Advanced Filters
  const [advancedFilters, setAdvancedFilters] = useState<AdvancedFilter[]>([]);
  const [isAddingAdvancedFilter, setIsAddingAdvancedFilter] = useState(false);

  // 3) Fetch user lists on mount or user change
  useEffect(() => {
    const fetchLists = async () => {
      if (user && lists.length === 0) {
        setLoading(true);
        try {
          const response = await getUserLists(user.id);
          setLists(response.results);
          console.log('Fetched user lists:', response.results);
        } catch (error) {
          console.error('Error fetching lists:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchLists();
  }, [user, lists]);

  // 4) Log states for debugging
  useEffect(() => {
    console.log('Selected Category:', selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    console.log('Selected Filter Lists:', Array.from(selectedFilterLists));
  }, [selectedFilterLists]);

  useEffect(() => {
    console.log('Selected Filters:', Array.from(selectedFilters));
  }, [selectedFilters]);

  useEffect(() => {
    console.log('Supporter Journey Filters:', supporterJourneyFilters);
  }, [supporterJourneyFilters]);

  useEffect(() => {
    console.log('Supporter Trends Filters:', supporterTrendsFilters);
  }, [supporterTrendsFilters]);

  useEffect(() => {
    console.log('Supporter Voice Filters:', supporterVoiceFilters);
  }, [supporterVoiceFilters]);

  useEffect(() => {
    console.log('Advanced Filters:', advancedFilters);
  }, [advancedFilters]);

  // ----------------------------------
  // 5) APPLY FILTERS BUTTON HANDLER
  // ----------------------------------
  /**
   * Example: apply only the supporterTrendsFilters in this single-filter payload.
   * If you want to combine all, you'd unify them into a single "combined" object 
   * that your build function can handle (like an allFilters object).
   */
  const handleApplyFilters = async () => {
    try {
      console.log('Applying filters...');
      // Build the filter payload from the user-chosen SupporterTrends filters
      const payload = buildSingleFilterPayload(supporterTrendsFilters);

      // Call the endpoint
      const data = await callApplyFilters(payload);

      // Now you have "data.supporters" from the server
      console.log('Apply filter results:', data.supporters);
      // ... do something with the results
    } catch (error) {
      console.error('Failed to apply filters:', error);
    }
  };

  // ----------------------------------
  // 6) Event & Rendering Logic
  // ----------------------------------
  const handleCategoryClick = (category: string) => {
    console.log(`Category clicked: ${category}`);
    setSelectedCategory(category);
  };

  const toggleAdvancedFilters = () => {
    console.log('Toggling advanced filters');
    setIsAdvancedOpen(!isAdvancedOpen);
  };

  const handleAddAdvancedFilter = () => {
    console.log('Adding advanced filter');
    setIsAddingAdvancedFilter(true);
    setSelectedCategory(null); // Reset to category selection
  };

  const handleAddFilterToAdvancedFilters = (filterData: any) => {
    console.log('Filter Data selected for advanced:', filterData);
    const newFilter: AdvancedFilter = {
      id: Date.now(),
      data: filterData,
      not: false,
    };
    setAdvancedFilters([...advancedFilters, newFilter]);
    setIsAddingAdvancedFilter(false);
    setSelectedCategory(null); // Reset after adding
  };

  const renderCategoryComponent = () => {
    if (isAddingAdvancedFilter) {
      // If we're in "adding advanced filter" mode and no category is selected
      if (selectedCategory === null) {
        return (
          <List>
            {['Supporter Voice', 'Supporter Journey', 'Supporter Trends'].map(
              (category) => (
                <ListItem
                  key={category}
                  button
                  onClick={() => setSelectedCategory(category)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '12px 16px',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                    color: '#004F6E',
                  }}
                >
                  <ListItemText
                    primary={category}
                    primaryTypographyProps={{
                      sx: {
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        color: '#004F6E',
                        fontSize: '1.1rem',
                      },
                    }}
                  />
                  <ListItemIcon
                    sx={{
                      minWidth: 'unset',
                      marginLeft: 'auto',
                      color: '#004F6E',
                    }}
                  >
                    <ChevronRightIcon />
                  </ListItemIcon>
                </ListItem>
              )
            )}
          </List>
        );
      } else {
        // If "adding advanced filter" mode but a category IS selected
        switch (selectedCategory) {
          case 'Supporter Voice':
            return (
              <SupporterVoice
                onFilterSelect={handleAddFilterToAdvancedFilters}
                isAddingAdvancedFilter={true}
              />
            );
          case 'Supporter Journey':
            return (
              <SupporterJourney
                onFilterSelect={handleAddFilterToAdvancedFilters}
                isAddingAdvancedFilter={true}
              />
            );
          case 'Supporter Trends':
            return (
              <SupporterTrends
                onFilterSelect={handleAddFilterToAdvancedFilters}
                isAddingAdvancedFilter={true}
              />
            );
          default:
            return null;
        }
      }
    } else {
      // Normal mode (not adding advanced filter)
      switch (selectedCategory) {
        case 'Saved Filters':
          return (
            <SavedFilters
              filters={filters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          );
        case 'Saved Lists':
          return (
            <SavedLists
              lists={lists}
              selectedLists={selectedFilterLists}
              setSelectedLists={setSelectedFilterLists}
            />
          );
        case 'Supporter Voice':
          return (
            <SupporterVoice
              filters={supporterVoiceFilters}
              setFilters={setSupporterVoiceFilters}
            />
          );
        case 'Supporter Journey':
          return (
            <SupporterJourney
              filters={supporterJourneyFilters}
              setFilters={setSupporterJourneyFilters}
            />
          );
        case 'Supporter Trends':
          return (
            <SupporterTrends
              filters={supporterTrendsFilters}
              setFilters={setSupporterTrendsFilters}
            />
          );
        default:
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Please select a category from the list.
            </Typography>
          );
      }
    }
  };

  // ----------------------------------
  // 7) MAIN RENDER
  // ----------------------------------
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        height: '100%',
        maxWidth: isAdvancedOpen ? '100vw' : '100vw', 
        minWidth: '400px',
        bgcolor: 'white',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        transition: 'width 0.3s ease',
        zIndex: 1200,
        transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          backgroundColor: '#DEF2FF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px',
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: '26px' }}
        >
          Filters
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Toggle Advanced Filters */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px 16px',
          cursor: 'pointer',
          color: '#004F6E',
          fontFamily: 'Inter',
          fontSize: '14px',
          textTransform: 'uppercase',
          marginTop: '8px',
          lineHeight: '1.2'
        }}
        onClick={toggleAdvancedFilters}
      >
        <ChevronLeftIcon
          sx={{
            transition: 'transform 0.3s',
            transform: isAdvancedOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
            marginRight: '8px',
          }}
        />
        Toggle Advanced Filters
      </Box>

      <Box sx={{ display: 'flex', height: 'calc(100% - 130px)' /* space for btn */ , paddingLeft: '1em' , paddingRight: '1em' }}>
        {/* Advanced Filters Panel */}
        {isAdvancedOpen && (
          <>
            <AdvancedFilters
              filters={advancedFilters}
              setFilters={setAdvancedFilters}
              onAddFilter={handleAddAdvancedFilter}
            />
            <Divider orientation="vertical" flexItem sx={{ marginLeft: 2, marginRight: 2 }} />
          </>
        )}

        {/* Main Category List or Filters UI */}
        <Box
          sx={{
            minWidth: 'unset',
            maxWidth: '100%',
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {/* Top portion: categories or selected category component */}
          <Box sx={{ overflowY: 'auto', flex: 1 }}>
            {selectedCategory === null ? (
              <List sx={{ width: '100%' }}>
                {isAddingAdvancedFilter ? (
                  ['Supporter Voice', 'Supporter Journey', 'Supporter Trends'].map(
                    (category) => (
                      <ListItem
                        key={category}
                        button
                        onClick={() => setSelectedCategory(category)}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '12px 16px',
                          fontFamily: 'Inter',
                          fontWeight: 700,
                          color: '#004F6E',
                        }}
                      >
                        <ListItemText
                          primary={category}
                          primaryTypographyProps={{
                            sx: {
                              fontFamily: 'Inter',
                              fontWeight: 700,
                              color: '#004F6E',
                              fontSize: '1.1rem',
                            },
                          }}
                        />
                        <ListItemIcon
                          sx={{
                            minWidth: 'unset',
                            marginLeft: 'auto',
                            color: '#004F6E',
                          }}
                        >
                          <ChevronRightIcon />
                        </ListItemIcon>
                      </ListItem>
                    )
                  )
                ) : (
                  [
                    'Saved Filters',
                    'Saved Lists',
                    'Supporter Voice',
                    'Supporter Journey',
                    'Supporter Trends',
                  ].map((category) => (
                    <ListItem
                      key={category}
                      button
                      onClick={() => handleCategoryClick(category)}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '8px 8px',
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        color: '#004F6E',
                        borderRadius: '10px',
                        width: '100%',
                        margin: '0 0 16px 0',
                      }}
                    >
                      <ListItemText
                        primary={category}
                        primaryTypographyProps={{
                          sx: {
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            color: '#004F6E',
                            fontSize: '1.1rem',
                          },
                        }}
                      />
                      <ListItemIcon
                        sx={{
                          minWidth: 'unset',
                          marginLeft: 'auto',
                          color: '#004F6E',
                        }}
                      >
                        <ChevronRightIcon />
                      </ListItemIcon>
                    </ListItem>
                  ))
                )}
              </List>
            ) : (
              <Box>
                {/* Back & Title */}
                <Box sx={{ display: 'flex', alignItems: 'center' , mt: 2, mb: 2 }}>
                  <IconButton
                    onClick={() => {
                      // If adding advanced filter, or normal mode:
                      setSelectedCategory(null);
                    }}
                    sx={{ padding: 0 }}
                  >
                    <ChevronLeftIcon sx={{ color: '#004F6E' }} />
                  </IconButton>
                  <Typography
                    variant="h3"
                    sx={{
                      fontFamily: 'Inter',
                      fontWeight: 700,
                      color: '#004F6E',
                      marginLeft: 1,
                      fontSize: '20px'
                    }}
                  >
                    {selectedCategory}
                  </Typography>
                </Box>

                {/* The actual content for the selected category */}
                {loading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%'
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  renderCategoryComponent()
                )}
              </Box>
            )}
          </Box>

          {/* Bottom portion: the APPLY FILTERS button */}
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Button
              onClick={handleApplyFilters} // <-- call your apply filter function here
              sx={{
                borderRadius: '5px',
                backgroundColor: '#C8D7E1',
                "&:hover": { backgroundColor: "#A9C0CD" },
                "&:active": { backgroundColor: "#A9C0CD" },
                color: '#000',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
                textTransform: 'uppercase',
                px: 3,
                py: 1,
                width: 'calc(100% + 120px)',
                whiteSpace: 'nowrap', // keeps "Apply Filters" on one line
              }}
            >
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterSidebar;
