import React from 'react';
import { Button, Box } from '@mui/material';

interface FooterButtonProps {
  onClickCancel: () => void;
  onClickConfirm: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  confirmDisabled?: boolean; // Optional prop for disabling the confirm button
  gap?: string; // New optional prop to control the gap between buttons
}

const FooterButtons: React.FC<FooterButtonProps> = ({
  onClickCancel,
  onClickConfirm,
  cancelLabel = "Cancel", // Default label is 'Cancel'
  confirmLabel = "Confirm", // Default label is 'Confirm'
  confirmDisabled = false, // Default to false if not provided
  gap = '20px', // Default gap is 20px if not provided
}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap, mt: 4 }}>
      <Button
        onClick={onClickCancel}
        sx={{
          borderRadius: '5px',
          border: '2px solid #7C90A3',
          background: '#FFF',
          "&:hover": { backgroundColor: "#ECECEC" },
          "&:active": { backgroundColor: "#ECECEC" },
          color: '#7C90A3',
          fontFamily: 'Inter',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: 'normal',
          textTransform: 'uppercase',
          letterSpacing: 0,
          px: 3,
          py: 1,
          width: 'auto' , //{ xs: 'calc(4vw + 75px)', md: 'calc(2vw + 100px)' }
          maxWidth: '130px'
        }}
      >
        {cancelLabel}
      </Button>
      <Button
        onClick={onClickConfirm}
        disabled={confirmDisabled} // Disable the button if confirmDisabled is true
        sx={{
          borderRadius: '5px',
          backgroundColor: confirmDisabled ? "#ECECEC" : "#C8D7E1", // Change color if disabled
          "&:hover": { backgroundColor: confirmDisabled ? "#ECECEC" : "#A9C0CD" },
          "&:active": { backgroundColor: confirmDisabled ? "#ECECEC" : "#A9C0CD" },
          color: '#000',
          fontFamily: 'Inter',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: 'normal',
          textTransform: 'uppercase',
          letterSpacing: 0,
          px: 3,
          py: 1,
          width: 'auto', //{ xs: 'calc(4vw + 75px)', md: 'calc(2vw + 100px)' }
          maxWidth: '130px'
        }}
      >
        {confirmLabel}
      </Button>
    </Box>
  );
};

export default FooterButtons;
