import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';

interface EnterURLPageProps {
  onNext: (url: string) => void; // Accept a callback that takes the URL
}

const EnterURLPage: React.FC<EnterURLPageProps> = ({ onNext }) => {
  const [url, setUrl] = useState<string>('');

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  const handleAuditClick = () => {
    onNext(url);
  };

  // Check if URL is empty
  const isUrlEmpty = url.trim() === '';

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Inter', color: '#33475B' }}>
        Step 1: Enter a URL
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please enter the URL you wish to audit:
      </Typography>

      <Box display="flex" alignItems="center" gap={2} mt={2}>
        <TextField
          label="Website URL"
          variant="outlined"
          value={url}
          onChange={handleUrlChange}
          size="small"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              height: 40, // Matches the button's height
            },
          }}
        />

        <Button
          onClick={handleAuditClick}
          disabled={isUrlEmpty}
          sx={{
            borderRadius: '5px',
            backgroundColor: isUrlEmpty ? '#ECECEC' : '#C8D7E1',
            '&:hover': { backgroundColor: isUrlEmpty ? '#ECECEC' : '#A9C0CD' },
            '&:active': { backgroundColor: isUrlEmpty ? '#ECECEC' : '#A9C0CD' },
            color: '#000',
            fontFamily: 'Inter',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            textTransform: 'none',
            px: 3,
            height: 40, // Matches the text field's height
            cursor: isUrlEmpty ? 'not-allowed' : 'pointer', // Visually indicate disabled
          }}
        >
          Audit
        </Button>
      </Box>
    </Box>
  );
};

export default EnterURLPage;
