import React, { useState } from 'react';
import { useDropzone, DropzoneOptions, DropzoneState } from 'react-dropzone';
import { CircularProgress, Typography, Box, Button, LinearProgress, Grid } from '@mui/material';
import CustomLinearProgressBar from './CustomLinearProgressBar';
import Papa from 'papaparse';
import { uploadFile } from '../api/supporterService';
import CloudUploadSvg from '../assets/images/cloud_upload.svg';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      'Arial',
      'sans-serif'
    ].join(','),
  },
});

interface FileUploadProps {
  onFileParsed: (fields: string[], data: any[]) => void;
  onFileUpload: (file: File) => void;
  onBack: () => void;
  cancel: () => void;
  activeStep: number;
  headers: string[];
  setHeaders: React.Dispatch<React.SetStateAction<string[]>>;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
  fileName: string;
  setSampleData: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
}

interface ParsedRow {
  [key: string]: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFileParsed, onFileUpload, onBack, cancel, activeStep, headers, setHeaders, setFileName, fileName, setSampleData
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const simulateProgress = () => {
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + 1.25;
        if (nextProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return nextProgress;
      });
    }, 100);
  };

  const onDrop: DropzoneOptions['onDrop'] = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setFile(file);
    parseCSV(file);
    await handleFileUpload(file);
  };

  const parseCSV = (file: File) => {
    setLoading(true);
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setLoading(false);
        const fields = results.meta.fields || [];
        onFileParsed(fields, results.data);
        setHeaders((prev) => [...prev, ...fields]);
        setFileName(file.name);
        console.log('Parsed CSV:', results);

        const sampleData: Record<string, string[]> = {};
        (results.data as ParsedRow[]).slice(0, 5).forEach((row) => {
          Object.keys(row).forEach((key) => {
            if (!sampleData[key]) {
              sampleData[key] = [];
            }
            sampleData[key].push(row[key]);
          });
        });
        setSampleData(sampleData);
      },
      error: (error) => {
        setLoading(false);
        console.error(error);
      }
    });
  };

  const handleFileUpload = async (file: File) => {
    simulateProgress();
    setUploading(true);
    try {
      const uploadedContent = await uploadFile(file);
      console.log('File uploaded successfully:', { uploadedContent });
      onFileUpload(file);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps }: DropzoneState = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] }
  });

  return (
    <ThemeProvider theme={theme}>
    <Grid container spacing={2}>
    <Grid item xs={12}>
      <Box {...getRootProps()} p={4} border="1px dashed grey" borderRadius={4} textAlign="center" bgcolor="background.paper">
        <input {...getInputProps()} />
        {loading || uploading ? (
                        <>
                        <CustomLinearProgressBar progress={progress} />
                        <Typography variant="body1" color="textSecondary" gutterBottom mt={3} fontSize='20px'>
                          Import in progress for{' '}
                          <Box component="span" sx={{ fontWeight: 700 }}>
                            {fileName}
                          </Box>
                        </Typography>
                        <Button
                          onClick={cancel}
                          sx={{
                            color: '#7C90A3',
                            fontFamily: 'Inter',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            textDecoration: 'underline',
                            mt: 2,  // Add margin-top for better spacing
                            marginBottom: 7,
                          }}
                        >
                          Cancel
                        </Button>
                    </>
        ) : (
          <>
            <img src={CloudUploadSvg} alt="Upload" style={{ width: '80px', height: '80px' }} />
            <Typography variant="h5" color="textSecondary" gutterBottom>
              Drag and drop file
            </Typography>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              or
            </Typography>
            <Button 
              variant="contained" 
              sx={{
                color: "#000000", 
                backgroundColor: "#C8D7E1", 
                "&:hover": { backgroundColor: "#A9C0CD" , boxShadow: 'none' },
                "&:active": { backgroundColor: "#A9C0CD" , boxShadow: 'none' },
                paddingLeft: 3,
                paddingRight: 3,
                fontSize: '1.25rem',
                fontWeight: '700',
                boxShadow: 'none',
              }}
            >
              Choose File
            </Button>
            {file && (
              <Typography variant="body2" color="textSecondary" mt={2}>
                {file.name}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Grid>
    </Grid>
    </ThemeProvider>
  );
};

export default FileUpload;
