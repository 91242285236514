import React, { createContext, useContext, useState } from 'react';
import { getTempSupporterConflicts, getTempSupportersByIds, getSupportersByIds, createSupporters, updateSupporters } from '../api/supporterService';

// Create the context
const ImportCSVContext = createContext();

// Create a provider component
export const ImportCSVProvider = ({ children }) => {
    const [counts, setCounts] = useState({
        'Incomplete Records': 0,
        'Suspected Duplicate Records': 0,
        'Suspected Existing Records': 0,
        'Unsubscribed Records': 0,
        'Updating Records': 0,
        'Existing Records': 0,
        'Clean Records': 0,
        'New Update': 0,
      });
    // data for each category
    const [incompleteRecords, setIncompleteRecords] = useState([]);
    const [suspectedDuplicateRecords, setSuspectedDuplicateRecords] = useState([]);
    const [suspectedExistingRecords, setSuspectedExistingRecords] = useState([]);
    const [unsubscribedRecords, setUnsubscribedRecords] = useState([]);
    const [updatingRecords, setUpdatingRecords] = useState([]);
    const [existingRecords, setExistingRecords] = useState([]);
    const [cleanRecords, setCleanRecords] = useState([]);
    const [newUpdateRecords, setNewUpdateRecords] = useState([]);
    const [incompleteIDs, setIncompleteIDs] = useState([]);
    const [suspectedDuplicateIDs, setSuspectedDuplicateIDs] = useState([]);
    const [suspectedExistingIDs, setSuspectedExistingIDs] = useState([]);
    const [unsubscribedIDs, setUnsubscribedIDs] = useState([]);
    const [updatingIDs, setUpdatingIDs] = useState([]);
    const [existingIDs, setExistingIDs] = useState([]);
    const [cleanIDs, setCleanIDs] = useState([]);

    // options selected for each category
    const [suspectedDuplicateSkipAll, setSuspectedDuplicateSkipAll] = useState(false);
    const [suspectedExistingDoForAll, setSuspectedExistingDoForAll] = useState(false);
    const [unsubscribedSkipAll, setUnsubscribedSkipAll] = useState(false);
    const [updatingSkipAll, setUpdatingSkipAll] = useState(false);
    const [existingSkipAll, setExistingSkipAll] = useState(false);
    const [cleanSkipAll, setCleanSkipAll] = useState(false);
    
    const [newUpdateDoForAll, setNewUpdateDoForAll] = useState(false);
    const [newUpdateResolved, setNewUpdateResolved] = useState(false);
    const [skipNewUpdateIDs, setSkipNewUpdateIDs] = useState([]);
    


    // Final lists of IDs to import or update (post or patch supporters)
    const [importIDs, setImportIDs] = useState([]);
    const [updateIDs, setUpdateIDs] = useState([]);

    // Booleans to check if conflicts are resolved
    const [suspectedDuplicatesResolved, setSuspectedDuplicatesResolved] = useState(false);
    const [suspectedExistingResolved, setSuspectedExistingResolved] = useState(false);

    // List of ID's for the two categories that need individual selections
    const [skipSuspectedDuplicateIDs, setSkipSuspectedDuplicateIDs] = useState([]);
    const [skipSuspectedExistingIDs, setSkipSuspectedExistingIDs] = useState([]);


    const fetchCategoriesData = async (taskId) => {
        try {
          // Fetch all categories initially
          const conflictResponse = await getTempSupporterConflicts(taskId);
      
          const {
            No_Conflicts: { count: noConflictsCount, data: noConflictsData } = { count: 0, data: [] },
            Suspected_Duplicates: { count: suspectedDuplicatesCount, data: suspectedDuplicatesData } = { count: 0, data: [] },
            Needs_Update: { count: needsUpdateCount, data: needsUpdateData } = { count: 0, data: [] },
            Existing_Data: { count: existingDataCount, data: existingData } = { count: 0, data: [] },
            Updating_Unsubscribe: { count: updatingUnsubscribeCount, data: updatingUnsubscribeData } = { count: 0, data: [] },
            Suspected_Existing: { count: suspectedExistingCount, data: suspectedExistingData } = { count: 0, data: [] },
            Incomplete_Record: { count: incompleteRecordsCount, data: incompleteRecordsData } = { count: 0, data: [] },
          } = conflictResponse;
      
          // Set counts for each category
          setCounts({
            'Incomplete Records': incompleteRecordsCount,
            'Suspected Duplicate Records': suspectedDuplicatesCount,
            'Suspected Existing Records': suspectedExistingCount,
            'Unsubscribed Records': updatingUnsubscribeCount,
            'Updating Records': needsUpdateCount,
            'Existing Records': existingDataCount,
            'Clean Records': noConflictsCount,
          });
      
          // Store IDs for each category
          setIncompleteIDs(incompleteRecordsData); // Formats: [0, 3, 8, ...]
          setSuspectedDuplicateIDs(suspectedDuplicatesData); // [[1, 2], [4, 5, 6] ...]
          setSuspectedExistingIDs(suspectedExistingData); // [[7, 9], [10, 11] ...]
          setUnsubscribedIDs(updatingUnsubscribeData); // [[12, 13], [14, 15] ...]
          setUpdatingIDs(needsUpdateData); // [[16, 17], [18, 19] ...]
          setExistingIDs(existingData); // [[20, 21], [22, 23] ...]
          setCleanIDs(noConflictsData); // [24, 25, 26, ...]
      
          // Fetch and set the first 50 records for each category
          const fetchAndSetRecords = async (ids, setRecordsFn) => {
            if (ids.length > 0) {
              const first50IDs = ids.slice(0, 50);
              const supportersResponse = await getTempSupportersByIds(first50IDs);
              setRecordsFn(supportersResponse.results);
            }
          };
      
          // New helper function to handle 2D arrays where we only fetch the first element in each subarray
          const fetchAndSetRecordsFor2DArray = async (ids, setRecordsFn) => {
            if (ids.length > 0) {
              // Extract the first elements from each subarray
              const first50IDs = ids.map(subArray => subArray[0]).slice(0, 50);
              const supportersResponse = await getTempSupportersByIds(first50IDs);
              setRecordsFn(supportersResponse.results);
            }
          };
      
          await Promise.all([
            fetchAndSetRecords(incompleteRecordsData, setIncompleteRecords),
            fetchAndSetRecords(noConflictsData, setCleanRecords),
            fetchAndSetRecordsFor2DArray(updatingUnsubscribeData, setUnsubscribedRecords),
            fetchAndSetRecordsFor2DArray(needsUpdateData, setUpdatingRecords),
            fetchAndSetRecordsFor2DArray(existingData, setExistingRecords),
          ]);
      
          // Fetch all records for suspectedDuplicatesData and group them
          if (suspectedDuplicatesData.length > 0) {
            const allSupportersResponse = await getTempSupportersByIds(suspectedDuplicatesData.flat());
            const groupedRecords = suspectedDuplicatesData.map((idGroup) =>
              allSupportersResponse.results.filter(record => idGroup.includes(record.id))
            );
            setSuspectedDuplicateRecords(groupedRecords);
          }
      
          // New logic for fetching data for suspectedExistingRecords
          if (suspectedExistingData.length > 0) {
            const tempSupporterIds = suspectedExistingData.map(subArray => subArray[0]); // First ID in each subarray
            const supporterIds = suspectedExistingData.map(subArray => subArray[1]); // Second ID in each subarray
      
            // Fetch temp supporters and supporters in parallel
            const [tempSupportersResponse, supportersResponse] = await Promise.all([
              getTempSupportersByIds(tempSupporterIds),
              getSupportersByIds(supporterIds),
            ]);
      
            // Combine the fetched data into a 2D array
            const combinedRecords = suspectedExistingData.map(([tempId, supporterId]) => [
              tempSupportersResponse.results.find(record => record.id === tempId),
              supportersResponse.results.find(record => record.id === supporterId),
            ]);
      
            setSuspectedExistingRecords(combinedRecords);
          }
      
        } catch (error) {
          console.error('Error fetching all categories data:', error);
        }
      };
      
    

      const fetchNextPageForCategory = async (category, pageSize = 50) => {
        try {
            let currentRecords, setRecordsFn, allIDs;
    
            switch (category) {
                case 'Incomplete_Record':
                    currentRecords = incompleteRecords;
                    setRecordsFn = setIncompleteRecords;
                    allIDs = incompleteIDs; // 1D list
                    break;
                case 'Updating_Unsubscribe':
                    currentRecords = unsubscribedRecords;
                    setRecordsFn = setUnsubscribedRecords;
                    allIDs = unsubscribedIDs; // 2D list
                    break;
                case 'Needs_Update':
                    currentRecords = updatingRecords;
                    setRecordsFn = setUpdatingRecords;
                    allIDs = updatingIDs; // 2D list
                    break;
                case 'Existing_Data':
                    currentRecords = existingRecords;
                    setRecordsFn = setExistingRecords;
                    allIDs = existingIDs; // 2D list
                    break;
                case 'No_Conflicts':
                    currentRecords = cleanRecords;
                    setRecordsFn = setCleanRecords;
                    allIDs = cleanIDs; // 1D list
                    break;
                default:
                    throw new Error(`Unsupported category: ${category}`);
            }
    
            // Check if allIDs is a 2D array or a 1D array
            const is2DArray = Array.isArray(allIDs[0]);
    
            // Find the index of the last fetched record in the full ID list
            const lastRecordID = currentRecords.length > 0 ? currentRecords[currentRecords.length - 1].id : null;
            const flattenedIDs = is2DArray ? allIDs.map(subArray => subArray[0]) : allIDs; // Flatten to 1D list for lookup
    
            const lastRecordIndex = lastRecordID ? flattenedIDs.indexOf(lastRecordID) : -1;
    
            // Determine the next set of IDs to fetch
            const nextIDs = flattenedIDs.slice(lastRecordIndex + 1, lastRecordIndex + 1 + pageSize);
    
            if (nextIDs.length > 0) {
                // Fetch the next set of records using the nextIDs
                const supportersResponse = await getTempSupportersByIds(nextIDs);
    
                // Append the new records to the existing records
                setRecordsFn(prev => [...prev, ...supportersResponse.results]);
            }
    
        } catch (error) {
            console.error(`Error fetching next page for category ${category}:`, error);
        }
    };
    

    // Accepts a 1D or 2D list of records, and removes any records that were skipped when selecting suspected duplicates or suspected existing records
    const filterRecordsBySkippedIDs = (data) => {
        // const { skipSuspectedDuplicateIDs, skipSuspectedExistingIDs } = useImportCSVContext();
    
        // Helper function to filter a single list of records
        const filterList = (list) => {
            return list.filter(
                record => !skipSuspectedDuplicateIDs.includes(record.id) && !skipSuspectedExistingIDs.includes(record.id)
            );
        };
    
        // Check if the input is a 2D list or a 1D list
        if (Array.isArray(data) && Array.isArray(data[0])) {
            // Handle 2D list
            return data.map(sublist => filterList(sublist));
        } else {
            // Handle 1D list
            return filterList(data);
        }
    };

    const finalizeSupporterImport = async () => {
        try {
            // Assemble IDs for creating new supporters (POST request)
            let importIDs = [];
    
            // Handle clean records
            if (!cleanSkipAll) {
                importIDs.push(...cleanIDs);
            }
    
            // Handle existing records
            let updateIDs = [];
            if (!existingSkipAll) {
                updateIDs.push(...existingIDs);
            }
    
            // Handle unsubscribed records
            if (!unsubscribedSkipAll) {
                updateIDs.push(...unsubscribedIDs);
            }
    
            // Handle updating records
            if (!updatingSkipAll) {
                updateIDs.push(...updatingIDs);
            }
    
            // Handle suspected duplicate records
            if (!suspectedDuplicateSkipAll) {
                const filteredSuspectedDuplicateIDs = suspectedDuplicateIDs
                    .flat()
                    .filter(id => !skipSuspectedDuplicateIDs.includes(id));
    
                // Only add to importIDs if there are IDs to import
                if (filteredSuspectedDuplicateIDs.length > 0) {
                    importIDs.push(...filteredSuspectedDuplicateIDs);
                }
            }
    
            // Handle suspected existing records
            const filteredSuspectedExistingIDs = suspectedExistingIDs.filter(
                pair => !skipSuspectedExistingIDs.includes(pair[0])
            );
            updateIDs.push(...filteredSuspectedExistingIDs);
    
            // Remove any IDs in skipSuspectedDuplicateIDs or skipSuspectedExistingIDs from importIDs
            importIDs = importIDs.filter(
                id => !skipSuspectedDuplicateIDs.includes(id) && !skipSuspectedExistingIDs.includes(id)
            );
    
            console.log('Final importIDs:', importIDs);
            console.log('Final updateIDs:', updateIDs);
    
            // Call the POST endpoint to create new supporters
            if (importIDs.length > 0) {
                // Ensure payload matches expected structure
                const importResponse = await createSupporters(importIDs);
                console.log('Import Response:', importResponse);
            }

            // Call the PATCH endpoint to update existing supporters
            if (updateIDs.length > 0) {
                // Format the updates as an array of objects with supporterId and tempSupporterId keys
                const formattedUpdates = updateIDs.map(([tempSupporterId, supporterId]) => ({
                    supporterId,
                    tempSupporterId
                }));

                const updateResponse = await updateSupporters(formattedUpdates);
                console.log('Update Response:', updateResponse);
            }
    
        } catch (error) {
            console.error('Error finalizing supporter import:', error);
        }
    };

    const resetContext = () => {
        setCounts({
            'Incomplete Records': 0,
            'Suspected Duplicate Records': 0,
            'Suspected Existing Records': 0,
            'Unsubscribed Records': 0,
            'Updating Records': 0,
            'Existing Records': 0,
            'Clean Records': 0,
        });
    
        setIncompleteRecords([]);
        setSuspectedDuplicateRecords([]);
        setSuspectedExistingRecords([]);
        setUnsubscribedRecords([]);
        setUpdatingRecords([]);
        setExistingRecords([]);
        setCleanRecords([]);
    
        setIncompleteIDs([]);
        setSuspectedDuplicateIDs([]);
        setSuspectedExistingIDs([]);
        setUnsubscribedIDs([]);
        setUpdatingIDs([]);
        setExistingIDs([]);
        setCleanIDs([]);
    
        setSuspectedDuplicateSkipAll(false);
        setSuspectedExistingDoForAll(false);
        setUnsubscribedSkipAll(false);
        setUpdatingSkipAll(false);
        setExistingSkipAll(false);
        setCleanSkipAll(false);
    
        setImportIDs([]);
        setUpdateIDs([]);
    
        setSuspectedDuplicatesResolved(false);
        setSuspectedExistingResolved(false);
    
        setSkipSuspectedDuplicateIDs([]);
        setSkipSuspectedExistingIDs([]);
    };
    
    
    
        
    
    // Value to pass to the provider
    const value = {
        counts,
        setCounts,
        incompleteRecords,
        setIncompleteRecords,
        suspectedDuplicateRecords,
        setSuspectedDuplicateRecords,
        suspectedExistingRecords,
        setSuspectedExistingRecords,
        unsubscribedRecords,
        setUnsubscribedRecords,
        updatingRecords,
        setUpdatingRecords,
        existingRecords,
        setExistingRecords,
        cleanRecords,
        setCleanRecords,

        suspectedDuplicateSkipAll,
        setSuspectedDuplicateSkipAll,
        suspectedExistingDoForAll,
        setSuspectedExistingDoForAll,
        unsubscribedSkipAll,
        setUnsubscribedSkipAll,
        updatingSkipAll,
        setUpdatingSkipAll,
        existingSkipAll,
        setExistingSkipAll,
        cleanSkipAll,
        setCleanSkipAll,

        fetchCategoriesData,
        fetchNextPageForCategory,
        filterRecordsBySkippedIDs,
        finalizeSupporterImport,
        incompleteIDs,
        setIncompleteIDs,
        suspectedDuplicateIDs,
        setSuspectedDuplicateIDs,
        suspectedExistingIDs,
        setSuspectedExistingIDs,
        unsubscribedIDs,
        setUnsubscribedIDs,
        updatingIDs,
        setUpdatingIDs,
        existingIDs,
        setExistingIDs,
        cleanIDs,
        setCleanIDs,

        importIDs,
        setImportIDs,
        updateIDs,
        setUpdateIDs,
        skipSuspectedDuplicateIDs,
        setSkipSuspectedDuplicateIDs,
        skipSuspectedExistingIDs,
        setSkipSuspectedExistingIDs,
        suspectedDuplicatesResolved,
        setSuspectedDuplicatesResolved,
        suspectedExistingResolved,
        setSuspectedExistingResolved,
        resetContext,

        newUpdateRecords,
        setNewUpdateRecords,
        newUpdateDoForAll,
        setNewUpdateDoForAll,
        counts,
        setCounts,
        skipNewUpdateIDs,
        setSkipNewUpdateIDs,
        newUpdateResolved,
        setNewUpdateResolved,
        
        
        
        
    };

    return (
        <ImportCSVContext.Provider value={value}>
            {children}
        </ImportCSVContext.Provider>
    );
};

// Custom hook to use the context
export const useImportCSVContext = () => useContext(ImportCSVContext);
