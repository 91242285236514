import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Button
} from '@mui/material';
import { Close } from '@mui/icons-material';

interface EmergencyStopDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const EmergencyStopDialog: React.FC<EmergencyStopDialogProps> = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box sx={{ backgroundColor: 'rgba(173, 33, 25, 0.17)', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px' }}>
        <Typography variant="h5" sx={{ fontFamily: 'Inter', fontWeight: 'bold', marginX: 2, color: '#AD2119' }}>Emergency Stop</Typography>
        <IconButton onClick={onClose}><Close /></IconButton>
      </Box>
      <DialogContent sx={{ padding: '24px', textAlign: 'left' }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          All emails* in your System will be paused indefinitely. Emails will not resume until you re-activate sending again.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          *If you set exceptions for Thank You and Birthday type emails, they will not be affected.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', mt: 2, mb: 1 }}>
        <Button onClick={() => onClose()} color="primary">Cancel</Button>
        <Button onClick={() => onConfirm()} sx={{ backgroundColor: '#AD2119', "&:hover": { backgroundColor: '#8A1B14' }, "&:active": { backgroundColor: '#8A1B14' }, color: '#FFF' }}>Pause Emails</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmergencyStopDialog;
