import React from 'react';
import { Box, Typography, styled, useTheme, useMediaQuery } from '@mui/material';

interface CustomStepperProps {
  steps: string[];
  activeStep: number;
}

interface StepBoxProps {
  isActive: boolean;
  index: number;
}

const StepBox = styled(Box)<StepBoxProps>(({ theme, isActive, index }) => ({
  fontFamily: 'Inter',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 'calc(1.5vw + 30px)',
  [theme.breakpoints.down('sm')]: {
    height: '12vw',
  },
  borderTopRightRadius: '55px',
  borderBottomRightRadius: '55px',
  borderTopLeftRadius: index === 0 ? '10px' : '0',
  borderBottomLeftRadius: index === 0 ? '10px' : '0',
  background: isActive ? '#C8D7E1' : '#ECF4F9',
  color: isActive ? '#000' : '#7C90A3',
  border: '2px solid #C8D7E1',
  borderLeft: '2px solid #C8D7E1',
  marginLeft: index === 0 ? '0' : '-34px',
  zIndex: -index,
  flex: '1 1 auto',
  position: 'relative',
  width: index === 0 ? 'calc(100% - 34px)' : '100%', // Make the first box slightly narrower
}));

const NumberCircle = styled(Typography)<{ index: number }>(({ theme, index }) => ({
  fontFamily: 'Inter',
  // Font sizes adjusted per breakpoints
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    width: '30px',
    height: '30px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.1rem',
    height: '25px',
    width: '25px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
    height: '25px',
    width: '25px',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '1.1rem',
  },
  fontStyle: 'normal',
  fontWeight: 700,
  background: '#FFF',
  borderRadius: '50%',
  
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  left: index === 0 ? theme.spacing(2) : theme.spacing(5),
}));

const CustomStepper: React.FC<CustomStepperProps> = ({ steps, activeStep }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{
      display: 'flex',
      width: isMobile ? '100%' : '100%',
      overflow: 'hidden',
      padding: {
        xs: '0px',
        sm: '0px',
        md: '24px',
        lg: '24px',
      },
      margin: {
        xs: '0 auto 1em',
        sm: '0 auto 1em',
        md: '0 auto',
        lg: '0 auto',
      },
      position: 'relative'
    }}>
      {steps.map((step, index) => (
        isMobile ?
          (index === activeStep && (
            <StepBox key={index} isActive={true} index={index}>
              <NumberCircle index={index}>{index + 1}</NumberCircle>
              <Typography sx={{
                fontFamily: 'Inter',
                fontSize: {
                  xs: '1rem', // Adjust for very small devices
                  sm: '0.85rem', // Adjust for small devices
                  md: '0.95rem', // Medium devices
                  lg: '1.2rem', // Larger devices
                },
                fontStyle: 'normal',
                fontWeight: 700,
                paddingLeft: theme.spacing(10),
                paddingRight: theme.spacing(5),
                marginLeft: 'auto',
              }}>
                {step}
              </Typography>
            </StepBox>
          )) :
          <StepBox key={index} isActive={index === activeStep} index={index}>
            <NumberCircle index={index}>{index + 1}</NumberCircle>
            <Typography sx={{
              fontFamily: 'Inter',
              fontSize: {
                xs: '1rem', // Adjust for very small devices
                sm: '0.85rem', // Adjust for small devices
                md: '0.95rem', // Medium devices
                lg: '1.2rem', // Larger devices
              },
              fontStyle: 'normal',
              fontWeight: 700,
              paddingLeft: theme.spacing(10),
              paddingRight: {
                sm: theme.spacing(2),
                md: theme.spacing(3),
                lg: theme.spacing(5),
              },
              marginLeft: 'auto',
            }}>
              {step}
            </Typography>
          </StepBox>
      ))}
    </Box>
  );
};

export default CustomStepper;
