import React, { useState } from 'react';
import EffortOverviewCard from '../../components/effort_overview/EffortOverviewCard';
import PurchaseEffortCard from '../../components/effort_overview/PurchaseEffortCard';
import StewardshipLearnMore from '../../components/effort_overview/StewardshipLearnMore';

// Example image imports:
import testEffortImage from '../../assets/images/test_effort_image.png';

const EffortOverview: React.FC = () => {
  const [showLearnMore, setShowLearnMore] = useState(false);

  // For demonstration, we’ll just log a message when the normal Effort cards are clicked.
  const handleCardClick = (title: string) => {
    console.log(`Card "${title}" clicked!`);
  };

  // Effort Overview data
  const effortData = [
    {
      title: 'PROSPECTS SURVEY',
      enrolled: 150,
      avgConversionRate: 35,
      imageUrl: testEffortImage,
    },
    {
      title: 'DOOR OPENER',
      enrolled: 200,
      avgConversionRate: 40,
      imageUrl: testEffortImage,
    },
    {
      title: 'CULTIVATION',
      enrolled: 180,
      avgConversionRate: 38,
      imageUrl: testEffortImage,
    },
    {
      title: 'TELL YOUR STORY',
      enrolled: 220,
      avgConversionRate: 42,
      imageUrl: testEffortImage,
    },
    {
      title: 'TEST EFFORT',
      enrolled: 99,
      avgConversionRate: 10,
      imageUrl: testEffortImage,
    },
  ];

  // Stewardship Series Purchase Card details
  const stewardshipCardImage = testEffortImage; // You can replace this with any real image

  return (
    <div style={{ padding: '1rem', textAlign: 'left' }}>
      {!showLearnMore ? (
        <>
          {/* Page Header */}
          <h1
            style={{
              color: '#004F6E',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 400,
              fontSize: '2rem',
              marginBottom: '0.5rem',
            }}
          >
            Effort Overview
          </h1>
          <p
            style={{
              color: '#000',
              fontFamily: 'Inter, sans-serif',
              fontSize: '1rem',
              marginBottom: '1.5rem',
            }}
          >
            Below is an overview of stats for all of your active Efforts and those that can be purchased.
          </p>

          {/* Grid of Effort Cards */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 450px))',
              justifyContent: 'center',
              gap: '2rem',
            }}
          >
            {effortData.map((effort, index) => (
              <EffortOverviewCard
                key={index}
                title={effort.title}
                enrolled={effort.enrolled}
                avgConversionRate={effort.avgConversionRate}
                imageUrl={effort.imageUrl}
                onCardClick={() => handleCardClick(effort.title)}
              />
            ))}

            {/* PURCHASE EFFORT CARD for Stewardship Series */}
            <PurchaseEffortCard
              title="STEWARDSHIP SERIES"
              recommendedSupporters={128}
              imageUrl={stewardshipCardImage}
              // Clicking the entire card now shows the "learn more" content
              onCardClick={() => setShowLearnMore(true)}
            />
          </div>
        </>
      ) : (
        <StewardshipLearnMore onBack={() => setShowLearnMore(false)} />
      )}
    </div>
  );
};

export default EffortOverview;
