import React, { useState, useMemo } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './EmailOverview.css';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import EmailListView from './EmailListView';
import { EmailDataProvider, useEmailData } from './EmailContext';

interface LegendCircleProps {
  outerColor: string;
  innerColor?: string | null;
  label: string;
}

// Smaller legend circles for the calendar legend.
function LegendCircle({ outerColor, innerColor, label }: LegendCircleProps) {
  const size = 16;
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
      <div style={{ position: 'relative', width: size, height: size }}>
        <div
          style={{
            backgroundColor: outerColor,
            borderRadius: '50%',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
        {innerColor && (
          <div
            style={{
              backgroundColor: innerColor,
              borderRadius: '50%',
              width: '70%',
              height: '70%',
              position: 'absolute',
              top: '15%',
              left: '15%',
            }}
          />
        )}
      </div>
      <span style={{ fontFamily: 'Inter, sans-serif', fontSize: '0.8rem' }}>
        {label}
      </span>
    </div>
  );
}

// Helper to determine circle colors and size based on total email count.
function getCircleColorsAndSize(totalCount: number | null) {
  if (totalCount === null) return { outer: null, inner: null, size: null };

  if (totalCount > 25000) {
    return { outer: '#F2B7B7', inner: '#F88C8C', size: '5rem' };
  } else if (totalCount >= 5001 && totalCount <= 25000) {
    return { outer: '#F5EBA1', inner: '#EEDC56', size: '4rem' };
  } else if (totalCount > 0 && totalCount <= 5000) {
    return { outer: '#CAEFC0', inner: '#8FDC85', size: '3.5rem' };
  }
  return { outer: null, inner: null, size: null };
}

// Return local date string "YYYY-MM-DD" (avoiding time zone offset).
function getLocalDateString(d: Date) {
  const year = d.getFullYear();
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const day = d.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function EmailOverviewInner() {
  // Use today's date as the default.
  const defaultDate = new Date();
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const emailData = useEmailData();

  // Map each date to its total email count.
  const dateTotals = useMemo<Record<string, number>>(() => {
    const map: Record<string, number> = {};
    for (const e of emailData) {
      const dt = e.date;
      const total = e.queued_count + e.sent_count;
      map[dt] = (map[dt] || 0) + total;
    }
    return map;
  }, [emailData]);

  // Render a colored circle on the calendar tile if email data exists.
  function renderTileContent({ date, view }: { date: Date; view: string }) {
    if (view !== 'month') return null;

    const dateString = getLocalDateString(date);
    const totalCount = dateTotals[dateString] ?? null;
    const { outer, inner, size } = getCircleColorsAndSize(totalCount);
    if (!outer && !inner) return null;

    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: size || '2rem',
          height: size || '2rem',
          zIndex: 0,
        }}
      >
        <div
          style={{
            backgroundColor: outer,
            borderRadius: '50%',
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          {inner && (
            <div
              style={{
                width: '80%',
                height: '80%',
                backgroundColor: inner,
                borderRadius: '50%',
                position: 'absolute',
                top: '10%',
                left: '10%',
              }}
            />
          )}
        </div>
      </div>
    );
  }

  // When a calendar day is clicked, scroll the feed to that day if it has emails.
  function handleDayClick(day: Date) {
    const dateString = getLocalDateString(day);
    if (dateTotals[dateString]) {
      setSelectedDate(dateString);
    } else {
      setSelectedDate(null);
    }
  }

  return (
    <div style={{ width: '80%', margin: '0 auto' }}>
      <h1
        style={{
          color: '#004F6E',
          fontFamily: 'Inter, sans-serif',
          fontWeight: 400,
        }}
      >
        Email Events Calendar
      </h1>

      <div style={{ display: 'flex', marginTop: '2rem', alignItems: 'stretch' }}>
        {/* Left: Calendar & Legend */}
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {/* Calendar */}
          <div className="large-calendar" style={{ flex: 'none' }}>
            <Calendar
              defaultValue={defaultDate}
              next2Label={null}
              prev2Label={null}
              nextLabel={<ArrowForwardIosIcon />}
              prevLabel={<ArrowBackIosIcon />}
              maxDetail="month"
              selectRange={false}
              tileContent={renderTileContent}
              onClickDay={handleDayClick}
            />
          </div>

          {/* Legend directly below the calendar */}
          <div style={{ marginTop: '1rem' }}>
            <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
              <LegendCircle
                outerColor="#F2B7B7"
                innerColor="#F88C8C"
                label="> 25,000 Emails"
              />
              <LegendCircle
                outerColor="#F5EBA1"
                innerColor="#EEDC56"
                label="5,001 - 25,000 Emails"
              />
              <LegendCircle
                outerColor="#CAEFC0"
                innerColor="#8FDC85"
                label="< 5,000 Emails"
              />
              <LegendCircle
                outerColor="rgba(0, 0, 0, 0.30)"
                innerColor={null}
                label="Blackout Day"
              />
            </div>
          </div>
        </div>

        {/* Right: Email feed */}
        <div
          style={{
            flex: 1,
            border: '1px solid #ccc',
            borderRadius: '8px',
            marginLeft: '1rem',
            // We'll make sure we have enough height for scrolling:
            height: '80vh',
          }}
        >
          <EmailListView initialDate={defaultDate} scrollToDate={selectedDate} />
        </div>
      </div>
    </div>
  );
}

export default function EmailOverview() {
  return (
    <EmailDataProvider>
      <EmailOverviewInner />
    </EmailDataProvider>
  );
}
