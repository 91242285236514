import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Checkbox,
  styled,
  Pagination,
  PaginationItem,
  Grid,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ReactComponent as UnsubscribeIcon } from '../icons/view_supporters/Unsubscribe.svg';

// Define the type for the supporter prop
interface Supporter {
  salutation?: string | null;
  assigned_gift_officer_id?: number | null;
  unsubscribed?: boolean | null;
  external_id?: string | null;
  group_id?: number | null;
  org_id?: number | null;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string | null;
  address?: string;
  city?: string | null;
  state?: string | null;
  zip_code?: string | null;
  image?: string | null;
  version?: number;
  is_head?: boolean;
  id: number;
  created_at?: string;
  created_by?: string | null;
  advancement_stage?: string | null;
}

interface SupportersTableProps {
  supporters: Supporter[];
  handleSupporterClick: (supporter: Supporter) => void;
  onSelectSupporters: (selectedIds: number[]) => void;
}

const tableBoxStyle = {
  borderRadius: '5px',
  border: '2px solid #C8D7E1',
  background: '#FFF',
  overflow: 'hidden',
};

const tableStyle = {
  minWidth: 650,
  overflowY: 'scroll',
  '& thead th': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    borderBottom: '2px solid #C8D7E1',
    padding: '0.25em 1em',
    whiteSpace: 'nowrap',
    background: '#C8D7E1',
  },
  '& thead th:first-of-type': {
    background: '#fff',
    borderRight: '2px solid #C8D7E1',
  },
  '& tbody tr:nth-of-type(odd)': {
    backgroundColor: '#FFF',
  },
  '& tbody tr:nth-of-type(even)': {
    backgroundColor: '#ECF4F9',
  },
  '& tbody td': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '1rem',
    borderBottom: '0px',
    padding: '0.25em 1em',
    whiteSpace: 'nowrap',
  },
  '& tbody td:not(:last-child)': {
    borderRight: '2px solid #C8D7E1',
  },
};

const tableContainerStyle = {
  overflowX: 'auto',
};

const PaginationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0',
  backgroundColor: '#FFF',
  boxShadow: '0px 0px 14px 0px #c8d7e1',
});

const orangeBoxStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFA726',
  borderRadius: '4px',
  width: '24px',
  height: '24px',
  marginLeft: '8px',
};

const SupportersTable: React.FC<SupportersTableProps> = ({
  supporters = [],
  handleSupporterClick,
  onSelectSupporters,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSupporters, setSelectedSupporters] = useState<number[]>([]);
  const rowsPerPage = 7;

  const handlePageChange = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleCheckboxClick = (id: number) => {
    const selectedIndex = selectedSupporters.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      // If it's not currently selected, add it
      newSelected = [...selectedSupporters, id];
    } else {
      // If it is currently selected, remove it
      newSelected = selectedSupporters.filter((sid) => sid !== id);
    }

    setSelectedSupporters(newSelected);
    // Notify parent with updated selection
    onSelectSupporters(newSelected);
  };

  const handleRowClick = (supporter: Supporter) => {
    // Optionally handle row details click, then toggle selection
    handleSupporterClick(supporter);
    handleCheckboxClick(supporter.id);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = paginatedSupporters.map((s) => s.id);
      setSelectedSupporters(newSelecteds);
      onSelectSupporters(newSelecteds);
    } else {
      setSelectedSupporters([]);
      onSelectSupporters([]);
    }
  };

  const totalPages = Math.ceil(supporters.length / rowsPerPage);
  const paginatedSupporters = supporters.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const isSelected = (id: number) => selectedSupporters.indexOf(id) !== -1;
  const allSelected =
    paginatedSupporters.length > 0 &&
    paginatedSupporters.every((supporter) => isSelected(supporter.id));

  return (
    <Grid item xs={12} sx={tableBoxStyle}>
      <TableContainer sx={tableContainerStyle}>
        <Table sx={tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: '0 4px' }}>
                <Checkbox
                  size="medium"
                  indeterminate={
                    selectedSupporters.length > 0 && !allSelected
                  }
                  checked={allSelected}
                  onChange={handleSelectAllClick}
                  inputProps={{ 'aria-label': 'select all supporters' }}
                  disableRipple
                  sx={{ padding: '0.5em 0' }}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Advancement Stage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedSupporters.map((supporter) => (
              <TableRow
                key={supporter.id}
                selected={isSelected(supporter.id)}
                onClick={() => handleRowClick(supporter)}
                hover
                style={{ cursor: 'pointer' }}
              >
                <TableCell
                  sx={{ padding: '0 4px' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Checkbox
                    size="medium"
                    checked={isSelected(supporter.id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleCheckboxClick(supporter.id);
                    }}
                    inputProps={{
                      'aria-label': `select supporter ${supporter.id}`,
                    }}
                    disableRipple
                    sx={{ padding: '0.5em 0' }}
                  />
                </TableCell>
                <TableCell>{`${supporter.first_name} ${supporter.last_name}`.trim()}</TableCell>
                <TableCell>
                  {supporter.email || ''}
                  {supporter.unsubscribed && (
                    <Box
                      component="span"
                      sx={orangeBoxStyle}
                      title="Email Unsubscribed"
                    >
                      <UnsubscribeIcon
                        style={{
                          width: '16px',
                          height: '16px',
                          transform: 'translate(1px, 1px)',
                        }}
                      />
                    </Box>
                  )}
                </TableCell>
                <TableCell>{supporter.phone_number || ''}</TableCell>
                <TableCell>{supporter.address || ''}</TableCell>
                <TableCell>{/* Score placeholder */}</TableCell>
                <TableCell>{/* Advancement Stage placeholder */}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationWrapper>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowBackIosNewIcon, next: ArrowForwardIosIcon }}
              {...item}
            />
          )}
        />
      </PaginationWrapper>
    </Grid>
  );
};

export default SupportersTable;