// EmailContext.tsx

import React, { createContext, useContext, useState } from 'react';

export interface EmailEntry {
  date: string;        // e.g. "2025-01-12"
  email_name: string;
  queued_count: number;
  sent_count: number;
}

// 1) Create the context
const EmailDataContext = createContext<EmailEntry[] | undefined>(undefined);

// 2) Export a simple hook for consuming this context
export function useEmailData() {
  const context = useContext(EmailDataContext);
  if (!context) {
    throw new Error('useEmailData must be used within an EmailDataProvider');
  }
  return context;
}

// 3) Provider component storing the email data array
export function EmailDataProvider({ children }: { children: React.ReactNode }) {
  // Store your sample data here (shared by both calendar & list)
  const [emailData] = useState<EmailEntry[]>([
    // January 2025
    { date: '2025-01-10', email_name: 'New Year Promo', queued_count: 2000, sent_count: 1000 },
    { date: '2025-01-15', email_name: 'Winter Sale', queued_count: 100, sent_count: 50 },
    { date: '2025-01-10', email_name: 'New Year Promo', queued_count: 2000, sent_count: 1000 },
    { date: '2025-01-15', email_name: 'Winter Sale', queued_count: 100, sent_count: 50 },
    // February 2025
    { date: '2025-02-03', email_name: 'Valentine Teaser', queued_count: 5000, sent_count: 2000 },
    { date: '2025-02-03', email_name: 'Bonus Valentine', queued_count: 600, sent_count: 100 },
    { date: '2025-02-10', email_name: 'Mid-Feb Newsletter', queued_count: 50, sent_count: 20 },
    { date: '2025-02-14', email_name: 'Valentine Main', queued_count: 25000, sent_count: 5000 },
    { date: '2025-02-27', email_name: 'End of Feb', queued_count: 25000, sent_count: 5000 },
    // March 2025
    { date: '2025-03-01', email_name: 'Spring Intro', queued_count: 350, sent_count: 120 },
    { date: '2025-03-11', email_name: 'Spring DAF Effort', queued_count: 85, sent_count: 200 },
    { date: '2025-03-15', email_name: 'Spring Sale 2', queued_count: 875, sent_count: 400 },
    { date: '2025-04-03', email_name: 'Valentine Teaser', queued_count: 5000, sent_count: 2000 },
    { date: '2025-04-04', email_name: 'Bonus Valentine', queued_count: 600, sent_count: 100 },
    { date: '2025-04-10', email_name: 'Mid-Feb Newsletter', queued_count: 50, sent_count: 20 },
    { date: '2025-04-14', email_name: 'Valentine Main', queued_count: 25000, sent_count: 5000 },
    { date: '2025-05-03', email_name: 'Valentine Teaser', queued_count: 5000, sent_count: 2000 },
    { date: '2025-05-03', email_name: 'Bonus Valentine', queued_count: 600, sent_count: 100 },
    { date: '2025-05-10', email_name: 'Mid-Feb Newsletter', queued_count: 50, sent_count: 20 },
    { date: '2025-05-14', email_name: 'Valentine Main', queued_count: 25000, sent_count: 5000 },
  ]);

  return (
    <EmailDataContext.Provider value={emailData}>
      {children}
    </EmailDataContext.Provider>
  );
}
